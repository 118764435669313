@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.blogpage-headingcontent {
  width: 55%;
  text-align: left;
  margin: 0 auto;
  padding: 30px 30px;
}

.CaseStudy {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.26px;
  margin-bottom: 1rem;
}

.CaeStudy-content {
  font-size: 44px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.72px;
}
.blogpage-profile {
  display: flex;
  margin-left: -34%;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.blogpage-profile-image {
  margin-right: 10px; /* Space between image and text */
}

.blogpage-profile-img-seperate {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.blogpage-profile-text {
  display: flex;
  flex-direction: column; /* Stack text vertically */
}

.blogpage-para {
  margin: 0;
}

.blogpage-para span {
  display: block;
}

.blogpage-year {
  color: #738a94;
  font-weight: 400;
}
.aakjbvdsf{
  text-decoration: none;
  color: rgb(77, 77, 225);
  
}
.aakjbvdsf:hover{

  text-decoration: underline;
  color: rgb(77, 77, 225);
}

.blogpage-profile-bigimage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.blogpage-profile-bigimage-text {
  margin-bottom: 10px;
}

.blogpage-profile-bigimage-seperate {
  width: 60%;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.blogpage-profile-bigimage-text {
  color: #738a94;
  font-weight: 400;
  padding: 10px;
}

.blogpage-realcontent {
  width: 55%;
  text-align: left;
  margin: 0 auto;
  padding: 30px 30px;
}
.blogpage-realcontent-introductionheading {
}
.blogpage-realcontent-introductioncontent {
  font-size: 21px;
  padding-top: 17px;
  line-height: 33px;
  font-weight: 300;
}

.blogpage-strategic-objectives {
  padding-top: 16px;
}

.blogpage-realcontent-Strategicpara {
  padding-top: 17px;
  font-size: 21px;
  font-weight: 300;
}
.dljgn {
  padding-top: 5px;
}

.blogpage-list {
  line-height: 30px;
  padding-bottom: 15px;
  font-size: 18px;
}

.blogpage-boost {
  font-size: 21px;
  font-weight: 500;
}

/* .blogpage-list span{
    font-size: 21px;
} */

.blogpage-realcontent-innovateheading {
  padding-top: 16px;
}

.blogpage-realcontent-innovativecontent {
  font-size: 21px;
  padding-top: 17px;
  line-height: 33px;
  font-weight: 300;
}

.blogpage-realcontent-leasingstructureheading {
  padding-top: 16px;
  text-align: center;
}

.blogpage-realcontent-leasingstructureparagraph {
  font-size: 21px;
  padding-top: 17px;
  line-height: 33px;
  font-weight: 300;

  margin: 10px;
}

.blogpage-realcontent-leasingstructureparagrapdiv {
  /* display: flex;
  justify-content: space-between; */

  font-size: 21px;
  padding-top: 17px;
  line-height: 33px;
  font-weight: 300;
}

.blogpage-realcontent-conclusion {
  padding-top: 16px;
}

.blogpage-realcontent-conclusionparagraph {
  font-size: 21px;
  padding-top: 17px;
  line-height: 33px;
  font-weight: 300;
}

.lastline {
}
.lastlinehr {
  margin: 0 auto;
  border: 3px solid #735197;
  border-radius: 5px;
}

.somespacetop {
  margin-top: 2%;
}

.lastconsclusioninfourthcard{
  display: flex;
  justify-content: space-between;
}

.blogpage-unorderd {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove padding */
}


@media only screen and (max-width: 576px) {
  .blogpage-headingcontent {
    width: 90%;
    margin-left: 8%;
  }

  .CaseStudy {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.26px;
    margin-bottom: 1rem;
  }
  .CaeStudy-content {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0.72px;
  }
  .blogpage-profile {
    width: 85%;
    margin-left:-5%;
    margin-top: -5%;
    padding-bottom: 10px;
  }

  .blogpage-profile-img-seperate {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .blogpage-para {
    font-size: 11px;
  }
  .blogpage-profile-bigimage-seperate {
    width: 80%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
  }
  .blogpage-profile-bigimage-text {
    color: #738a94;
    font-weight: 400;
    padding: 10px;
    font-size: 12px;
    width: 80%;
  }
  .blogpage-realcontent {
    width: 82%;
    padding: 2px;
  }
  .blogpage-realcontent-introductionheading {
    font-size: 21px;
  }
  .blogpage-realcontent-introductioncontent {
    font-size: 12px;

    padding-top: 17px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-strategic-objectives {
    font-size: 21px;
  }
  .blogpage-realcontent-Strategicpara {
    font-size: 12px;

    font-weight: 300;
    padding-top: 2px;
  }
  .blogpage-unorderd {
    font-size: 12px;

    font-weight: 300;
  }
  .blogpage-list {
    font-size: 12px;

    font-weight: 300;
    line-height: 24px;
  }
  .blogpage-boost {
    font-size: 15px;
  }
  .blogpage-realcontent-innovateheading {

    font-size: 21px;
  }
  .blogpage-realcontent-innovateheading {
    font-size: 21px;
  }
  .blogpage-realcontent-innovativecontent {
    font-size: 12px;

    font-weight: 300;
    line-height: 24px;
  }

  .blogpage-realcontent-leasingstructureheading {
    font-size: 17px;
  }

  .blogpage-realcontent-leasingstructureparagrap {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  .blogpage-realcontent-conclusion {
    font-size: 21px;
  }

  .blogpage-realcontent-conclusionparagraph {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }


  .aakjbvdsf{
    font-size: 12px;
  }
  .somespacetop {
    margin-top: 5%;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {

.blogpage-totaldiv{
    width: 100%;
}
.blogpage-headingcontent{
    width: 80%;
}
.CaseStudy{

}
.CaeStudy-content{
    font-size: 25px;
}

.blogpage-profile{
   margin-left: -30%;
}
.blogpage-profile-text{
    font-size: 14px;
}
.blogpage-profile-bigimage-seperate {
    width: 80%;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
  }
  .blogpage-profile-bigimage-text {
    color: #738a94;
    font-weight: 400;
    padding: 10px;
    font-size: 12px;
    width: 80%;
  }
  .blogpage-realcontent {
    width: 83%;
    padding: 10px;
  }
  .blogpage-realcontent-introductionheading {
    font-size: 20px;
  }
  .blogpage-realcontent-introductioncontent {
    font-size: 16px;
    padding-top: 2px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-realcontent-Strategicpara {
    font-size: 16px;

    font-weight: 300;
    padding-top: 8px;
  }
  .blogpage-unorderd {
    font-size: 16px;

    font-weight: 300;
  }
  .blogpage-list {
    font-size: 16px;

    font-weight: 300;
    line-height: 24px;
  }
  .blogpage-boost {
    font-size: 16px;
  }
  
  .blogpage-realcontent-innovateheading {
    font-size: 20px;
    
  }
  .blogpage-realcontent-innovativecontent{
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-strategic-objectives{
    font-size: 20px;
  }
.blogpage-realcontent-leasingstructureheading{
  font-size: 18px;
}

.blogpage-realcontent-leasingstructureparagrap{
    font-size: 16px;
    line-height: 28px;
}

.blogpage-realcontent-conclusionparagraph{
    font-size: 16px;
line-height: 28px;
font-weight: 300;
}


.blogpage-realcontent-conclusion{
font-size: 20px;
}

}

@media (min-width: 769px) and (max-width: 992px) {

    
.blogpage-totaldiv{
    width: 100%;
}
.blogpage-headingcontent{
    width: 80%;
}
.CaseStudy{

}
.CaeStudy-content{
    font-size: 25px;
}

.blogpage-profile{
   margin-left: -48%;
}
.blogpage-profile-text{
    font-size: 14px;
}
.blogpage-profile-bigimage-seperate {
    width: 80%;
    height: 290px;
    border-radius: 10px;
    object-fit: cover;
  }
  .blogpage-profile-bigimage-text {
    color: #738a94;
    font-weight: 400;
    padding: 10px;
    font-size: 12px;
    width: 80%;
  }
  .blogpage-realcontent {
    width: 83%;
    padding: 10px;
  }
  .blogpage-realcontent-introductionheading {
    font-size: 20px;
  }
  .blogpage-realcontent-introductioncontent {
    font-size: 16px;
    padding-top: 17px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-realcontent-Strategicpara {
    font-size: 16px;

    font-weight: 300;
    padding-top: 8px;
  }
  .blogpage-unorderd {
    font-size: 16px;

    font-weight: 300;
  }
  .blogpage-list {
    font-size: 16px;

    font-weight: 300;
    line-height: 24px;
  }
  .blogpage-boost {
    font-size: 18px;
  }
  
  .blogpage-realcontent-innovateheading {
    font-size: 20px;
  }
  .blogpage-realcontent-innovativecontent{
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-strategic-objectives{
    font-size: 20px;
  }
.blogpage-realcontent-leasingstructureheading{
  font-size: 18px;
}

.blogpage-realcontent-leasingstructureparagrap{
    font-size: 16px;
    line-height: 28px;
}

.blogpage-realcontent-conclusionparagraph{
    font-size: 16px;
line-height: 28px;
font-weight: 300;
}


.blogpage-realcontent-conclusion{
font-size: 20px;
}
}


@media (min-width: 993px) and (max-width: 1200px) {
 
    
.blogpage-totaldiv{
    width: 100%;
}
.blogpage-headingcontent{
    width: 80%;
}
.CaseStudy{

}
.CaeStudy-content{
    font-size: 30px;
}

.blogpage-profile{
   margin-left: -51%;
}
.blogpage-profile-text{
    font-size: 14px;
}
.blogpage-profile-bigimage-seperate {
    width: 80%;
    height: 290px;
    border-radius: 10px;
    object-fit: cover;
  }
  .blogpage-profile-bigimage-text {
    color: #738a94;
    font-weight: 400;
    padding: 10px;
    font-size: 12px;
    width: 80%;
  }
  .blogpage-realcontent {
    width: 83%;
    padding: 10px;
  }
  .blogpage-realcontent-introductionheading {
    font-size: 20px;
  }
  .blogpage-realcontent-introductioncontent {
    font-size: 16px;
    padding-top: 17px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-realcontent-Strategicpara {
    font-size: 16px;

    font-weight: 300;
    padding-top: 8px;
  }
  .blogpage-unorderd {
    font-size: 16px;

    font-weight: 300;
  }
  .blogpage-list {
    font-size: 16px;

    font-weight: 300;
    line-height: 24px;
  }
  .blogpage-boost {
    font-size: 18px;
  }
  
  .blogpage-realcontent-innovateheading {
    font-size: 20px;
  }
  .blogpage-realcontent-innovativecontent{
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
  }
  .blogpage-strategic-objectives{
    font-size: 20px;
  }
.blogpage-realcontent-leasingstructureheading{
  font-size: 18px;
}

.blogpage-realcontent-leasingstructureparagrap{
    font-size: 16px;
    line-height: 28px;
}

.blogpage-realcontent-conclusionparagraph{
    font-size: 16px;
line-height: 28px;
font-weight: 300;
}


.blogpage-realcontent-conclusion{
font-size: 20px;
}   
}

@media (min-width: 1201px) and (max-width: 1440px) {
    .blogpage-totaldiv{
        width: 100%;
    }
    .blogpage-headingcontent{
        width: 80%;
    }
    .CaseStudy{
    
    }
    .CaeStudy-content{
        font-size: 35px;
    }
    
    .blogpage-profile{
       margin-left: -51%;
    }
    .blogpage-profile-text{
        font-size: 14px;
    }
    .blogpage-profile-bigimage-seperate {
        width: 80%;
        height: 320px;
        border-radius: 10px;
        object-fit: cover;
      }
      .blogpage-profile-bigimage-text {
        color: #738a94;
        font-weight: 400;
        padding: 10px;
        font-size: 12px;
        width: 80%;
      }
      .blogpage-realcontent {
        width: 83%;
        padding: 10px;
      }
      .blogpage-realcontent-introductionheading {
        font-size: 22px;
      }
      .blogpage-realcontent-introductioncontent {
        font-size: 16px;
        padding-top: 17px;
        line-height: 28px;
        font-weight: 300;
      }
      .blogpage-realcontent-Strategicpara {
        font-size: 16px;
    
        font-weight: 300;
        padding-top: 8px;
      }
      .blogpage-unorderd {
        font-size: 16px;
    
        font-weight: 300;
      }
      .blogpage-list {
        font-size: 16px;
    
        font-weight: 300;
        line-height: 24px;
      }
      .blogpage-boost {
        font-size: 19px;
      }
      
      .blogpage-realcontent-innovateheading {
        font-size: 22px;
      }
      .blogpage-realcontent-innovativecontent{
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
      }
      .blogpage-strategic-objectives{
        font-size: 22px;
      }
    .blogpage-realcontent-leasingstructureheading{
      font-size: 19px;
    }
    
    .blogpage-realcontent-leasingstructureparagrap{
        font-size: 16px;
        line-height: 28px;
    }
    
    .blogpage-realcontent-conclusionparagraph{
        font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    }
    
    
    .blogpage-realcontent-conclusion{
        font-size: 22px;
    }  

}

