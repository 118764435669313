@media screen and (min-width: 320px) and (max-width: 576px) {
    .product-img-wrap {
      display: flex;
      flex-direction: column; /* Stack images vertically */
    }
  
    .product {
      flex: 1 1 100%; /* Each image takes full width */
    }
  }
  
  
  .hide-below-330px {
      display: none;
    }
    
    @media (min-width: 330px) {
      .hide-below-330px {
        display: block;
      }
    }
  
    /* Custom class to show elements between 438px and 690px */
  .show-between-438-690px {
      display: none; /* Hide by default */
    }
    
    @media (min-width: 438px) and (max-width: 568px) {
      .show-between-438-568px {
        display: block; /* Show within the specified range */
      }
    }
  
  
    @media (min-width: 320px) and (max-width: 460px) {
    .responsiveoforders{
    
    }
    .krgbkrn{
      font-size: 14px;
    }
    }
  
  
    
  
  
    .show-between-569-1200px {
      display: none; /* Hide by default */
    }
    
    @media (min-width: 569px) and (max-width: 1600px) {
      .show-between-569-1200px {
        display: block; /* Show within the specified range */
      }
    }
  
  
   /* Unique styles for screens smaller than 576px */
  /* @media (max-width: 490.98px) {
      .custom-row {
        margin-left: 0;
        margin-right: 0;
      }
      
      .custom-col {
        padding-left: 0;
        padding-right: 0;
      }
      
      .custom-mb-3 {
        margin-bottom: 1rem; 
      }
    }
     */