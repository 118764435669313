.deliverydetailsdivv{
    display: none;
  }
  
  
  .bold-title {
    font-weight: bold; /* Makes the text bold */
    cursor: default; /* Indicates the text is not clickable */
    color: inherit; /* Keeps the text color consistent */
    text-decoration: none; /* Removes underline if styled as a link */
  }
  .breadcrumbsforsparesrevised{
    /* padding-top: 10px;
    padding-left: 45px; */
  }
  
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-container input {
    padding-left: 40px; /* Adjust padding to make space for the icon */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  .input-container .icon {
    position: absolute;
    left: 10px; /* Position icon inside the input */
    color: #aaa; /* Optional: icon color */
    pointer-events: none; /* Prevent the icon from capturing clicks */
  }
  
  
  .product-page {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 60px;
  }
  
  .rsgfbdz {
    position: relative; /* Make the parent element the reference point for the absolute positioning */
  }
  
  .rsgfbdz:before {
    position: absolute;
    left: 0;
    bottom: -4px; /* Adjusts the position if needed */
    width: 45px;
    height: 3px;
    border-radius: 10px;
    content: "";
    background-color: #999e51;
  }
  
  .heading-underline {
    width: 45px; /* Line spans full width */
    height: 3px; /* Thickness of the line */
    background-color: #999e51;
    margin-top: 2px; /* Space between text and line */
  }
  
  .beforeofproductname{
    position: relative;
  }
  
  .beforeofproductname:before{
    position: absolute;
    left: 0;
    bottom: -4px; /* Adjusts the position if needed */
    width: 45px;
    height: 3px;
    border-radius: 10px;
    content: "";
    background-color: #999e51;
  }
  .rfsbbzd {
    position: relative; /* Make the parent element the reference point for the absolute positioning */
  }
  .specification-content {
    margin-top: 2% !important; /* Adjust this value as needed */
  }
  
  
  .rfsbbzd:before {
    position: absolute;
    left: 0;
    bottom: -4px; /* Adjusts the position if needed */
    width: 45px;
    height: 3px;
    border-radius: 10px;
    content: "";
    background-color: #999e51;
  }
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    /* height: 120px; */
    width: 30px !important;
  
  }
  .show-more-wrapper {
    display: inline-block;
    line-height: 1;
  }
  
  .desc-value {
    display: inline-flex;
    flex-direction: column;
    gap: 0;
    /* font-size: 14px; */
  }
  
  .token-descc{
    font-size: 14px !important;
  }
  .show-more-btn {
    margin-top: 1%;
    color: blue;
    border: none;
    margin-bottom: 0;  /* Correct way to remove bottom margin */
    background-color: white;
    display: inline-flex;  /* This will help control the spacing */
    align-items: center;
    padding: 0;  /* Remove any default padding */
  }
  .fixed {
    position: fixed;
    top: 80px; /* Set to your navbar height */
    z-index: 10; /* Ensures it's above other elements */
  }
  
  
  
  
  
  
  
  
  
  .product-images {
    position: sticky;
    top: 180px; /* Same height as navbar */
    z-index: 10;
  }
  
  .product-pricing {
    position: sticky;
    top: 160px; /* Same height as navbar */
    z-index: 10;
  }
  
  .fanagkeiu{
    margin-left: 10px;
  }
  
  .column {
    flex: 1;
    min-width: 300px;
  }
  
  .product-info {
    flex: 1.6;
  }
  
  .buttonclickednodvjbktorbfdg {
    padding: 12px;
    background-color: #fafafa;
    border: none;
    font-size: 16px;
    border-radius: 8px;
  }
  
  .buttonclickednodvjbktorbfdg:hover {
    border-radius: 8px;
    transition: 0.4s;
  }
  .btn-clicked {
    background-color: #73509e;
    color: white;
    /* transform: scale(0.95); */
  }
  
  
  
  
  .buttons-in-type {
  
  } 
  
  .rszbfdntzdg{
    display: flex!important;
    align-items: center!important;
    gap: 4px !important;
    flex-wrap: wrap  !important;
  }
  .btn-clicked:hover {
    background-color: #73509e;
    color: white;
    /* transform: scale(0.95); */
    transition: none;
  }
  
  .product-images {
    width: 400px;
    /* top:0; */
    height: min-content;
    flex: none;
    
    /* padding: 20px; */
  }
  .product-images img {
    width: 100%;
    height: auto;
  }
  
  .product-info {
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09); */
    padding: 12px;
    min-height: 600px;
  }
  .product-pricing {
    width: 250px;
    height: min-content;
    flex: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
  
  }
  
  .hiehgtoftextpart{
    line-height:0.9rem;
  }
  
  .price-with-tax
  {
    font-size: 14px !important;
    color: gray;
    font-size: 0.9rem;
    font-weight: 500;
  
  
  
  }
  .bucketSixeeee{
    font-size: 14px !important;
  }
  
  .offer-price-fgg{
    color: #58cb58;
    padding-left: 5%;
    font-weight: 600;
  }
  .basic-info {
    line-height: 0.8rem;
  }
  .info-row {
    line-height: 1rem;
  }
  
  .spec-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .spec-title {
    font-weight: bold;
  }
  
  .spec-detail {
    color: #555;
  }
  
  .busbfbrftton {
    margin-top: 12px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .busbfbrftton:hover {
    background-color: #0056b3;
  }
  
  .product-info h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .product-info table {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .product-info .offers {
    background-color: yellow;
    border: 2px dotted black;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .product-info .offers .offer {
    margin-bottom: 10px;
  }
  
  .product-info .variants button,
  .product-info .quantity button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .product-pricing h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .product-pricing button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #73509e;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .product-pricing button:hover {
    background-color: #5a3d7e;
  }
  
  
  
  
  
  
  
  .quantity-controls {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .quantity-for-tools{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .quantity-for-toolssfbfgn{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .quanty-textt{
  font-size: 16px;
      font-weight: 600;
      
      padding: 0 10px;
     
    color: rgb(0, 0, 0) !important;
    background-color: white !important;
    border: 1px solid #e2e2e2 !important;
    border-radius: 10px !important;
    width: 70px !important;
    height:33px !important;
  
    transition: background-color 0.3s ease !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .update-qty{
    font-weight: 600; 
    font-size: 16px;
  }
  .buttons-in-addmore {
    display: flex;  /* Use flexbox to align buttons */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    gap: 4px; /* Adjust this value to control the space between buttons */
  }
  
  .quantity-button-for-tools{
  
  margin-top: 4%;
    background-color: #73509e !important;
    color: white !important;
    border: none !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .quantity-selector-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .quantity-selector {
    display: flex;
    align-items: center;
    gap: 3px;
  }
  
  .quantity-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .quantity-button {
    /* background-color: #73509e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px; */
    border-radius: 4px;
    cursor: pointer;
  }
  
  .quantity-value-box {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .box-item-btn {
    display: inline-block;
    padding: 10px;
    margin: 5px;
    border-radius: 9px;
    /* font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #73509e;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px; */
  }
  
  .box-item-btn:hover {
    background-color: #5a3d7e;
  }
  
  .btns-group {
    display: flex;
    gap: 0px;
  }
  
  .fnvnvfnvnvnv{
  
    padding: 8px, 0px, 8px, 0px  !important;
    border-radius: 12px!important;
    font-weight: 600;
    padding: 5px;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.6875rem !important;
    letter-spacing: 0em !important;
  
  
  }
  
  .fnvnvfnvnvnv:hover{
  
  }
  
  
  .fnvnvfnvnvnvfvsbfv{
    padding: 8px, 0px, 8px, 0px  !important;
    border-radius: 12px!important;
     background: #FFFFFF !important;
     font-size: 1rem !important;
     font-weight: 600 !important;
     line-height: 1.6875rem !important;
     letter-spacing: 0em !important;
     border: 2px solid #73509E !important;
     color: #73509E !important;
  }
  
  .fnvnvfnvnvnvfvsbfv:hover{
  background-color: rgb(247, 247, 247)  !important;
  }
  .info-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
  .headingblnan {
    font-weight: 600;
  }
  
  .answerblan {
    text-align: right;
  }
  
  
  
  .bottom-sticker {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background-color: white;
      border-top: 1px solid #dad9d9;
      z-index: 100;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.01);
      flex-direction: row;
  }
  
  .left-info {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      flex: 1;
  }
  
  .brand-name {
      font-weight: 400;
  
      text-transform: uppercase;
  }
  
  .product-name {
      font-weight: 700;
  }
  
  .right-info {
      text-align: right;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  }
  
  .price-and-pack {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  }
  
  .pack-info {
      font-size: 14px;
      color: #666;
  }
  
  .price {
      font-size: 20px;
      color: #000;
      margin: 5px 0;
  }
  .pricelndjbgd{
      font-weight: bold;   
  }
  
  .discount-price {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #666;
      font-size: 14px;
  }
  
  .old-price {
      text-decoration: line-through;
      color: #999;
  }
  
  .per-ltr-price {
    
  }
  
  .discount {
      color: green;
      font-weight: bold;
      font-size: 14px;
  }
  
  .bottom-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
  }
  
  .quantity-controls {
      display: flex;
      align-items: center;
      gap: 10px;
  }
  
  .quantity-btn {
      background-color: #73509e;
      color: white;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .quantity-btn:hover {
      background-color: #5b3b7e;
  }
  
  .quantity {
      font-size: 18px;
      font-weight: bold;
      padding: 0 10px;
  }
  
  .btns-group {
      display: flex;
      align-items: center;
      gap: 10px;
  }
  
  .threebuttonsinn,
  .buy-nowsjdvsf {
      border-radius: 8px;
      font-weight: 600;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
      transition: transform 0.2s ease;
      white-space: nowrap;
  }
  
  .threebuttonsinn:hover,
  .buy-nowsjdvsf:hover {
      transform: translateY(-2px);
  }
  
  .buy-nowsjdvsf {
      background-color: transparent;
      color: #73509e;
      border: 2px solid #73509e;
  }
  
  .threebuttonsinn {
      background-color: #73509e;
      color: white;
      border: none;
  }
  
  .dividing-two-divsleft-and-right {
      display: flex;
      justify-content: space-between;
      width: 100%;
  }
  
  .total-origa-benefits{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  
  .quantity-box {
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      padding: 18px;
  }
  
  
  .box-inner{
      /* border: 1px solid #ddd; */
      border-radius: 8px;
      padding: 18px;
  }
  
  .basic-info{
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      padding: 18px;
  }
  
  .product-specifications{
       border: 1px solid #e9e9e9;
      border-radius: 8px;
      padding: 18px;
    
  }
  
  .pricessss{
  margin-right: 10px;
  display: flex;
      align-items: center;
  width: 100%;
  }
  .insideanswerfortools {
    flex: 1; /* Makes the text take remaining space */
  }
  
  .product-specificationsavsdz{
    border: 1px solid #e9e9e9;
    margin-right: 10px;
   border-radius: 8px;
   padding: 18px;
  }
  
  
  .total-product-page{
    padding-left: 0;
    padding-right: 0;
      padding-bottom: 0; 
  }
  
  .typeheadingfortools{
    font-size:16px !important;
    }
    
    .buttonclickednodvjbktor{
    font-size:14px !important;
    }
    
    .insideheadingfortools{
    font-size:15px !important;
    }
    
    .insideanswerfortools{
    font-size:14px !important;
    }
  
  
  @media (min-width: 1074px) {
      .bottom-sticker {
        display: none;
      }
      
      
      .product-page {
        padding-bottom: 0;
        
      }
  }
  
  @media (max-width: 1072px) {
      .product-page {
        padding-left: 0;
        padding-top: 12px;
        padding-right: 0;
       
      }
      .total-product-page{
        padding-left: 0;
        padding-right: 0;
          padding-bottom: 0; 
      }
  
      .product-pricing {
          display: none;
      }
      .deliverydetailsdivv{
        display: block;
      }
  }
  
  @media (max-width:740px){
      .product-images {
       
        position: static;
      }
      
      
      .product-pricing {
       
        position: static;
      }
      .total-origa-benefits {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px; /* Adjusts space between items for mobile */
    }
  
    .pricessss {
        flex: 1 1 45%; /* Each item takes half the row width */
        gap: 6px;
        align-items: center;
    }
  
    .insideanswerfortools {
        font-size: 14px;
    }
  
  }
  
  
  @media (max-width: 1074px) {
      .product-pricing {
          display: none;
      }
      .bottom-sticker {
          flex-direction: column; 
          align-items: flex-start;
      }
      
  
      .btns-group {
        width: 100%;
        justify-content: space-between;
      }
  
      .threebuttonsinn,
      .buy-nowsjdvsf {
        flex: 1;
        text-align: center;
      }
      .total-origa-benefits {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px; /* Adjusts space between items for mobile */
    }
  
    .pricessss {
        flex: 1 1 45%; /* Each item takes half the row width */
        gap: 6px;
        align-items: center;
    }
  
    .insideanswerfortools {
        font-size: 14px;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 740px) {
    .each-individual-card-for-similarmachines:hover {
      transform: none;
      box-shadow: none;
    }
  .product-images {
    /* width: 80%!important; */
    margin: 0 auto;
    position: static !important;
  }
  }
  
  .breadcrumbsforsparesrevised{
    display: flex;
    justify-content: space-evenly;
  }
  @media screen and (min-width: 300px) and (max-width: 576px) {
  
  .breadcrumbsforsparesrevised{
    margin-left: -2%;
  }
    .each-individual-card-for-similarmachines:hover {
      transform: none;
      box-shadow: none;
    }
    .bottom-sticker {
          padding: 10px;
      }
  
      .product-page {
        padding-left: 0;
        padding-right: 0;
         margin-left: 4%;
      }
      .brandandnameheadingtools{
        font-size: 18px  !important;
      }
      .typeheadingfortools{
        font-size: 16px  !important;
      }
      .insideheadingfortools{
        font-size: 15px  !important;
      }
      .insideanswerfortools{
        font-size: 14px  !important;
      }
      .buttonclickednodvjbktor{
        font-size: 14px!important;
      }
      .total-product-page{
        padding-left: 0;
        padding-right: 0;
          padding-bottom: 0; 
      }
  
      .product-images{
          min-height: 0;
      }
      .price {
          font-size: 18px;
      }
  
      .quantity-btn {
          width: 25px;
          height: 25px;
          font-size: 16px;
      }
  
      .btns-group {
          display: flex;
      }
      .product-pricing {
          display: none;
      }
      .threebuttonsinn,
      .buy-nowsjdvsf {
          padding: 6px 12px;
          font-size: 12px;
      }
      .discount-price{
          gap:5px;
      }
      .brand-name{
          font-size: 0.95rem;
      }
  
      .fixed {
       
        z-index: 0; /* Ensures it is not above other elements */
      }
      
      .product-images {
        margin: 0 auto !important;
        position: static;
        padding-top: 12px;
        width: 200px !important;
      }
      
      .product-pricing {
       
        position: static;
      }
      .total-origa-benefits {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px; /* Adjusts space between items for mobile */
    }
  
    .pricessss {
        flex: 1 1 45%; /* Each item takes half the row width */
        gap: 6px;
        align-items: center;
    }
  
    .insideanswerfortools {
        font-size: 14px;
    }
    .similar-products-heading{
      font-size: 16px !important;
      padding-left: 2% !important;
      font-weight: 600! important;
    }
  
    .slick-next {
      right: -10px !important;
      z-index: 1;
    }
    
    .slick-prev {
      left: -10px !important;
      z-index: 1;
    }
    .each-individual-card-for-similarmachines{
      margin-left: 5% !important;
    }
  
    .buttons-in-addmore {
      gap: 10px;
    }
  
    .headingblnan {
      font-weight: 600;
      flex: 1; /* Allow heading space to grow as needed */
      max-width: 50%; /* Prevent it from taking too much space */
    }
    
    .answerblan {
      text-align: right;
      flex: 1; /* Allow answer space to grow as needed */
      max-width: 55%; /* Keep it on the right side */
    }
    
  }
  
  @media screen and (min-width: 180px) and (max-width: 299px) {
    .each-individual-card-for-similarmachines:hover {
      transform: none;
      box-shadow: none;
    }
    .product-pricing {
          display: none;
      }
      .product-images {
       
        position: static;
      }
      .total-origa-benefits {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px; /* Adjusts space between items for mobile */
    }
  
    .pricessss {
        flex: 1 1 45%; /* Each item takes half the row width */
        gap: 6px;
        align-items: center;
    }
  
    .insideanswerfortools {
        font-size: 14px;
    }
      
    
  
      .product-images{
          min-height: 0;
      }
      .threebuttonsinn,
      .buy-nowsjdvsf {
          padding: 6px 12px;
          font-size: 12px;
      }
  }
  
  