.totalcustomerdiv {
  width: 100%;
  overflow: auto;

}

.left-side-customerdiv {
  float: left;
  width: 50%;
}

.left-side-customerdiv h2 {
  width: 70%;
  text-align: left;

}

.left-side-customerdiv p {
  width: 70%;
  line-height: 24px;
  text-align: left;

}

.imagelogoleftside {
  margin-left: 19%;
}

.textinp {
  width: 70%;
  margin-left: 19%;
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;

}

.Companytext {
  margin-top: 8%;
  line-height: 124px;
}

.button-left-side {
  line-height: 60px;
  margin-left: 19%;
}

.Read-the-story-btn {
  padding: 10px 15px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid;
  border-radius: 12px;
  color: white;
  line-height: 24px;
  background-color: #73509e;
  cursor: pointer;

}

.right-side-customerdiv {
  float: right;
  width: 50%;
}

.right-side-customerimg{
  width: 100%;
  height: 350px;
  object-fit: cover;

 
}
.howcustomerspara{
  display: flex;
  justify-content: center;
  padding: 10px;
}
.howcustomerspara p{
  font-weight: 500;
  font-size: 16px;
}



@media only screen and (max-width: 576px) {
  .totalcustomerdiv {
    flex-direction: column-reverse; /* Reverse the order of flex items */
    align-items: center;
  }

  .left-side-customerdiv {
    margin-top: 20px; /* Add space between the two sections */
  }

  .right-side-customerdiv {
    order: -1; /* Move this section to the top */
    margin-left: 0; /* Remove margin between sections */
    margin-bottom: 20px; /* Add space below the top section */
  }

  .right-side-customerimg{
    height:220px;
 
  }
  .imagelogoleftside {
    height: 45px;
    width: 85px;
    margin-top: -30%;
    margin-left: 13%;
  }

  .left-side-customerdiv h3 {
    font-size: 13px;
    line-height: 15px;
  }
.Companytext{
  font-size: 14px;
}
  .textinp {
    /* Adjust text styles as needed */
    font-size: 14px;
    line-height: 10px;
    margin-left: 14%;
    margin-top: -25%;
  }

  .button-left-side {
    margin-left: 11%;
    margin-top: -5%; /* Adjust button margin */
  }

  .Read-the-story-btn {
    font-size: 9px;
    padding:10px 10px;
    line-height: 5px;
  }
  .howcustomerspara{
    width:80%;
    margin-left: 10%;

  }

  .howcustomerspara p{
    font-size: 12px;
    text-align: center;
  }
}

 
 
@media (min-width: 577px) and (max-width: 768px) {
  .Companytext , .textinp {
   
  font-size: 19px;
  }
  .textinp{
    width: 60%;
    line-height: 22px;
  }
  .Read-the-story-btn {
    font-size: 12px;
    padding:13px 13px;
    line-height: 5px;
  }
  .howcustomerspara{
    margin-left: 19%;
    text-align: center;
    width: 60%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {

}
@media (min-width: 993px) and (max-width: 1200px) {

}
@media (min-width: 1201px) and (max-width: 1440px) {

}
@media (min-width: 1441px) {

}