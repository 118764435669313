.LookingForBuySellLeft-maindiv{
    background-color:white;
    /* padding: 0; */
    margin: 3rem 0rem 0rem 0rem ;
}
.LookingForBuySellLeft-headingdiv{
    width: 25.5rem;
    height: 2rem;
    display: flex;
    gap: 1rem;
}
.LookingForBuySellLeft-navbar {
    /* width: 9.1875rem; */
    height: 2rem;
    text-decoration: none;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0px;
text-align: left;
color: #211E24;
opacity: 60%;
}
.LookingForBuySellLeft-navbar:hover{
    text-decoration: none;
    color: #211E24;
}
.LookingForBuySellLeft-navbar.active{
font-size: 1.25rem;
font-weight: 600;
opacity: 100%;
border-bottom: 3px solid #9BA24C;
}
