.modal-overlaysfbdj {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.modal-contentdvsbf {
    background-color: white; 
    padding: 20px;
    width: 25%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column; 
    align-items: center; 
}

.pleasefillin{
    font-size: 20px;
    font-weight: 600;
}
.sfdbjgn{
    text-align: left;
    font-size: 14px;
}
.modal-content h2 {
    margin: 0 0 10px;
    color: black; 
}

.modal-content ul {
    padding-left: 20px; 
}

.modal-content p, .modal-content ul {
    margin: 0 0 20px;
    color: black; 
}

.buttonsjxfbhdf {
    width: 180px;
    height: 3.375rem;
    border-color: #73509E;
    padding: 8px 0px;
    border-radius: 12px;
    background-color: #73509E;
    color: white;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6875rem;
    letter-spacing: 0em;
    cursor: pointer;
}

.buttonsjxfbhdf:hover {
    background-color: white; /* Invert colors on hover */
    color: #73509E;
    border-color: #73509E;
}



@media (max-width: 600px) {
    .modal-contentdvsbf {
        width: 58%; 
    }
    
    .pleasefillin {
        font-size: 18px;
    }

    .sfdbjgn {
        font-size: 12px;
    }

    .buttonsjxfbhdf {
        width: 160px; 
    }
}

@media (min-width: 601px) and (max-width: 991px) {
    .modal-contentdvsbf {
        width: 50%; 
    }
}

@media (min-width: 992px) {
    .modal-contentdvsbf {
        width: 30%; 
    }
}