.aaaaajjjjj{
  overflow-y: auto;
}

.summarypagestylediv{
  display: flex;
  justify-content: center;   
 }
 
 
 .unique-submitbtnsbbf{
 
     padding: 10px 20px;
     font-family: Poppins;
     font-size: 16px;
     font-weight: 500;
     border: 1px solid;
     border-radius: 12px;
     color: white;
     line-height: 24px;
     background-color: #73509e;
     cursor: pointer;
   }

   
 
   .unique-submitbtrsbrsn{
    width: 20%;
    padding: 10px 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid;
    border-radius: 12px;
    color: white;
    line-height: 24px;
    background-color: #73509e;
    cursor: pointer;
   }
 .twodivborderseperate{
    gap:30px;
 }
   .DeliverAddresContentText{
     font-size: 1.2rem;
     
   }
   .leftsideborderdeliverypart{
 border: 1px solid  rgb(221, 211, 211);
 border-radius: 5px;
 height: min-content;
 padding-top:10px ;
   }
 
 
   .rightsidebordersummarypart{
     border: 1px solid  rgb(221, 211, 211);
     border-radius: 5px;
   }
 
 
   .addressfirstname{
 
     text-transform: uppercase;
 
   }
 
   .headingindelivery{
     font-weight: 600;
   }
 
 
   
   .lineofcartbottomiin{
     width: 104%;
     border: 0;
     height: 1px;
     top: -8px;
     position: relative;
     margin: 20px auto;
     left: -2%;
     color: gray;
   }
 
   /* .lineofcartbottomiinvjnbf{
     width: 64%;
     border: 0;
     height: 1px;
     top: -8px;
     position: relative;
     margin: 20px auto;
     left: -2%;
     color: gray;
   } */
 
   .botttomsummarycartdiv{
     background-color:  #000000;
   }
 
   .chooseashippingaddress{
     width: 85%;
     text-align: center;
     padding-top: 1%;
     margin-left: 8%;
   }
 
   .buttondivsummaryaddress{
 display:block;
 /* margin: 0 auto; */
 padding-top: 3%;
   }
 
   .usethisaddressinbutton{
 padding: 9px 25px; 
 border-radius: 12px;
 font-size: 14px;
 font-weight: 400;
 background-color: #73509e;
 color: white;
 /* margin-left:33%; */
 border: none;
   }
 
   .usethisaddressinbuttondbb{
     padding: 9px 25px; 
 border-radius: 12px;
 font-size: 14px;
 font-weight: 400;
 background-color: #73509e;
 color: white;
 font-weight: 600;
 border: none;
   }
 
   .pricing-details {
     max-width: 400px;
     margin: 20px auto;
     padding: 20px;
     border: 1px solid #ccc;
     border-radius: 10px;
     background-color: #fff;
   }
   
   .pricing-item {
     display: flex;
     justify-content: space-between;
     padding: 10px 0;
     font-size: 16px;
   }
   
   .total, .order-total {
     font-weight: bold;
   }
   
   .promotion {
     color: red;
   }
   .GSRGSKJNRBN{
 padding-bottom: 13px;
   }
 
 
   .ordersummaryheading{
     margin-left: 10%;
   }
 
 
 
   .popupforcartpageinedit{
   
   
   }
   .popup-banner {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: rgba(0, 0, 0, 0.5);
     z-index: 1000;
   }


 
   .popup-content {
     position: relative;
     background: white;
     padding: 20px;
     border-radius: 8px;
     text-align: center;
     max-width: 90%;
     width: 500px;
   }

   .popup-contentADDRESS {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 90%;
    width: 900px;
     z-index: 1001;

  }

   .popup-contentedvbff{
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 90%;
    width: 900px;
   }
   
   .popup-content img {
     width: 100%;
     height: auto;
   }
   
   .close-popup {
     position: absolute;
     top: 0px;
     right: 6px;
     cursor: pointer;
   }
 
 
   .popup-overlay{
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
     z-index: 1000; /* Ensure it overlays everything else */
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .popup-overlayformachines{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 4000; /* Ensure it overlays everything else */
    display: flex;
    justify-content: center;
    align-items: center;
  }
   @media (max-width: 576px) {
 
 .leftsideborderdeliverypart{
  /* height: 360px; */
 }.addressheading{
font-size: 16px;
 }
 .headingindelivery{
font-size: 14px;
 }
 .usethisaddressinbuttondbb{
  font-size: 1px;
 }

 .usethisaddressinbutton{
  margin-left: 10%;
  font-size: 12px;
  padding: 8px 22px;
 }
 .chooseashippingaddress{
font-size: 14px;
}
.ordersummaryheading{
font-size: 14px;
}
.pricing-item{
  font-size: 14px;
}

  p .answerdelivery {
    font-size: 14px;
  }
   }
 




   @media (min-width: 577px) and (max-width: 768px) {
 
 
   }
 
   @media (min-width: 769px) and (max-width: 992px) {
 
 
   }
 
 
   @media (min-width: 993px) and (max-width: 1200px) {
 
 
   }
 
   @media (min-width: 1201px) and (max-width: 1440px) {
     .usethisaddressinbuttondbb{
         margin-left: 5%;
     }
    
   }


   /* Address container */
   .address-container {
   
    padding: 5px;
    border-radius: 8px;
    max-width: 100%;
    width: 90%;
    margin: 0 auto;
    max-height: 500px; /* You can adjust the height */
    overflow-y: auto; /* Enables vertical scroll */
  }
.address-wrapper {
  background-color: #F9F9F9;
  padding: 20px;
  border-radius: 8px;
}

.address-header {
  display: flex;
  align-items: center;
}

.add-new-address {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #73509E;
}

.add-new-address-text {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

/* Address card */
.address-card {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  text-align: left;
  background-color: white;
}

.address-selection {
  display: flex;
  text-align: left;

}

.address-name {
  font-size: 18px;
  font-weight: 600;
}

.address-details {
  font-size: 14px;
  color: #666;
 
}

.address-details span {
  display: block;

}

.factory-name {

}

.address-contact {
 display: block;
  padding-top: 8px;
}

.address-contact p {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
}

.edit-addreskfsjds {
 
 display: flex;
 align-items: center;
  color: #73509E;
  cursor: pointer;
 
}



/* No address found */
.no-address-found {

  padding: 40px;
}
.editaddressheadingdf{
  display: flex;
  text-align: left;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.add-address-btn {
  background-color: #73509E;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.deliver-here-container {
 display: flex;
 margin-left: 20px;
 justify-content: flex-start;
  margin-top: 20px;
}

.deliver-here-btn {
  background-color: #73509E;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



/***********************************************************************************************************************************************************************/
.button-in-summarycartpage{
  display: flex;
  justify-content: center;
}