.maindiv {
  display: flex;
  flex-direction: column;
}

.homeCardBorder {
  border-right: 1px solid rgba(115, 80, 158, 0.10);
  border-left: 1px solid rgba(115, 80, 158, 0.10);
  border-radius: 10px;
}

