/* .right-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  /* .advance-info{
    margin-left: 190%; 
} */


/* .block-info{
    padding-top: 8%;
}

.advance-info{
    padding-top: 2%;

    */

   
    .bold-text {
        font-weight: 500;
      }

    .tobetopfirst{
        padding-top: 40px;
        border-bottom: none;
    }
    .tobetop{
        padding-top: 60px;
    }
    /* .quantity-selector {
        display: flex;
        align-items: center;
        gap:8px; 
      } */

/* 
     
      .custom-badge {
       
        background-color: #6c757d;
        padding: 0px 15px;
        color: #fff;
        border-radius: 4px;
        font-size: 16px;

      } */

      .quantity-selector {
        display: inline-flex; /* Display flex items in a row */
        align-items: center; /* Align items vertically */
      }
      
  
      
      .custom-badge {
        display: inline-block;
        padding: 3px 12px;
        background-color: #6c757d;
        color: #fff;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        margin: 0 8px/* Adjust margin */
      }
      
      
    /* .call-to-action-main {
        display: flex;
        column-gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
      }
      .call-to-action-main .btns-wrap {
        display: flex;
        column-gap: 12px;
      }
      .call-to-action-main .btns-wrap .buy-now {
        background: none !important;
        border: 1px solid #73509e !important;
        color: #73509e !important;
      } */
      .box-item-btn1 {
        height: 33px;
        background: #73509e;
        color: #fff;
        border-radius: 12px;
        border: none;
        padding: 0 15px;
      }

      .quantity-selector{

      }

      

      @media (max-width: 576px) {
        /* CSS styles here */
        .name{
            margin-top: -60%;
        }
        .price{
            margin-top: 1%;
        }
        .bottom{
            margin-top: 5%;
        }
      }

      @media (min-width: 577px) and (max-width: 640px) {
        /* CSS styles here */
        .tobetopfirst{
            margin-top: -12%;
        }
        .price{
            margin-top: 2%;
        }
        .bottom{
            margin-top: -8%;
        }

      }

      @media (min-width: 642px) and (max-width: 767.98px) {
       
    }

      
      
      /* CSS for screens between 768px and 991.98px (typically tablets in landscape mode and smaller desktop screens) */
      @media (min-width: 768px) and (max-width: 991.98px) {
        /* CSS styles here */
      }
      
      /* CSS for screens between 992px and 1199.98px (typically larger tablets and medium-sized desktop screens) */
      @media (min-width: 992px) and (max-width: 1199.98px) {
        /* CSS styles here */
      }
      
      /* CSS for screens 1200px and larger (typically larger desktop screens) */
      @media (min-width: 1200px) {
        /* CSS styles here */
      }