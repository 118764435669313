/* Base styles for the container and inner elements */
.address-container {
    padding: 16px;
    box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

.address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.edit-address-container {
    display: flex;
    align-items: center;
}

.edit-address {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.company-details-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 16px;
    box-sizing: border-box;
}

.company-details, 
.personal-details, 
.shipping-address, 
.billing-address {
    padding: 8px;
    border: 1px solid #ccc; /* Add a border for better visual separation */
    border-radius: 4px; /* Optional: rounded corners */
    word-wrap: break-word; /* Ensure long words or URLs break to fit within the container */
}

/* Styling for the headings */
.headingindelivery {
    font-weight: bold;
}

.headingofamc {
    font-weight: normal;
    margin-right: 5px;
}


.proceedcartbtnnew{
    margin-left: 25%;
    width: 40%;
}
.centerbuttonpayment{
    display: flex;
    justify-content: center;
}

.btnofamc{
   
}
/* Media queries for screens 576px and below */
@media (max-width: 942px) {
    .btnofaedsbfmc{
        font-size: 8px;
    }
    
    .address-header {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
    }

    .edit-address-container {
        margin-top: 8px; /* Space between Address and Edit Address button */
    }

    .company-details-container {
        grid-template-columns: 1fr; /* Stack all items in a single column */
        gap: 8px; /* Reduce gap between sections */
    }

    .company-details,
    .personal-details,
    .shipping-address,
    .billing-address {
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align text to the start */
    }

    .company-details-container span {
        display: inline-block;
        width: 100%; /* Take up full width */
        margin-bottom: 4px; /* Add space between lines */
    }

    .company-details-container .headingindelivery,
    .company-details-container .headingofamc {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .company-details-container span {
        font-size: 12px; /* Smaller text for mobile screens */
    }

    .proceedcartbtnnew {
       margin-left: 25%;
       font-size: 14px;
        width: 40%; /* Ensure button takes full width */
        padding: 12px; /* Add some padding for better click area */
    }
}


@media (min-width: 577px) and (max-width:900){
  
    .proceedcartbtnnew {
        margin: 0 auto; /* Center the button horizontally */
        display: block; /* Make button a block-level element */
        width: 50%; /* Adjust width as needed */
        padding: 12px; /* Add padding for better click area */
        font-size: 14px; /* Adjust font size */
    }

}






@media (min-width:769px ) and (max-width:940px){


}