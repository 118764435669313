@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));  
    gap:19px; 
    padding: 10px;
    /* width: 65%; */
    width: 85%; 
    max-width: 1600px; 
    margin: auto; 
  }
  
.cardinndd {
  width: 300px; 
  height: auto;
  border: 0.2px solid #dddddd;
  border-radius: 8px;
  overflow: hidden;

}

.top-part {
  position: relative;
}

.top-part img {
  width: 100%;
  height: auto;
  
}

.card-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #1560bd;
  color: white;
  padding: 6px 6px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  transition: top 0.3s ease; /* Smooth transition */
}

.card-label:hover {
  cursor: default;
  top: 5px; /* Move the label up on hover */
}


.card-label.case-study {
  background-color: #1560bd;
}

.card-label.blog {
  background-color: #735197;
}


.titlelogo-top-part {
  position: absolute;
  top: 2%;
  left:28%;
  border: 0.5px gray;
  border-radius: 10px;
  transform: translate(-70%, 70%);
}

.titlelogo-top-part img {
  width: 80px; /* Adjust size as needed */
  height: 30px;
  padding: 0px 0px;
  image-rendering: -webkit-optimize-contrast; /* Improves contrast on WebKit browsers */
  image-rendering: crisp-edges; /* Ensures crisp edges on supported browsers */
  image-rendering: pixelated; 
  background-color: white;
  border: 0.5px gray;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bottom-part {
  padding: 16px;
}
.Heading-bottom-text-part {
  font-weight: 600;
  color: black;
  font-size: 16px;
  line-height: 22px;
}
.para-text-bottom-part {
    margin-top: 3%;
  font-family: 400;
  font-size: 13px;
  font-family: "Poppins";
  color: rgb(99, 99, 99);
  line-height: 21px;
}
.read-now-btn {
  cursor: pointer;
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 22px 5px 22px;
  border: 2px solid #505050; 

}
.read-now-btn:hover{
  color:white;
  transition: 0.3s ease-in-out;
  background-color: #505050;
}
.bottom-part {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .text-bottom-part {
    flex: 1;
  }
  
  .button-bottom-part {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 12%;
  }
  
  .read-now-btn {
    cursor: pointer;
    border-radius: 24px;
    font-size: 11px;
    font-weight: 500;
background-color: white;
color: black;
    border: 2px solid #505050; 
    padding: 6px 12px 6px 12px;
  }
/* For screens up to 576px */
@media only screen and (max-width: 576px) {
  .cards {
    width: 90%; /* Adjust width to take up more space on smaller screens */
    margin: 0 auto; /* Center align cards */
  }

  .cardinndd {
    width: 100%; /* Make cards take up full width */
    margin-bottom: 20px; /* Add space between cards */
  }


  .card-label {
    font-size: 10px;
    padding: 2px 6px;
  }

  .titlelogo-top-part {
    left: 0; /* Align to the left border */
    transform: translate(0%, 70%); /* Adjust transform if necessary */
  }

  
  .titlelogo-top-part img {
    width: 100px; /* Adjust size for smaller screens */
    height:30px; /* Maintain aspect ratio */
  }

  .Heading-bottom-text-part {
    font-size: 14px; /* Adjust font size */
  }

  .para-text-bottom-part {
    font-size: 12px; /* Adjust font size */
  }

  .read-now-btn {
    font-size: 10px; /* Adjust font size */
    padding: 5px 10px; /* Adjust padding */
  }
}

/* For screens from 577px to 768px */
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .cards {
    width: 75%; /* Adjust width to take up more space on smaller screens */
    margin: 0 auto; /* Center align cards */
  }

  .cardinndd {
    width: 100%; /* Make cards take up full width */
    margin-bottom: 20px; /* Add space between cards */
  }

 
  .card-label {
    font-size: 12px;
    padding: 4px 8px;
  }

  .titlelogo-top-part {
    left: 0; /* Align to the left border */
    transform: translate(0%, 70%); /* Adjust transform if necessary */
  }

  .titlelogo-top-part img {
    width: 100px; /* Adjust size for smaller screens */
    height:30px;/* Maintain aspect ratio */
  }

  .Heading-bottom-text-part {
    font-size: 14px; /* Adjust font size */
  }

  .para-text-bottom-part {
    font-size: 12px; /* Adjust font size */
  }

  .read-now-btn {
    font-size: 10px; /* Adjust font size */
    padding: 5px 10px; /* Adjust padding */
  }
}

/* For screens from 769px to 992px */
@media (min-width: 769px) and (max-width: 992px) {
  .cards {
    width: 75%; /* Adjust width */
    margin: 0 auto; /* Center align cards */
    gap: 20px; /* Adjust space between cards */
  }

  .cardinndd {
    width: 100%; /* Full width for smaller screens */
  }
  .titlelogo-top-part {
    left: 0; /* Align to the left border */
    transform: translate(0%, 70%); /* Adjust transform if necessary */
  }

  .titlelogo-top-part img {
   
    width: 100px; /* Adjust size for smaller screens */
    height:30px; /* Maintain aspect ratio */
  }

  .Heading-bottom-text-part {
    font-size: 16px; /* Adjust font size */
  }

  .para-text-bottom-part {
    font-size: 14px;
  }

  .read-now-btn {
    font-size: 12px; 
    padding: 5px 12px; 
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .cards {
    width: 85%; 
    margin: 0 auto; 
    gap: 20px;
  }

  .cardinndd {
    width: 100%; 
  }
}


@media (min-width: 1441px) and (max-width: 1800px) {
  .cards {
   
    margin: 0 auto; 
    gap: 20px;
  }

  .cardinndd {
    width: 100%; 
  }

}


/* 

@media (min-width: 1441px) and (max-width: 1800px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    gap:19px; 
    padding: 10px;
    width: 85%; 
    max-width: 1400px; 
    margin: auto; 
  }
}

@media (min-width:1801px){

} */