/* 
.order-container {
  display: flex;
  flex-direction: column; 
  gap: 16px; 
}

.order-itemed {
  display: flex;
  align-items: center; 
width: 40%;
  padding: 8px;

}

.product-wrapEBedd {
  display: flex;
  align-items: center; 
  width: 100%;
}

.left-wraRSNFFDpeee {
  display: flex;
  align-items: center; 
  flex: 1; 
}

.product-SBFhi {
  width: 80px; 
  height: 80px; 
  object-fit: cover; 
  margin-right: 16px; 
}

.detail-wrapeee {
  display: flex;
  flex-direction: column; 
  flex: 1; 
}

.heading-600-16.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px; 
}

.heading-600-14.price {
  font-size: 14px;
  font-weight: 600;
  color: #333; 
} */
/* 
@media (max-width: 575px) {
  .top-heading {
    margin-top: 5%;
  }
  .ordersummaryheadingres {
    font-size: 1.3rem;
  }

  .headingindelivery {
    font-size: 1rem;
  }
  .indofrrrr {
    font-size: 0.9rem;
  }

  .iconsintetio {
    margin-top: 5%;
    margin-left: -20%;
  }

  .buttonhandlegotorders {
    padding: 8px 8px;
    font-size: 12px;
  }
  .buttondownloadinvoice {
    padding: 8px 8px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .ammount-summarydiv {
    padding: 12px;
  }

  .amount-heading {
    font-size: 18px;
  }

  .price-itemngnb,
  .total-wrap {
    font-size: 14px;
  }

  .price-itemngnb .title,
  .price-itemngnb .value,
  .total-wrap .title,
  .total-wrap .total-price {
    font-size: 14px;
  }
}

@media (max-width: 999px) {
  .order-itemed {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 522px) {
  .order-itemed {
    flex: 1 1 calc(100% - 16px);
  }
} */

/* 


.product-wrapwedd {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-wrapeeed {
  flex: 1;
  margin-right: 20px;
}

.right-wraeeeep {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.rwbjkn {
  gap: 5px;
}
*/

/* Base Styles */
/* .rwbjkn {
  margin-left: 70%;
  gap: 5px;
} */
.OrderSummaryTotalPageDived {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 80%;
  background-color: #f9f9f9;
  margin: 2% auto;
}

.totalcontentsummaryedd {
  justify-content: space-between;
  align-items: center;
}

.ordersummaryh1dived {
  flex: 1;
}

.ordersummarystepperdiv {
  display: flex;
  justify-content: flex-end;
}

.ordersummaryh1ed {
  font-size: 22px;
  font-weight: 600;
}

.right.bi-process-wrap {
  display: flex;
  gap: 10px;
}

.BelowOrderSummaryDived {
  background-color: #f9f9f9;
  margin: 16px 0;
}

.order-summary-wraped {
  display: flex;
  justify-content: space-between;
}

.order-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order-itemed {
  display: flex;
  align-items: center;
  padding: 8px;
}

.product-wrapEBedd {
  display: flex;
  align-items: center;
  width: 100%;
}

.left-wraRSNFFDpeee {
  display: flex;
  align-items: normal;
  width: 100%;
}

.product-SBFhi {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 16px;
}

.detail-wrapeee {
  display: flex;
  flex-direction: column;
}

.heading-600-16.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.heading-600-14.price {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.amount-summary {
  flex-basis: 300px;
  height: 280px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.somespavebetween {
  margin-right: 1%;
}

.summary-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.summary-label {
  font-size: 14px;
  color: #555;
}

.summary-value {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.buttonhandlegotorders,
.buttondownloadinvoice {
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 12px;
  color: white;
  line-height: 24px;
  background-color: #73509e;
  cursor: pointer;
}

/*---------------------------------------------------------------------New Design of orders page-----------------------------------------------------------------------*/
.NewOrderSummaryDesign {
  border: 1px solid #ddd;
  border-radius: 8px;

  background-color: #f9f9f9;
}

.borderbelowthenamegstmobile {
  border-bottom: 1px solid black;
  margin-top: 1%;
}

.products-amountsummary-delivery {
  float: left;
  padding: 20px;
  width: 70%;
}

.stepper-rightside {
  padding: 20px;
  float: right;
  width: 30%;
  border-right: 1px solid #ddd;
}

.namemobileleft-gstrightP {
  font-size: 14px !important;
}

.gstnoinrightside {
  color: gray;
  font-weight: 600;
}

.addresspart {
  border-bottom: 1px solid #ddd;
}
.BillingAddressPartandShippingAddressPart {
  display: flex;
  justify-content: space-between;
  gap: 3px;
}

.sdvvsfv,
.vfvjfjjf {
  font-size: 13px;
  padding: 10px;
  width: 50%;
}
.selectedAddshippingAddress {
  width: 100%;
}

.headingindelivery {
  font-weight: bold;
  margin-bottom: 10px;
}

.order-items-in-summary {
  border-bottom: 1px solid #eaeaea;
}

.Order-Amount-Summary {
  padding: 10px;
}

.product-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}

.product-left {
  display: flex;
  align-items: center;
  width: 70%;
}

.product-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 16px;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-name {
  font-weight: 600;
  font-size: 16px;
}

.product-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
  gap: 20px;
}

.product-quantity {
  font-weight: 600;
  font-size: 14px;
}

.product-rate {
  font-weight: 600;
  font-size: 14px;
}

/******************************************************************Responsive********************************************************/
@media screen and (min-width: 320px) and (max-width: 1000px) {
  .Whole-dIV-FOR-OrderSummaryDesgin-for-823px {
    display: none;
    margin-bottom: 10px;
  }
  .custom-marginccc {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 576px) {
  .OrderSummaryTotalPageDived {
    padding: 12px;
  }

  .lrbnbnf {
    margin-top: 15%;
  }

  .totalcontentsummaryedd {
    flex-direction: column;
    align-items: flex-start;
  }

  .ordersummaryh1ed {
    font-size: 18px;
  }

  .order-container {
  }

  .order-itemed {
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
  }

  .heading-600-16.title {
    font-size: 15px;
  }

  .heading-600-14.price {
    font-size: 13px;
  }

  .amount-summary {
    flex-basis: 100%;
    height: auto;
    margin-top: 12px;
  }

  .summary-heading {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .summary-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .summary-label,
  .summary-value {
    font-size: 12px;
  }

  .buttonhandlegotorders,
  .buttondownloadinvoice {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 8px;
    margin-top: 8px;
  }

  .somespavebetween {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .left-wraRSNFFDpeee {
    display: flex;
    align-items: normal;
  }

  .prodyctcardsummary {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
  }
  .prodyctcardsummary-leftdiv {
    float: left;
    width: 30%;
  }
  .prodyctcardsummaryrightdiv {
    float: right;
    width: 7cap;
  }
  .reactstppercss {
    display: none;
  }
}

@media (min-width: 1001px) {
  .Whole-dIV-FOR-OrderSummaryDesgin-for-824px {
    display: none;
  }
}

@media (max-width: 1164px) {
  .OrderSummaryTotalPageDived {
    padding: 12px;
  }

  .totalcontentsummaryedd {
    flex-direction: column;
    align-items: flex-start;
  }

  .ordersummaryh1ed {
    font-size: 18px;
  }

  .BelowOrderSummaryDived {
    padding: 12px;
  }

  .order-summary-wraped {
    flex-direction: column;
  }

  .order-container {
    gap: 12px;
  }

  .order-itemed {
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
  }

  .product-SBFhi {
    width: 70px;
    height: 70px;
    margin-right: 12px;
  }

  .heading-600-16.title {
    font-size: 15px;
  }

  .heading-600-14.price {
    font-size: 13px;
  }

  .amount-summary {
    flex-basis: 100%;
    height: auto;
    margin-top: 12px;
  }

  .summary-heading {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .summary-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .summary-label,
  .summary-value {
    font-size: 12px;
  }

  .buttonhandlegotorders,
  .buttondownloadinvoice {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 8px;
    margin-top: 8px;
  }

  .somespavebetween {
    margin-right: 0;
    margin-bottom: 12px;
  }
}

/* Responsive Styles */
/* @media (max-width: 992px) {
  .OrderSummaryTotalPageDived {
    max-width: 95%;
  }
  
  .totalcontentsummaryedd {
    flex-direction: column;
    align-items: flex-start;
  }

  .ordersummaryh1ed {
    font-size: 20px;
  }
  
  .right.bi-process-wrap {
    justify-content: flex-start;
    gap: 8px;
  }
} */
