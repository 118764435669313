/*************************************************************Grid button Logic**********************************************************/
.gridlines {
  border: 1px solid #73509e;
  color: #7a7c7f;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
}

.grid-toggle-btn {
  background-color: #73509e;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.grid-toggle-btn:hover {
  background-color: #9ba24c;
}

.gridtext{
  color: rgb(90, 90, 90);
  font-weight: 600;
  text-transform: uppercase;
}

.gridtextfordesktop{
  color: rgb(90, 90, 90);
  font-weight: 600;
  text-transform: uppercase;
}

.icon-box{
  width: 10px;
  height: 18px;
  background-color: rgb(224, 224, 224);
}

.icon-box.icon-active {
  background-color: grey;
}

.gridButtonformobile {
  display: none;
}

.filterAndLocationWrapper{
  margin-bottom: -1%;
}

/*-------------Grid button logic - 1 icon and 2 icons for mobile------------------*/
.gridcontainerbuttonformobile {
  border: 1px solid rgb(156, 156, 156);
  padding: 3px;
  display: flex;
  cursor: pointer;
  gap: 2px;
  width: fit-content;
}

.gridcontainerbuttonformobile.active {
  border-color: black;
}


.gridcontainerbuttonformobile {
  display: none;
}


/*-------------Grid button logic - 1 icon and 2 icons for tab ------------------*/
.gridcontainerbuttonforsmallestdesktop{
  border: 1px solid rgb(183, 183, 183);
  padding: 3px;
  cursor: pointer;
  display: flex;
  gap: 2px;
  width: fit-content;
}
.gridcontainerbuttonforsmallestdesktop.active {
  border-color: black;
}

.gridcontainerbuttonforsmallestdesktop{
  display: none;
}


/*-------------Grid button logic - 2 icon and 3 icons for small desktop screens ------------------*/
.gridcontainerbuttonforsmalldesktop{
  border: 1px solid rgb(183, 183, 183);
  padding: 3px;
  cursor: pointer;
  display: flex;
  gap: 2px;
  width: fit-content;
}

.gridcontainerbuttonforsmalldesktop.active {
  border-color: black;
}

.gridcontainerbuttonforsmalldesktop{
  display: none;
}


/*-------------Grid button logic - 3 icon and 4 icons for normal desktop screens ------------------*/
.gridcontainerbuttonfordesktop {
  border: 1px solid rgb(183, 183, 183);
  padding: 3px;
  cursor: pointer;
  display: flex;
  gap: 2px;
  width: fit-content;
}

.gridcontainerbuttonfordesktop.active {
  border-color: black;
}

.gridcontainerbuttonfordesktop {
  display: flex;
}



/*-------------Grid button logic - 4 icon and 5 icons for large desktop screens ------------------*/
.gridcontainerbuttonforbigdesktop{
  border: 1px solid rgb(183, 183, 183);
  padding: 3px;
  cursor: pointer;
  display: flex;
  gap: 2px;
  width: fit-content;
}

.gridcontainerbuttonforbigdesktop.active{
  border-color: black;
}

.gridcontainerbuttonforbigdesktop {
  display: none;
}


/*************************************************************Card Logic Css**************************************************************/
.whole-product-sectionformachines {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  justify-content: center;
}


.whole-product-section {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  justify-content: center;
}


.whole-product-section-for-combined-search {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  justify-content: center;
}

.individual-product {
  overflow: hidden;
  position: relative;
  font-size: 16px !important;
  padding: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-right: 1px solid #73509e1a;
}

.individual-product:hover {
  transform: scale(1.03);
}

.exclusive-btn {
  position: absolute;
  white-space: nowrap;
  top: 8px;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px !important;
  font-weight: bold;
  background-color: #cc0c39;
}

.individual-product img {
  max-width: 100%;
  height: 200px;
  object-fit: contain;
  /* object-position: bottom; */
  margin: 0 auto;
  display: block;
}


.individual-product-imagehere {
  max-width: 100% !important;
  height: 230px!important;
  object-fit: cover!important;
  /* object-position: bottom; */
  margin: 0 auto;
  display: block;
}

.brandsandproductname-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brandsandproductname {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brandsandproductname-wrapper:after {
  background: #211e24;
  content: "";
  display: flex;
  height: 2px;
  margin-top: 4px;
  width: 32%;
}

.bucketsizepriceandltr {
  text-align: right;
}

.offerpercent {
  color: #58cb58 !important;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 500;
}

.individual-product span,
.individual-product div {
  font-size: 16px;
  color: #333;
}

.individual-product button {
  border: none;
  margin-top: 2%;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  background-color: #73509e;
  transition: background-color 0.3s ease;
}

.sfbbfdbdbbbbbbbb{
  margin-top: 4.5% !important;
}

.addtocart-btn-oil,
.buynow-btn-oil {
  padding: 10px 16px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}


.addtocart-btn-oil {
  background-color: #73509e;
}

.buynow-btn-oil {
  background-color: #9ba24c !important;
}

.totaldiv-addtocard-buynow {
  display: flex;
  justify-content: center;
  gap: 5px;
  overflow: hidden;
  /* padding: 8px 12px; */
  margin-top: 8px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}

/****************************************************Grid buttons Grid Logic css*********************************************************/

.grid-view-for-combined-search {
  grid-template-columns: repeat(4, 1fr);
}


.nogridscssaplied{
  grid-template-columns: repeat(4, 1fr);
}




.grid-view {
  grid-template-columns: repeat(4, 1fr);
}

.grid-view .totaldiv-addtocard-buynow {
  justify-content: flex-start;
}

.grid-view-for-combined-search .totaldiv-addtocard-buynow {
  justify-content: flex-start;
}




/****************************************************Responsive Media Queries************************************************************/

@media (max-width: 400px) {
  .addtocart-btn-oil,
  .buynow-btn-oil {
    padding: 6px 10px;
    font-size: 10px;
  }
}


@media (max-width: 576px) {
  .whole-product-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    padding: 0;
  }

  .whole-product-section-for-combined-search {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    padding: 0;
  }


  .whole-product-sectionformachines {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    padding: 0;
  }
  .individual-product-imagehere {
    max-width: 100% !important;
    height: 230px!important;
    object-fit: cover!important;
    /* object-position: bottom; */
    margin: 0 auto;
    display: block;
  }
  
  /* .individual-product {
    border-right: none;
  } */


  .grid-view {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-view-for-combined-search{
    grid-template-columns: repeat(2, 1fr);
  }


  
  .grid-view .addtocart-btn-oil,
  .grid-view .buynow-btn-oil {
    width: 100% !important;
  }

  .grid-view .totaldiv-addtocard-buynow {
    width: 100% !important;
    justify-content: flex-start;
  }

    
  .grid-view-for-combined-search .addtocart-btn-oil,
  .grid-view-for-combined-search .buynow-btn-oil {
    width: 100% !important;
  }

  .grid-view-for-combined-search .totaldiv-addtocard-buynow {
    width: 100% !important;
    justify-content: flex-start;
  }


  .addtocart-btn-oil,
  .buynow-btn-oil {
    padding: 8px 12px;
    font-size: 12px;
  }
  .totaldiv-addtocard-buynow {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 70%;
    overflow: hidden;
    margin-top: 8px;
    text-align: center;
    color: #fff;
    white-space: nowrap;
  }
  .containerefvvvvvvvv {
    display: none;
  }
  .filterAndLocationWrapper{
    margin-bottom: 2%;
  }
}


@media screen and (min-width:577px) and (max-width:790px){


.nogridscssaplied {
  grid-template-columns: repeat(2, 1fr);
}


}
@media (max-width: 768px) {
  .gridcontainerbuttonfordesktop {
    display: none;
  }
  .gridcontainerbuttonformobile {
    display: flex;
  }
  .gridButtonformobile {
    display: block;
  }
  .gridcontainerbuttonforbigdesktop {
    display: none;
  }
  .gridtextfordesktop{
    display: none;
  }
  .filterAndLocationWrapper{
    margin-bottom: 1%;
  }
}



@media (max-width: 999px) {
  .grid-view {
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 0;
  }
  .grid-view-for-combined-search{
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 0;
  }
}


@media (min-width: 1563px) {
  .grid-view {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid-view-for-combined-search{
    grid-template-columns: repeat(5, 1fr);
  }
  .gridcontainerbuttonforbigdesktop {
    display: flex;
  }
  .gridcontainerbuttonfordesktop{
    display: none;
  }
}

.gridcontainerbuttonforsmalldesktop{
  display: none;
}

@media screen and (min-width:577px) and (max-width: 767px){
  .grid-view{
    grid-template-columns: repeat(1,1fr);
  }

  .grid-view-for-combined-search{
    grid-template-columns: repeat(1,1fr);
  }
  .totaldiv-addtocard-buynow {
    /* width: 70%; */
}
}


@media screen and (min-width:769px) and (max-width:998px){
  .gridcontainerbuttonforsmallestdesktop{
    display: flex;
  }
  
  .gridcontainerbuttonforsmalldesktop{
    display: none;
  }
  .gridcontainerbuttonfordesktop{
    display: none;
  }
  .gridcontainerbuttonforbigdesktop{
    display: none;
  }
  .grid-view{
    grid-template-columns: repeat(1,1fr);
  }
  .grid-view-for-combined-search{
    grid-template-columns: repeat(2,1fr);
  }
  .nogridscssaplied{
    grid-template-columns: repeat(3,1fr)  !important;
  }
  /* .totaldiv-addtocard-buynow {
    width: 70%;
} */
}

@media screen and (min-width:999px) and (max-width:1252px){
.gridcontainerbuttonforsmalldesktop{
  display: flex;
}
.gridcontainerbuttonfordesktop{
  display: none;
}
.gridcontainerbuttonforbigdesktop{
  display: none;
}
.grid-view{
  grid-template-columns: repeat(3,1fr);
}
.grid-view-for-combined-search{
  grid-template-columns: repeat(3,1fr);
}
}


@media (min-width: 1826px) {
  .whole-product-section {
    grid-template-columns: repeat(6, 1fr);
  }

  .whole-product-section-for-combined-search {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid-view{
    grid-template-columns: repeat(5,1fr);
  }
  .grid-view-for-combined-search{
    grid-template-columns: repeat(5,1fr);
  }
}




/***************************************************************Old Codes****************************************************************/
/* .containerefvvv {
 border: 1px solid black;
  display: inline-block;
gap: 5px;
}

.icon-box {
  width: 15px; 
  height: 25px; 
  position: absolute;
  top: 96px;
  background-color: grey; 
  display: inline-block;
  border-radius: 2px;
}
.icon-box2{
  width: 15px; 
  height: 25px; 
  position: absolute;
  top: 96px;
  background-color: grey; 
  display: inline-block;
  border-radius: 2px; 
  right: 145px;
} */

/* 
@media (max-width: 359px) {
  .whole-product-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    padding: 0;
  }
  .individual-product button {
    padding: 6px 4px !important;
    font-size: 12px;
  }
} 

@media screen and (min-width: 360px) and (max-width: 380px) {
  .whole-product-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    padding: 0;
  }
  .individual-product button {
    padding: 6px 5px !important;
    font-size: 12px;
  }
}

@media screen and (min-width: 381px) and (max-width: 409px) {
  .whole-product-section {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    gap: 0px;
  }
  .individual-product button {
    padding: 10px 5px !important;
    font-size: 12px;
  }
  .totaldiv-addtocard-buynow {
    gap: 0px !important;
  }
}

@media screen and (min-width: 410px) and (max-width: 475px) {
  .whole-product-section {
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 0;
  }

  .individual-product button {
    padding: 10px 3px !important;
  }
}

@media screen and (min-width:577px) and (max-width:962px){
  .grid-view{
    grid-template-columns: repeat(3, 1fr);
  }
} 

@media screen and (min-width:1252px) and (max-width:1532px){
  .grid-view{
    grid-template-columns: repeat(4,1fr);
  }
}

@media screen and (min-width:1533px) and (max-width:2600px){
  .grid-view{
    grid-template-columns: repeat(5,1fr);
  }
}

@media screen and (min-width:2601px) and (max-width:3000px){
  .grid-view{
    grid-template-columns: repeat(6,1fr);
  }
} */
