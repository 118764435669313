.header-wrap-sale-grid {display: flex;justify-content: space-between;align-items: center;}
.header-wrap-sale-grid .tab-wrap-my-machine{display:flex;margin-top:20px;}
.header-wrap-sale-grid .tab-wrap-my-machine .btn:focus{box-shadow:none;outline:none;}
.header-wrap-sale-grid .tab-wrap-my-machine .btn{font-size:16px;color:#7a7c7f;border-radius:0;padding:0 10px;height:48px;}
.header-wrap-sale-grid .tab-wrap-my-machine .active{color:#211E24;border-bottom:3px solid #9DA14E;}
.header-wrap-sale-grid .btn-wrap {display: flex;column-gap: 15px;}
.header-wrap-sale-grid .btn-wrap .short-wrap .short-btn {background: none;border: 2px solid #73509E;color: #73509E;position: relative;}
.header-wrap-sale-grid .btn-wrap .short-wrap .short-btn svg path{fill:#73509E;}
.header-wrap-sale-grid .btn-wrap .btn {height: 54px;background: #73509E;color: #fff;border-radius: 12px;padding: 0 30px;display: flex;align-items: center;column-gap: 10px;}

.machine-sale-grid-wrap{display:flex;flex-wrap:wrap;row-gap:50px;margin:40px 0 0;}
.machine-sale-grid-wrap .item{width:calc(33.33%);padding:25px;transition:.2s;border-right:1px solid rgba(115, 80, 158, 0.10);}
.machine-sale-grid-wrap .item .sale-details{position: relative;}
.machine-sale-grid-wrap .item .sale-details::before{content:"";position: absolute;width:25%;height:2%;background:#c5c5c5;bottom:0;}
.machine-sale-grid-wrap .item .sch-view{cursor: pointer;}
.machine-sale-grid-wrap .item .image-wrap{height:230px;width:100%;display:flex;align-items:center;justify-content:center;overflow:hidden;padding:20px 0;}
.machine-sale-grid-wrap .item .image-wrap .product-img{display:flex;}
.machine-sale-grid-wrap .item .label-item{font-style:14px;border-radius:16px;padding:4px 14px;color:#211E24;align-items:flex-start;background:#F5C518;border:none;width:max-content;margin:0 0 15px;}
.machine-sale-grid-wrap .item .listed{background: #3C7D0E;color:#fff;}
.machine-sale-grid-wrap .item .machine-info{margin:15px 0 0;}
.machine-sale-grid-wrap .item .btns-wrap-view{display:flex;justify-content:flex-start;column-gap:12px;margin:30px 0 0;}
.machine-sale-grid-wrap .item .btns-wrap-view .item-btn{height:54px;padding:0 30px;background:#73509E;border-radius:12px;border:none;color:#fff;}
.machine-sale-grid-wrap .item .btns-wrap-view .simple-btn{padding:0 15px;color:#000;background:none;}
.machine-sale-grid-wrap .item:hover{background:#9B9E51;color:#fff;}
.machine-sale-grid-wrap .item:hover .sch-view svg path{fill:#fff;}
.machine-sale-grid-wrap .item:hover .btns-wrap-view .simple-btn,
.machine-sale-grid-wrap .item:hover .light-txt{color:#fff}

.pagination {display: flex;justify-content: center;list-style: none;padding:0;column-gap:20px;padding-top:90px;}
.pagination li{display:flex;cursor: pointer;border-radius:30px;width:40px;height:40px;align-items: center;justify-content: center;}
.pagination .next,
.pagination .previous{border: 1px solid var(--Black, #211E24);box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);width:40px;height:40px;}
.pagination .previous{margin-right:30px;}
.pagination .next{margin-left:30px;}
.pagination a {text-decoration: none;border-radius:30px;font-size:14px;color: #656565;font-weight:400;}
.pagination .active {background-color: #73509E;color:#fff;border:none;font-size:16px;font-weight:600;}

@media(max-width:991px){
    .header-wrap-sale-grid {flex-direction: column;align-items:normal;}
    .header-wrap-sale-grid .btn-wrap {flex-direction:row;align-items: center;margin-top: 15px;justify-content: flex-end;}
    .header-wrap-sale-grid .btn-wrap .btn {height:50px;padding: 0 25px;}
    .header-wrap-sale-grid .machine {padding: 0;margin-top:0px;}
    .machine-sale-grid-wrap .item{width:calc(50%);padding:25px;}
}
@media(max-width:767px){
    .header-wrap-sale-grid .btn-wrap .btn {height:45px;padding: 0 20px;font-size:14px;}
    .header-wrap-sale-grid .btn-wrap .short-wrap .short-btn svg{width:20px;height:20px;}
    .machine-sale-grid-wrap .item{width:100%;padding:0;padding:25px;}
    .machine-sale-grid-wrap .item .machine-info{justify-content: space-between;}
    .machine-sale-grid-wrap .item .sch-view{margin:0 !important;position:absolute;top: 690px;left:228px;font-size:14px;}
    .machine-sale-grid-wrap .item .btns-wrap-view .item-btn{position: relative;height:45px;padding:0 30px;font-size:14px;}
    .pagination {column-gap:5px !important;}
    .pagination li{width:35px !important;height:35px !important;}
    .pagination .next,
    .pagination .previous{width:35px !important;height:35px !important;}
}
@media(max-width:479px){
    .header-wrap-sale-grid .tab-wrap-my-machine .btn{font-size:14px;}
    .machine-sale-grid-wrap .item .label-item{font-size:12px;border-radius:16px;padding:4px 14px;color:#211E24;align-items:flex-start;background:#F5C518;border:none;width:max-content;margin:0 0 15px;}
    .machine-sale-grid-wrap .item .btns-wrap-view .item-btn{height:42px;padding:0 28px}
    .pagination .previous{margin-right:10px !important;}
    .pagination .previous svg{width:20;height: 20px;}
    .pagination .next svg{width:20;height: 20px;}
    .pagination .next{margin-left:10px !important;}
}