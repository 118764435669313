.newMenu .search-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-150%);
}
.search-icon {
  color: #211e24;
}
.newMenu {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  position: relative;
  margin-left: 1px;
}
.newMenu .menus {
  display: flex;
}
.newMenu .menus .menus-ui {
  display: flex;
  padding: 0;
  gap: 40px;
  list-style: none;
  margin: 0;
}
.newMenu .menus .menus-ui .menus-li {
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding-bottom: 26px;
}
.newMenu .menus .menus-ui .menus-li-active {
  font-weight: 600;
  border-bottom: 4px solid #73509e;
}
.close-svg-rotated {
  transform: rotate(180deg);
}
.newMenu .newsearch {
  flex-grow: 1;
  padding-bottom: 26px;
  -webkit-animation: openSearch 0.9s linear both;
  animation: openSearch 0.5s linear both;
}
.newMenu .closeSearch .expended-search {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  -webkit-animation: openSearch 0.9s linear both;
  animation: openSearch 0.5s linear both;
}
.newMenu .closeSearch .expended-search .inputcutom {
  width: 100%;
}

@media (max-width: 950px) {
  .newMenu .closeSearch .expended-search .inputcutom {
    height: 48px !important;
  }
}

.f-logo {
  display: none;
  width: 64px;
  align-items: center;
}

.phone-avatar {
  position: absolute;
  display: none;
  right: 30px;
  gap: 10px;
}

.phone-avatar .col {
  padding-left: 0;
}
@-webkit-keyframes openSearch {
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes openSearch {
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.close-search {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  gap: 12px;
  z-index: 3;
  -webkit-animation: closeSearch1 0.9s linear both;
  animation: closeSearch1 0.9s linear both;
}
.closeSearch {
  /* flex-grow: 0;  */

  display: flex;
  justify-content: end;
  padding-bottom: 26px;
}
@keyframes closeSearch {
  from {
    width: 100%;
  }
  to {
    width: 10%;
  }
}

@-webkit-keyframes closeSearch1 {
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes closeSearch1 {
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.cust-toggel-f {
  display: none;
  padding-top: 18px;
  padding-bottom: 18px;
}

.sfjkbf {
  position: absolute;
  top: 48px;
  right: 35px;
  color: #ffffff;
  background-color: #9b9e51;
  font-size: 12px; /* Reduced font size for better fit */
  font-weight: bold;
  /* Added background color for visibility */
  border-radius: 50%; /* Make it circular */
  padding: 1px 5px; /* Adjusted padding */
  border: 1px solid #735197; /* Added border for definition */
}

.cart-badgdsnjsbfe {
  display: none;
}

@media screen and (min-width: 850px) and (max-width: 1231px) {
  .sfjkbf {
    padding: 1px 4px;
    top: 45px;
    right: 25px;
  }
  .cart-badgdsnjsbfe {
    display: block;
    position: absolute;
    top: -55px;
    right: 175px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease;
    background-color: #9b9e51;
    border-radius: 50%;
    padding: 1px 5px;
  }
}

@media screen and (min-width: 1232px) and (max-width: 2500px) {
  .sfjkbf {
    display: none;
  }
  .cart-badgdsnjsbfe {
    display: block;
    position: absolute;
    top: -55px;
    right: 175px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease;
    background-color: #9b9e51;
    border-radius: 50%;
    padding: 1px 5px;
  }
}
@media screen and (max-width: 1125px) {
  .newMenu .menus .menus-ui {
    gap: 20px;
  }
}

@media screen and (max-width: 997px) {
  .newMenu {
    margin-top: 0;
    justify-content: flex-start;
  }
  .newMenu .menus .menus-ui {
    display: none;
  }
  .cust-toggel-f {
    display: flex;
  }
  .closeSearch {
    align-items: center;
    padding-bottom: 0;
  }
  .f-logo {
    display: flex;
    justify-content: flex-start;
    margin-left: 5%;
  }
  .w-100 {
    width: 100% !important;
  }
  .phone-avatar {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .search-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .login-text {
    display: flex;
    align-items: center;
    margin-left: 5px;
    left: 10px;
  }
}

@media screen and (max-width: 992px) {
  .phone-avatar {
    display: flex;
    align-items: center;

    gap: 30px;
  }
  .f-logo {
    display: flex;
    justify-content: flex-start;
  }
  .search-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .login-text {
    display: flex;
    align-items: center;
    margin-left: 5px;
    left: 10px;
  }
  .newMenu .closeSearch {
    width: 100%;
  }
  .newMenu .closeSearch .expended-search {
    width: 100%;
  }
}

@media screen and (min-width: 993px) {
  .phone-avatar {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .search-icon {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
}

/* Add these new classes at the end of the file */
.mobile-only {
  display: none;
}

@media screen and (max-width: 992px) {
  .mobile-only {
    display: flex;
  }
}

/* Update the existing .phone-avatar class */
.phone-avatar {
  position: relative;
  right: 5px;
  gap: 5px;
}

/* Update the existing .search-icon class */
.newMenu .search-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  right: 0;
  top: 0;
  transform: none;
  margin-top: 0;
}

@media screen and (max-width: 992px) {
  .newMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .newMenu .search-icon {
    position: relative;
    transform: none;
    margin-left: 0.6%; /* Align to the right */
    margin-top: 0;
  }

  /* .phone-iconnn{
    margin-left: -35%;
  } */

  .phone-avatar,
  .login-text {
    position: relative;
    left: 0; /* Reset left position */
  }
}
