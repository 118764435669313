.app-containeregbdg {
  display: flex;
  overflow: hidden;
}


.cart-scrollable-container {
  max-height: 400px; /* Set a max height for the scrollable area */
  overflow-y: auto;  /* Enable vertical scrolling */
  padding-right: 10px; /* Optional: Add some padding */
}
.sticky .columeen-oneeeee {
  position: fixed;
  top: 70;
  transition: top 0.3s ease-in-out;
  /* z-index: 10; */
}

.no-scroll {
  overflow: hidden; /* Disable scrolling */
  height: 100vh; /* Prevent body height changes */
}
.sticky .columeen-twvsfo{
  position: absolute;
  left: 28%;
} 
.sticky .columeen-threeeee {
  position: fixed;
  transition: top 0.3s ease-in-out;
  top: 70; 
  left: 70.5%;
  /* z-index: 10; */
}

.columeen-oneeeee {
  width: 30%;
  transition: top 0.3s ease-in-out;
  padding: 20px;
}


.jsfhbbsffs {
  height: 580px;
  overflow-y: auto; /* Adds a vertical scrollbar when content exceeds the height */
}

.jsfhbbsffs::-webkit-scrollbar{
  background: #F5F5F5;
  border-radius: 8px;
  width: 6px;
  height: 10px;
}

.jsfhbbsffs::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(175, 175, 175, 0.3);
}
.columeen-threeeee {
  transition: top 0.3s ease-in-out;
  width: 30%;
  padding: 20px;
  height: 100px;
}

.columeen-twvsfo {
  width: 45%;
  padding: 20px;
  overflow-y: auto; /* Allow the middle column to scroll */
}

.service-type-containeeer {
  position: relative;
  z-index: 1;
}






/*-------------------------------------------------------------------First Column ------------------------------------------------------*/

.sidebarsdvfsa{
  transition: top 0.3s ease-in-out;
}

.ColumnONEkARTHIK {
}
.insidecolummnnone {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  /* width: 380px; */
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insidecolumnonepart {
  margin-top: 2%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  width: 380px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-selection h2 {
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 12px 0;

  margin: 0 0 16px 0;
  text-align: left;
  color: #333;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 8px;
  padding: 0;
}

.service-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  height: auto;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.service-icon {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
}


.service-iconrzdhzd {
  width: 40px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
}

.service-iconbig {
  width: 150px;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
}

.service-label {
  font-size: 12px;
  text-align: center;
  color: #333;
  max-width: 90px;
  line-height: 1.2;
}

.service-button:hover {
  background-color: transparent;
  font-weight: normal;
}

.service-button img.clicked {
  border: 2px solid #73509E;
  opacity: 0.9; 

}

.service-heading-bttt {
  display: flex;
  justify-content: space-between;
}

.buttonofloaction {
  padding: 5px;
  border-radius: 8px;
  border: none;
}

/*-------------------------------------------------------------------Second Column------------------------------------------------------*/

.insideColumnTwo {
  padding: 8px;
  margin: 0 auto;
}

.plan-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typeofsecondcolumn {
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.plan-name-secondcolumn {
  font-size: 16px;
  line-height: 24px;
  color: rgb(15, 15, 15);
  text-decoration-line: none;
  text-transform: none;
  font-weight: 600;
  text-align: left;
}

.Startsat {
  font-weight: 400;
}

.serviceeading{
  font-weight: 700;
  font-size: 1.1rem;
}

.linebelowaddbutton {
  color: #aaa9a9;
  width: 98%;
}

.plan-image {
  width: 120px;
  height: auto;
  object-fit: contain;
}

.addbufbbftton {
  background-color: #73509E;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.cartapgesbnzg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  /* border-bottom: 1px solid #e0e0e0; */
}

.buttonsforquantiy {
  display: flex;
  align-items: center;
  border: 1px solid #73509E;
  border-radius:20px;
  padding: 5px;
  overflow: hidden;
  margin: 0 5px;
}

.quantity-btnsfv {
  background: none;
  border: none;
  color: #73509E;
  font-size: 1.2rem;
  padding: 0 5px;
  cursor: pointer;
}
.boldheadingfor{
  font-weight: bold;
}
.buttonsforquantiy span {
  padding: 0 5px;
  min-width: 15px;
  text-align: center;
}


.description-price-container {
  display: flex; 
  justify-content: space-between; 
  align-items: flex-start;
  width: 100%; 
}

.clean-description {
  flex: 1; 
  text-align: left; 
  padding-right: 20px; 
}

.price-text {
  text-align: right;
  white-space: nowrap; 
}

.price-text div {
  display: block; 
}


.price-text div {
  display: block;
}

/*-------------------------------------------------------------------Third Column--------------------------------------------------------*/


.service-type-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.plans-wrapper {
  display: flex;
  flex-direction: column;
}

.plan-item {
  border-bottom: 1px solid #f0f0f0;
}

.plan-item:last-child {
  border-bottom: none;
}

.service-square-button {
  background-color: #73509E;
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  margin: 0 8px;
}

.add-button {
  background-color: #73509E;
  color: white;
  padding: 8px 19px;
  border-radius: 12px;
  cursor: pointer;
}

.service-type-buttons{
  display: flex;
  align-items: center;
}

.sjifhbdkbzdfdvdvs{
  display: flex;
justify-content: space-between;
}

.serviceheading{
  font-weight: 600;
  font-size: 1.1rem;
}

.cart-empty-full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.cartttfsd {
  font-size: 1rem;
  color: gray;
  font-weight: 400;
}

.jnjkfsnbfsnfb {
  display: flex;
  gap: 5px;
}
.callbackfbdj {
  color: white;
  background-color: #73509E;
  border: none;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  margin: 0 8px;
}


.callbackfbdj2 {
  color: #73509E;
  background-color: white;
  border: 2px solid #73509E;
  border-radius: 12px;
  padding: 5px;
  cursor: pointer;
  margin: 0 8px;
}



.callbackfbdj:hover {
  border: none;
}

.callbackfbdj2:hover {
  color: white;
  background-color: #73509E;
  border: none;

}

.hover-effect {
  background-color: white;
  color: #73509E;
  border: 2px solid #73509E;
}


.buttonsforquantiy {
  display: flex;
  align-items: center;
  gap: 7px;
}

.imagesrbentzdg{
  width:150px;
  height: 150px;
 
}
@media screen and (min-width:769px) and (max-width:1070px){

  .app-containeregbdg {
    flex-direction: column;
  }

  .columeen {
    width: 100%;
    margin-bottom: 20px;
  }

  .columeen-oneeeee {
    order: 1;
  }

  .columeen-twvsfo {
    order: 2;
  }



  .columeen-threeeee {
display: none;
}
  .service-request-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    background-color: white;
    padding: 225px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    order: 3;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }  


  .callbackfbdj {
    width: 48%;
   
    font-size: 14px;
  }

  .callbackfbdj2 {
    width: 48%;

    font-size: 14px;
  }

}


  @media screen and (max-width: 768px) {
  .app-containeregbdg {
    flex-direction: column;
  }

  .columeen {
    width: 100%;

  }

  .columeen-oneeeee {
    order: 1;
  }

  .columeen-twvsfo {
    order: 2;
  }

  .columeen-threeeee {
    order: 3;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  
  .service-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    /* overflow-x: hidden; */
    gap: 3px;
    /* padding-bottom: 10px; */
  }

  /* .insidecolummnnone {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
  } */

  .service-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    /* overflow-x: hidden; */
    gap: 10px;
    /* padding-bottom: 10px; */
  }

  
  .service-button {
    min-width: auto;
    margin-right: 0;
  }

  .imagesrbentzdg {
    height: 60px;
    width: 60px;
    object-fit: cover;
  }

  .insidecolumnonepart{
    display: none;
  }

  .service-type-containeeer {
    margin: 0 auto;
    /* overflow-x: auto; */
    white-space: nowrap;
    margin-bottom: 15px;

  }

  .serviceeading{
display: none;
  }

  .service-type-button {
    padding: 8px 15px;
    font-size: 14px;
  }
 
  

  .ColumntWOkARTHIK {
    margin-bottom: 15px;
  }

  .plan-item {
    flex-direction: row;
    align-items:  flex-start;
  }

  .plan-name-secondcolumn {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .Startsat {
    font-size: 14px;
  }

  /* .iamgeandbutttonn {
    display: none;
  } */

  .buttonsforquantiy {
    display: flex;
    align-items: center;
    border: 1px solid #73509E;
    border-radius: 20px;
    overflow: hidden;
    justify-content: space-between; /* Ensures equal spacing */
  }

  .quantity-btnsfv {
    background: none;
    border: none;
    color: #73509E;
    font-size: 18px;
    padding: 5px 10px;
  }

  .ColumntHREEkARTHIK {
    padding: 10px;
  }

  .cartapgesbnzg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .jnjkfsnbfsnfb {
    display: flex;
    justify-content: space-between;
  }

  .callbackfbdj {
    width: 48%;
padding: 8px;
    font-size: 14px;
  }

  .callbackfbdj2 {
    width: 48%;
 
    font-size: 14px;
  }

  .service-request-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    z-index: 1000;
  }


  .bnzdfjbfd{
    padding-bottom: 85px; /* Adjust based on footer height */
  }
  .service-request-button {
    width: 48%;
    padding: 10px;
    font-size: 14px;
  }
  .columeen-threeeee{

    display: none;
  }
}

@media screen and (max-width: 520px) {
 
  .app-containeregbdg {
    flex-direction: column;
  }

  .columeen {
    width: 100%;
   
  }

  .columeen-oneeeee {
    order: 1;
  }
  .service-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .insidecolummnnone {
    padding: 10px;
  }

  .service-icon {
    width: 90px;
    height: 90px;
  }

  .service-iconbig{
    width: 120px;
    height: 160px;
  }
  .service-label {
    font-size: 9px;
  }

  .plan-name-secondcolumn {
    font-size: 14px;
  }

  .Startsat {
    font-size: 12px;
  }
}





@media screen and (min-width:309px) and (max-width:426px){
  /* .service-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    overflow-x: hidden;

    padding-bottom: 10px;
  } */


}