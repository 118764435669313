.listofservicewithoutdes{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

/* .listofservicewithoutdes img{
    width: 5.625rem;
    height: 5.625rem;
} */

.listofservicewithoutdes-title{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: center;
}


