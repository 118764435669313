/* .buttonsqautntiyf{
text-align: left;
}

.quotationDetailsheading{
    font-size: 1.7rem;
    font-weight: 700;
}

.button-submit-popup{
    padding: 11px 16px;
    font-size: 14px;
    color: white;
    background-color: darkcyan;
    border:none;
    font-weight: 500;
    border-radius:10px;


}


.Contentisnidepopup{
    font-size: 1rem;
} */

.totalpopupdiv {
    text-align: left;
}
/* 
.quotationDetailsheading {
    font-size: 1.7rem;
    font-weight: 700;
} */

.renderPricListsf{
    border: 1px rgb(211, 208, 208) solid;
    border-radius: 8px;
    padding: 11px;
}

.quotationDetailsheading {
    font-size: 100%;
font-weight: 800;
padding: 10px;
text-align: left;
color: white;

background-color: dimgrey;
margin-left: -20px; /* Adjust these values to match the padding of the container */
margin-right: -20px; /* Adjust accordingly */
width: calc(100% + 40px); /* Extend to account for the negative margins */
    /* font-size: 1.7rem;
    font-weight: 800;
    
    padding: 4px;
    text-align: left;
    color: #211e24;
    border-radius: 8px;
    box-shadow: 0.5px 0.5px 2px 2px rgba(117, 67, 255, 0.2); */


   
}


.quotationDetailsheadingsfbd{
    font-size: 1.1rem;
    font-weight: 800;

    padding: 10px;
    text-align: left;
    color: #211e24;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/*  */


p {
   
}


.button-submit-popup {
    padding: 11px 16px;
    font-size: 14px;
    color: white;
    background-color:  #73509e;
    border: none;
    font-weight: 600;
    border-radius: 10px;
}

.button-submit-popupd {
    padding: 11px 16px;
    font-size: 14px;
    color: white;
    background-color: #bfc13b ;
    background-color: #9b9e51 ;
    border: none;
    font-weight: 600;
    border-radius: 10px;
}
.Contentisnidepopup {
    font-size: 1rem;
}

.popupContentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.imageWrapper {
    margin-left: 20px;
}

.imageinpopup {
    max-width: 200px; /* Increase the width */
    max-height: 200px; /* Increase the height */
    object-fit: cover; /* Maintain aspect ratio */
}





