.new-details-wrap{display:flex;overflow: hidden;}
.new-details-wrap .btn-wrap{flex:0 0 100px;}
.new-details-wrap .body-wrap{flex:1;background:#F9F9F9;padding:50px 50px 0;display: flex;flex-direction: column;text-align: center;}
.new-details-wrap .body-wrap .head{text-align: left;color:#211E24;}
.new-details-wrap .body-wrap .details-wrap{position:relative;padding:0 0 48px;border-bottom:1px solid #AAA;}
.new-details-wrap .body-wrap .details-wrap:last-child{border:none;}
.new-details-wrap .body-wrap .details-wrap .left-wrap{text-align:left;width:45%;margin:24px 0;}
.new-details-wrap .body-wrap .details-wrap .left-wrap .heading{color:#211E24;}
.new-details-wrap .body-wrap .details-wrap .left-wrap .text{color:#211E24;opacity: 0.6;max-width:280px;margin:10px 0;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add{width:60%;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .field-group-wrap-add .input-group-wrap-add .form-group-add{position:relative;padding:30px 0 0;width:100%;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .field-group-wrap-add .input-group-wrap-add{display: flex;gap:20px;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .address-save-btn{height:48px;padding:0 30px;background: #73509E;color: #fff;border-radius: 12px;border: none;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .btn-wraps{width:100%;display:flex;justify-content:flex-end;margin:45px 0 0;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .text{text-align:right;margin:5px 0 0;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .location-icon{position: absolute;right:7px;top:36px;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add ::placeholder{opacity: 0.6;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add{position:relative;padding:30px 0 0;width:100%;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add{width:100%;border:1px solid #AAA;padding:10px 15px;background:#FFFFFF;transition:0.3s ease;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add.has-input-error{border-color:#fe0102 !important;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-label-add{display:block;position:absolute;top:30px;left:15px;padding:5px 10px;transition:0.3s ease;font-size: 14px;}

.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-label-add::after{content:"";position:absolute;left:0;top:15px;right:0; height: 2px;background-color: #F9F9F9;z-index: -1;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add sup{top:1px;color:#fe0102;font-size:16px;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add::placeholder{color:transparent;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add:placeholder-shown ~ .form-label-add{font-size:14px;top:52px;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add:focus{border:1px solid #73509E;}
.new-details-wrap .body-wrap .details-wrap .right-wrap-add .input-group-wrap-add .form-group-add .form-field-add:focus ~ .form-label-add{display:block;position:absolute;top:30px;padding:5px 10px;color:#73509E;}

@media(max-width:991px){
    .new-details-wrap{flex-direction:column;row-gap:20px;}
    .new-details-wrap .body-wrap{padding:30px 30px 0;}
    .new-details-wrap .btn-wrap{flex:1;}
    .new-details-wrap .body-wrap .details-wrap .left-wrap .heading{font-size:14px;}
    .new-details-wrap .body-wrap .details-wrap .left-wrap .text{font-size:14px;margin:10px 0;}
    .new-details-wrap .body-wrap .details-wrap .left-wrap{margin:18px 0;}
}
@media(max-width:767px){
    .new-details-wrap .body-wrap .details-wrap .left-wrap{width:100%;}
    .new-details-wrap .body-wrap .details-wrap .right-wrap-add{width:100%;}
    .new-details-wrap .body-wrap .details-wrap .right-wrap-add .field-group-wrap-add .input-group-wrap-add{flex-direction:column;row-gap:0;}
    .new-details-wrap .body-wrap .details-wrap .left-wrap{margin:18px 0 0;}
    .new-details-wrap .body-wrap .details-wrap .right-wrap-add .btn-wraps{margin:30px 0 0;}
    .new-details-wrap .body-wrap .details-wrap .right-wrap-add .address-save-btn{height:45px;font-size:13px;padding:0 20px;}
}