.productblockmaindiv {


}


.inspectionreportbutton{
  font-size: 14px;
  border-radius: 8px;
  padding: 10px 10px;
  color: white;
  border: none;
  white-space: nowrap;
  background-color: #73509E;
  display: inline-grid;
  grid-template-columns: repeat(2, auto); /* Two columns for the two words */
  gap: 0; /* No gap between words */
  }
  
  
  .Submitbuttonthereport{
    display: flex;
    text-align: left;
    
  
  }
  
   .reportwobuttons{
    width: 100%;
    gap: 15px;
  }
  
  
  .reportbutton,
  .reportbutton2 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 8px;
    padding: 10px 30px;
    height: 50px;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease;
    border: none; 
  }
  
  .Submitreportbuttonv{
    font-size: 14px;
    border-radius: 8px;
    padding: 10px 30px;
    height: 50px;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease;
    border: none; 
    color: white;
    background-color: #73509E;
  }
  
  .reportbutton {
    color: white;
    background-color: #73509E;
  }
  
  .reportbutton2 {
    color: #73509E;
    background-color: white;
    border: 2px solid #73509E;
  }
  
  .reportbutton:hover {
  
    border: none;
    /* background-color: white;
      border: none;
    color: #73509E;
    border: 2px solid #73509E; */
  }
  
  .reportbutton2:hover {
    color: white;
    background-color: #73509E;
    border: none;
  
  }
  
  /* Apply hover effects when one button is hovered */
  .hover-effect {
    background-color: white;
    color: #73509E;
    border: 2px solid #73509E;
  }

.description-text {
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: normal; 
  line-height: 1.5; 
  word-wrap: break-word;
overflow-wrap: break-word;
margin-bottom: 0.25rem;
font-size: inherit;
}

.maintenance-text {
  font-size: 14px;
}
.description-container {
  font-size: 14px;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.totalbadgediv {
margin-left: -3.7%;

  width: 50%;
}

.dvsffbsvdvd{
  margin-top: -2%;
}

/* .totalbadgediv:hover{
  margin-left: 1%;
} */
.product1dv{
background-color: #29bfff;
}  

.product1dv5{
background-color: #609ab3;
}

.product1dv42{
background-color: #334851;
}
.totalbadgediv {
  display: flex; 
  flex-direction: column; 
  text-align: left;

}

.kjbvsfb-multiline {
  box-sizing: border-box; /* Ensure padding and border are included in element's width/height */
  overflow: hidden; /* Hide any overflowed content */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  white-space: normal; /* Allow text to wrap onto multiple lines */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit text to 3 lines (adjust as needed) */
  -webkit-box-orient: vertical;
  padding: 8px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Border for visual separation */
  border-radius: 4px; /* Optional: Rounded corners */
  max-width: 100%; /* Ensure the element doesn't exceed its container's width */
}


.firstbagediv,
.secondtbagediv,
.thirdbagediv {
  padding: 6px;
  color: #fff; 
  text-align: center; 
}

.firstbagediv {
  background-color:#999e51;
  /* background-color:#4caee2; */
  text-align: left;
  border-radius: 8px 8px 0 0; 
}

.secondtbagediv {
  background-color: #999e51;
  color: black;
  text-align: left;
}


.thirdbagediv {
  background-color: #adb263;
  /* background-color: #b7dff3; */
  color: black;
  text-align: left;
  border-radius: 0 0 8px 8px; 
  white-space: nowrap;
}


.firstbagedicproductplan{
  font-weight: 500;
  font-size: 1rem;
  color: rgb(255, 255, 255);
}
.productpriceofamc{
  font-weight: 600;
  color: rgb(67, 63, 63);
}

.kjbvsfb{
  white-space: pre;
}

.productblockmaindiv {
  /* border: 0px, 1px, 0px, 1px; */
  border: 1px solid rgb(218, 212, 212);
  padding: 10px;
  width: 21rem !important;
  border-radius: 8px;
  position: relative;



}
.productblockmaindiv.hovered {
  transform: scaleZ(1.1);
  border:2px solid;

  border-color: #73509E;
}



.productblockmaindiv.hovered .totalbadgediv {
  transform: translateX(1%); /* Move right by 1% */

}
/* .productblockmaindiv.hovered {
  transition: all 0.3s;
  top: -0.5rem;
  padding-top: 12px;
  position: relative;
  height: 28rem;
  background: #f1f1ef;
  color: #FFFFFF;
  border-radius: 0px;
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 40px 0 rgba(0, 0, 0, 0.19);
} */

.planofamc{
  border:1px solid;
  color: white;
  /* background-color: #73509E; */
  background-color: #9B9E51;
  padding: 7px;
  font-size: 0.8rem;
  border-radius: 8px;
 
  transition: transform 0.3s ease;
}

.planofamc:hover {
  cursor: pointer; /* Changes the cursor to a pointer */
  transform: translateY(-5px); /* Moves the element up by 5 pixels */
}
.learnmoreabout{
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-top: 10px;
}

.buttongetstarted{
  background-color: white;
  color: black;
  border: 1px #73509E;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px; /* Adjust as needed */
  align-items: center;
  flex-direction: column;
}

/* Media query for smaller screens */
@media (max-width: 480px) and (min-width: 361px) {
.buttongetstarted {
    font-size: 14px; /* Slightly smaller font size for very small screens */
    padding: 8px 12px; /* Adjust padding for smaller screens */
    max-width: 90%;
}
}
@media (max-width: 360px) {
.buttongetstarted {
    font-size: 12px; /* Even smaller font size for very small screens */
    padding: 6px 10px; /* Further reduce padding */
    width: 95%; /* Allow button to take up more width */
    margin-bottom: 8px; /* Slightly reduce bottom margin */
}
}

/* .productblockmaindiv.hovered 
.productblockmessage.hovered {
  color: #fff;
}
.productblockmaindiv.hovered p
.productblockmessage.hovered {
  color: #fff;
} */
/* .{
  color: #FFF;cd
} */
.discount {
  margin: 0;
  text-align: end;
  font-family: Poppins;
}
.productQuentity {
  opacity: 50%;
  color: #211e24;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}
.imgdiv {
  width: 280px;
  height: 202px;
  border: 0px, 1px, 0px, 1px;
  margin: 0rem auto;
  overflow: hidden;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgdiv img {
  max-width: 100%;
  max-height: auto;
  /* background-color: #211E24; */
}
.productblockmessage {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0em;
  text-align: justify !important;
  overflow: hidden;
  overflow-wrap: break-word;
}

.offer-tag-wrapper {
position: absolute;
top: -10px;
right: -10px;
width: 150px;
height: 150px;
overflow: hidden;
pointer-events: none;
}

.offer-tag {
position: absolute;
top: 35px;
right: -35px;
width: 150px;
padding: 5px 0;
background-color: #ff9800;
color: white;
text-align: center;
font-size: 12px;
font-weight: bold;
transform: rotate(45deg);
box-shadow: 0 0 3px rgba(0,0,0,0.3);
}

.offer-tag::before,
.offer-tag::after {
content: '';
position: absolute;
top: 100%;
width: 0;
height: 0;
}

.offer-tag::before {
left: 0;
border-left: 3px solid #cc7a00;
border-right: 3px solid transparent;
border-bottom: 3px solid transparent;
}

.offer-tag::after {
right: 0;
border-left: 3px solid transparent;
border-right: 3px solid #cc7a00;
border-bottom: 3px solid transparent;
}

.limited-offer-tag {
background-color: #ff9800;
}

.most-popular-tag {
background-color: #4caf50;
}