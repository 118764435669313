.pbtnsbdiv {
    background: #73509e;
  }
  .pbtnsb1div {
    background-color: #9ba24c;
  }
  .buttonsinonelinediv {
    display: flex;
    gap: 10px;
  }
  
  .delveryloactionned {
    align-items: center;
    background: #73509e;
    border-radius: 12px;
    color: #fff;
    column-gap: 10px;
    border: none;
    display: flex;
    font-weight: 500;
    height: 42px;
    font-size: 14px;
    padding: 0 20px;
  }
  
  .sortforstorejsxed {
    align-items: center;
    background: #ffffff;
    border-radius: 12px;
    color: #73509e;
    column-gap: 10px;
    border: 2px solid #73509e;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    padding: 0 20px;
  }
  
  .row-div.layout-wrapdiv {
    display: flex;
    flex-wrap: wrap;
  }
  .layout-wrapdiv {
    margin: 60px 0 0;
  }
  .layout-wrapdiv .product-wrapdiv {
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    margin-top: 30px;
  }
  
  .layout-wrapdiv .product-wrapdiv .products-div {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-content: stretch;
    row-gap: 80px;
  }
  
  .layout-wrapdiv .product-wrapdiv .product-div {
    flex: 1 1 calc(33.33% - 20px);
    min-width: 200px;
    min-height: 410px;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    box-sizing: border-box;
    border-right: 1px solid #73509e1a;
  }
  
  .layout-wrapdiv .product-wrapdiv .product-div .product-innerdiv {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 15px;
  }
  
  .layout-wrapdiv .product-wrapdiv .product-div .product-imgdiv {
    width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: cover;
    display: flex;
    align-self: center;
    padding: 0;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:nth-child(4n) {
    border-right: none;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .content-div {
    margin: 0;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .content-div .name-div {
    font-size: 16px;
    color: #211e24;
    margin: 0 0 10px;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .content-div .name-div:after {
    content: "";
    width: 32%;
    height: 2px;
    background: #211e24;
    display: flex;
    margin-top: 4px;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .content-div .location-div {
    font-size: 14px;
    color: #7a7c7f;
    margin: 0 0 5px;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .content-div .price-wrapdiv {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: space-between;
    color: #211e24;
    margin: 0 0 10px;
  }
  .layout-wrapdiv
    .product-wrapdiv
    .product-div
    .content-div
    .price-wrapdiv
    .time-div {
    font-size: 16px;
  }
  .layout-wrapdiv
    .product-wrapdiv
    .product-div
    .content-div
    .price-wrapdiv
    .price-div {
    font-size: 16px;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .heart-div {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    padding: 0;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .heart-div svg {
    stroke: #211e24;
    fill: transparent;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .heart-div.favorite svg {
    fill: #73509e;
    stroke: #73509e;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .purchase-div {
    border: none;
    border-radius: 12px;
    color: #fff;
    font-size: 16px;
    height: 54px;
    padding: 0 18px;
    transition: 0.3s;
    white-space: nowrap;
    background-color: #73509e;
  }
  .layout-wrapdiv .product-wrapdiv .product-div .purchasef-div {
    font-size: 16px;
    color: #fff;
    height: 54px;
    border: none;
    padding: 0 18px;
    border-radius: 12px;
    transition: 0.3s;
    white-space: nowrap;
    background-color: #73509e;
  }
  
  .layout-wrapdiv .product-wrapdiv .product-div:hover .product-innerdiv {
    background: #fff;
    padding: 20px 25px;
    min-height: 450px;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:hover .content-div .name-div {
    color: #211e24;
  }
  .layout-wrapdiv
    .product-wrapdiv
    .product-div:hover
    .content-div
    .name-div:after {
    background: #211e24;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:hover .content-div .location-div {
    color: #211e24;
  }
  .layout-wrapdiv
    .product-wrapdiv
    .product-div:hover
    .content-div
    .price-wrapdiv {
    color: #211e24;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:hover .heart-div svg {
    stroke: #211e24;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:hover .heart-div.favorite svg {
    fill: #73509e;
    stroke: #73509e;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:hover .purchase-div {
    display: block;
  }
  .layout-wrapdiv .product-wrapdiv .product-div:hover .purchasef-div {
    display: block;
  }
  
  @media (max-width: 1199px) {
    .layout-wrapdiv .product-wrapdiv .products-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-around !important;
      row-gap: 40px;
    }
  
  
    .layout-wrapdiv .product-wrapdiv .product-div {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
      min-width: 200px;
      min-height: 450px;
      margin: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      transition: 0.2s;
      box-sizing: border-box;
    }
  }
  
  @media (max-width: 767px) {
    .layout-wrapdiv .product-wrapdiv .products-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .layout-wrapdiv .product-wrapdiv .product-div {
      flex: 1 1 calc(70% - 20px);
      max-width: calc(70% - 20px);
    }
  }
  
  @media (max-width: 479px) {
    .layout-wrapdiv .product-wrapdiv .products-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: center;
    }
    .layout-wrapdiv .product-wrapdiv .product-div {
      flex: 1 1 calc(80% - 15px);
      max-width: calc(80% - 15px);
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1599px) {
    .layout-wrapdiv .product-wrapdiv .products-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-around !important;
      row-gap: 80px;
    }
    .layout-wrapdiv .product-wrapdiv .product-div {
      flex: 1 1 calc(33.33% - 20px);
    }
  }
  
  @media (min-width: 1600px) and (max-width: 1800px) {
    .layout-wrapdiv .product-wrapdiv .products-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      row-gap: 40px  !important;
    }
    .layout-wrapdiv .product-wrapdiv .product-div {
      flex: 1 1 calc(25.33% - 20px);
      max-width: calc(25.33%);
    }
  }
  
  @media (min-width: 1801px) and (max-width: 3000px) {
    .layout-wrapdiv .product-wrapdiv .products-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: stretch;
      row-gap: 40px  !important;
    }
    .layout-wrapdiv .product-wrapdiv .product-div {
      flex: 1 1 calc(25.33% - 20px);
      max-width: calc(25.33% - 20px);
    }
  }
  