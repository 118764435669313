.div{
    width: 2.875rem;
height: 2.875rem;
border-radius: 2.125rem;
border: 1px solid #211E24;
display: flex;
align-items: center;
justify-content: center;
}
/* .img{
    width: 1rem;
    height: 0.875rem;
} */
