.block-home{
    border:2px solid #F6F6F6;
    width: 100%;
    max-width: 285px;
    height: 220px;
    margin-top: 2rem;
  
}
.block-home div
{
    width: 52px;
    height: 52px;
    border-radius: 26px;
    background-color: #E7E7E7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
@media (max-width:768px){
    .block-home{
    background-color: #FFFFFF;
}
}
@media (max-width:576px) {
    .block-home{
        max-width: 241px;
    }
}