.popuppagecartdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: rgba(232, 227, 227, 0.5); /* Optional: to dim the background */
}

.phniintheumber{
  text-align: left;
  font-size: 12px;
}
.centeredcolumndivforpopup {
  background-color: white;
  border: 1px solid rgb(205, 200, 200);
  border-radius: 5px;
  padding: 20px; /* Optional: add padding if necessary */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
}

.edityouraddresstext {
  border-bottom: 1px solid rgb(205, 200, 200);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.youreditaddresstext {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.input-container {
  margin: 0;
}

.bi-form-group-white {
  background-color: white;
}

.bi-form-field-white {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.heading-400-14-12 {
  font-size: 14px;
  font-weight: 400;
}

.bi-form-label-white {
  margin-top: 5px;
}
