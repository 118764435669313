.apply-loan{display:flex;}
.apply-loan .btn-wrap{flex:0 0 100px;}
.apply-loan .content-wrap{flex:1;background:#F9F9F9;padding:50px 50px 150px;}
.apply-loan .content-wrap .body-wrap{position:relative;}
.apply-loan .content-wrap .body-wrap .heading-para{width: 80%;margin:10px 0 15px;}
.apply-loan .content-wrap .body-wrap .product-wrap{display:flex;}
.apply-loan .content-wrap .body-wrap .product-wrap .left-wrap{flex:1;padding-top: 20px;}
.apply-loan .content-wrap .body-wrap .product-wrap .left-wrap .title{position:relative;margin:0 0 10px;padding:0 0 5px;color:#211E24;}
.apply-loan .content-wrap .body-wrap .product-wrap .left-wrap .tille-para{max-width:280px;}
.apply-loan .content-wrap .body-wrap .product-wrap .right-wrap{flex:1;display:flex;flex-direction:column;row-gap:8px;}
.apply-loan .content-wrap .body-wrap .product-wrap .right-wrap .field-group{padding-top: 15px;margin:15px 0 0;position: relative;}
.apply-loan .content-wrap .body-wrap .product-wrap .right-wrap .field-group .input-field{width: 100%;outline: 0;border: 1px solid #AAAAAA; padding: 10px;background: transparent;color: #211E24;}
.apply-loan .content-wrap .body-wrap .product-wrap .right-wrap .field-group .input-label{display:block;position:absolute;top:15px;left:10px;background: #F9F9F9;color:#AAAAAA;padding:5px;}
.apply-loan .content-wrap .body-wrap .product-wrap .right-wrap .submit-btns{height:48px;padding:0 30px;background:#73509E;color:#fff;border-radius:12px;border:none;position:absolute;right:0;bottom:-100px;}

@media (max-width:767px){
    .apply-loan .btn-wrap{flex:0 0 50px;}
    .apply-loan .content-wrap .body-wrap .heading-para{width:100%;margin:10px 0 0px;}
    .apply-loan .content-wrap .body-wrap .product-wrap .left-wrap .tille-para{max-width:100%;margin:0;}
    .apply-loan .content-wrap .body-wrap .product-wrap{flex-direction:column;}
    .apply-loan .content-wrap .body-wrap .product-wrap .left-wrap .title{padding:0;}
    .apply-loan .content-wrap .body-wrap .product-wrap .right-wrap .submit-btns{height:40px;padding:0 30px;}

}