.field-group-wrap{display:flex;align-items:center;column-gap:20px;width:100%;}
.upload-wrap{display:flex;flex-direction:column;border:1px dashed #AAA;background:#F5F5F5;padding:20px;box-sizing:border-box;width:100%;min-height:168px;align-items:center;justify-content:center;border-radius:8px;row-gap:10px;margin-top:30px;cursor:pointer;}
.bi-upload-file{display:none;}
.uploading-wrap{display:flex;flex-direction:column;box-sizing:border-box;width:100%;row-gap:5px;margin-top:20px;}
.uploading-wrap .uploading-item{display:flex;align-items:center;column-gap:10px;}
.uploading-wrap .uploading-item .file-data{display:flex;flex-direction:column;flex:1;row-gap:2px;}
.uploading-wrap .uploading-item .file-data .name{color:#211E24;}
.uploading-wrap .uploading-item .file-data .process{position:relative;width:100%;height:3px;border-radius:2px;background:#4E4E4E;}
.uploading-wrap .uploading-item .file-data .process .process-inner{position:absolute;width:30%;height:3px;border-radius:2px;background:#73509E;}
.uploading-wrap .uploading-item .file-data .size-status-wrap{display:flex;justify-content:space-between;column-gap:20px;}
.uploading-wrap .uploading-item .file-data .size-status-wrap .size{color:#211E24;}
.uploading-wrap .uploading-item .file-data .size-status-wrap .status{color:#211E24;}