@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: Poppins;
}

.unique-feedback-heading-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:-1%;
}

.unique-HelpUs {
  font-size: 30px;
  text-align: center;
  width: 80%;
  margin-top: 0;
}

.unique-new5 {
  margin-top: 1%;
}

hr {
  margin: 0 auto;
  border: 3px solid green;
  border-radius: 5px;
  width: 15%;
}

.unique-firstdivcolumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:2%;
}
.rge{
  margin-top:4%;
}

.unique-seconddivcolumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:2%;
}

.unique-coled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
}
.unique-empty{
    margin-top: 5px;
}
.row {
    display: flex;
    flex-wrap: wrap; 
  }

.unique-bordered {
  border: 1px solid #ddd;
  border-radius: 12px;
  box-sizing: border-box;
  flex: 1;
  margin-bottom: 10px;
}

.unique-custom-color {
  color: #73509e;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.unique-buttons {
    display: flex;
    justify-content: center;
  }
  
  .unique-btn12,
  .unique-btn22 {
    padding: 8px 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid;
    border-radius: 40px;
    color: white;
    line-height: 24px;
    cursor: pointer;
  }
  
  .unique-btn12 {
    background-color: green;
    opacity: 0.8;
  }
  
  .unique-btn12:hover {
    background-color: green;
    transform: scale(1.1);
    transition: transform 0.3s ease-out;
  }
  
  .unique-btn22 {
    background-color: gray;
    opacity: 0.8;
  }
  
  .unique-btn22:hover {
    background-color: gray;
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  .unique-selected {
    transform: scale(1.1);
    border-width: 2px;
    border-color: #735197;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    
  }

.unique-ratingsbfx {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.visually-hidden33 {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.row.align-items-start {
    height: auto; 
  }


.unique-error-message {
  color: red;
  margin-top: 5px;
}

.unique-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3%;

}
.mt-5 {
    margin-top: 3rem;
  }
  
  .popup-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 90%;
    width: 500px;
  }
  
  .popup-content img {
    width: 100%;
    height: auto;
  }
  
  .close-popup {
    position: absolute;
    top: 0px;
    right: 6px;
    cursor: pointer;
  }


  .popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 1000; /* Ensure it overlays everything else */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 576px) {

    
       .unique-feedback-heading-text {
        display: flex;
        justify-content: center;
        align-items: center;
        /* Ensures full height centering */
        text-align: center;
        margin-top:5%;
      }
      

      .unique-HelpUs {
        font-size: 20px;
        text-align: center;
        width: 80%;
        margin-top: 0; /* Reset margin if needed */
      }
      
        .unique-firstdivcolumn .container {
          margin-top: 6%;
        }
        .rge{
          margin-top:6%;
        }
        .unique-seconddivcolumn .container {
          margin-top: 6%;
        }
        .unique-firstdivcolumn .row {
          display: flex;
          flex-direction: column;
      
        }
        .unique-seconddivcolumn .row {
          display: flex;
          flex-direction: column;
      
        }
        .unique-coled{
      
          margin-bottom: -4%;
        }
        .unique-firstdivcolumn .col {
          width:90%;
        }
        .unique-seconddivcolumn .col {
          width:90%;
        }
        .unique-error-message{
          font-size: 12px;
        }
        .unique-custom-color{
          font-size: 14px;
        }
        .unique-para{
          font-size: 12px;
        }
        .unique-btn12{
      font-size: 12px;
        }
        .unique-btn22{
          font-size: 12px;
        }
        .unique-submitbtn{
          margin-top: 5%;
          font-size: 12px;
        }
        .unique-submitbtn:hover{
          transform: scale(1);
        }
        .star:hover{
          transform: scale(1);
        }
        .thanks{
          font-size: 12px;
        }


  }  
  
  /* Tablets (portrait and landscape) */
  @media (min-width: 577px) and (max-width: 768px) {
    .unique-feedback-heading-text {
      display: flex;
      justify-content: center;
      align-items: center;
      /* Ensures full height centering */
      text-align: center;
      margin-top:5%;
    }
    .unique-feedback{
      font-size: 14px;
    }
    .unique-HelpUs {
      font-size: 20px;
      text-align: center;
      width: 80%;
      margin-top: 0; /* Reset margin if needed */
    }
    
    .unique-firstdivcolumn .container {
   
      margin-top: 20%;
    }
    .rge{
   
      margin-top: 4%;
    }
    .unique-firstdivcolumn .row {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .unique-seconddivcolumn .row {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .unique-coled{
      margin-bottom: -2%;
    }
    .unique-submitbtn{
      margin-top: 3%;
    }
  
  }
  
  /* Small desktops */
  @media (min-width: 769px) and (max-width: 992px) {
    
    .unique-feedback-heading-text {
      display: flex;
      justify-content: center;
      align-items: center;
      /* Ensures full height centering */
      text-align: center;
      margin-top:5%;

    }
    
    .unique-HelpUs {
      font-size: 30px;
      text-align: center;
      width: 80%;
      margin-top: 0; /* Reset margin if needed */
    }
    .unique-firstdivcolumn .col {
      flex: 1 1 100%; /* Equal width for all columns */
      max-width: 100%;
    }  
    .rge{
   
      margin-top: 4%;
    }
    .unique-seconddivcolumn .col {
      flex: 1 1 100%; /* Equal width for all columns */
      max-width: 100%;
    }  
    
    .coled{
  
      margin-bottom: -2%;
    }
  
    .unique-submitbtn{
      margin-top: 3%;
    }
    
    /* .firstdivcolumn .container {
      margin-top: 5%;
    } */
    /* .firstdivcolumn .row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
    .firstdivcolumn .col {
      width: 50%;
    } */
  }
  
  /* Medium desktops */
  @media (min-width: 993px) and (max-width: 1200px) {
    .unique-feedback-heading-text {
      margin-top: 4%;
    }
    .unique-firstdivcolumn .container {
      margin-top: 50%;
    }
  
    .unique-firstdivcolumn .row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
  
    .unique-firstdivcolumn {
      display: flex;
      justify-content: center; /* Center the child elements horizontally */
    }
    .unique-firstdivcolumn .col {
     
      flex: 1 1 100%; /* Equal width for all columns */
      max-width: 80%;
      margin-left: 10%;
    }
  

    .unique-seconddivcolumn .container {
      margin-top: 50%;
    }
  
    .unique-seconddivcolumn .row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
  
    .unique-seconddivcolumn {
      display: flex;
      justify-content: center; /* Center the child elements horizontally */
    }
    .unique-seconddivcolumn .col {
     
      flex: 1 1 100%; /* Equal width for all columns */
      max-width: 80%;
      margin-left: 10%;
    }



    .unique-submitbtn {
      margin-top: 0.5%;
    }
  
    .coled {
      margin-bottom: -4%;
      padding: 2rem; /* Add padding inside the border */
    }
  }
  
  
  /* Large desktops */
  @media (min-width: 1201px) and (max-width: 1440px) {
    
    .unique-HelpUs{
      margin-top: 4%;
    }
    .unique-firstdivcolumn .container {
      margin-top: 35%;
    }
    .unique-firstdivcolumn .row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
    .unique-firstdivcolumn .col {
      width: 33.33%;
    }
    .unique-seconddivcolumn .container {
      margin-top: 35%;
    }
    .unique-seconddivcolumn .row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
    .unique-firstdivcolumn .col {
      width: 33.33%;
    }
    .unique-seconddivcolumn .col {
      width: 33.33%;
    }
    .unique-submitbtn{
      margin-top: 5%;
    }
  }
  
  /* Extra large desktops */
  @media (min-width: 1441px) {
    .unique-HelpUs{
      margin-top: 4%;
    }
    .unique-firstdivcolumn .container {
      margin-top: 5%;
    }
    .unique-firstdivcolumn .row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
    .unique-firstdivcolumn .col {
      width: 33.33%;
    }
    .unique-submitbtn{
      margin-top: -2%;
    }
  }
  
  
  @media (max-width: 768px) {
    .popup-content {
        width: 90%;
    }
  
    .close-popup svg {
        width: 20px;
        height: 20px;
    }
  }
  
  .mobile-icon {
    display: flex;
    margin: 0 0 30px;
  }
  
  .mobile-icon svg {
    margin: 0 auto;
    flex: 190px 0 0;
  }
  .thanks{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
  }
  

 .unique-submitbtn{

  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 12px;
  color: white;
  line-height: 24px;
  background-color: #73509e;
  cursor: pointer;
}