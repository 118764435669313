/*----------------------------------------------Total Container and Each individual item-------------------------------------------------*/
.similar-products-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 2%;
  }
  
  .similar-oils-container {
    padding: 0 15px;
    margin: 0 auto;
  }
  
  .similar-products-heading {
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: 600;
    margin-left: 20px;
    color: #212121;
  }
  
  .arrowviewoils {
    margin-right: 20px;
  }
  
  .arrowicontext {
    text-decoration: none;
    color: #000;
  }
  
  .arrowicontext:hover {
    color: black;
  }
  
  .arrowicontext:hover {
    color: black;
  }
  
  .arrowicontext:active {
    color: black;
  }
  
  .arrow-iconfor {
    display: inline-flex;
    align-items: center;
    border-radius: 50%;
    border: 1px solid black;
    background-color: white;
    padding: 10px;
  }
  
  .each-individual-card-for-similarmachines {
    width: 90% !important;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s;
    margin: 0 5px;
  cursor: pointer !important;
    height: 360px;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  
  .each-individual-card-for-similarmachines:hover {
    transform: translateX(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .each-individual-card-for-similarmachines img {
    margin-top: 1%;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .product-infofor {
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .product-infofor h4 {
    font-size: 1rem;
    margin: 5px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
  }
  
  .product-infofor {
    margin-top: auto;
    font-size: 0.9rem;
    color: #555;
  }
  
  /*----------------------------------------------React slick Slider-------------------------------------------------*/
  
  .slick-prev {
    left: -17px;
  }
  
  .slick-next {
    right: -10px !important;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
  }
  
  
   /*----------------------------------------------Responsive till 400px----------------------------------------------*/
   @media (max-width: 440px) {
    .similar-products-container {
      margin-top: 0%;
    }
  
  
    .similar-oils-container {
      flex-direction: column;
  
      align-items: center;
      text-align: center;
    }
  
    .similar-products-heading {
      margin: 10px 0;
      font-size: 18px !important;
    }
  
    .arrowviewoils {
      margin: 10px 0;
    }
  }
  
  
   /*----------------------------------------------Responsive from 441px to 768----------------------------------------------*/
  @media screen and (min-width: 441px) and (max-width: 768px) {
    .similar-products-container {
      margin-top: 0%;
    }
  
  
  
    .similar-oils-container {
      flex-direction: row;
      align-items: center;
    }
  
    .similar-products-heading {
      font-size: 20px !important;
      margin: 10px 0;
    }
  
    .arrowviewoils {
      margin: 10px 0;
    }
  }
  
  
  /* .sjzlbdzgcbgd {
    display: flex !important;
    justify-content: center !important;
  } 
  
   .similar-products-containerforal {
    display: flex !important;
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
  } 
  
  .similar-products-headingc {
    font-size: 32px;
    margin-bottom: 24px;
    font-weight: 600;
    color: #212121;
  }
    .custom-slider .slick-slide {
    display: inline-block !important;
  }
    
   .rating-container {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    padding: 5px 0;
    color: #555;
  }
  
  .rating-container span {
    font-size: 14px;
    color: #333;
  } 
  
   .auc-Recommend-item {
    width: 100%;
  }
   */
  