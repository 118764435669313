.bi-popup-wrap .inner .head-wrap{flex-direction: column;align-items: flex-start;margin:0;}
.bi-popup-wrap .inner .head-wrap .heading-600-20 {color:#211E24;margin:0 0 8px;}
.bi-popup-wrap .inner .field-group{max-width: 600px;width:100%;padding-top: 15px;margin:15px 0 0;position: relative;}
.bi-popup-wrap .inner .field-group .input-field{width: 100%;outline: 0;border: 1px solid #73509E; padding: 10px;background: transparent;}
.bi-popup-wrap .inner .field-group .input-label{display:block;position:absolute;top:15px;left:10px;background: #fff;color:#73509E;padding:5px;}
.bi-popup-wrap .inner .text{color:#211E24;opacity: 0.8;text-align: left;}
.bi-popup-wrap .inner .otp-btn{flex:54px 0 0;background:#73509E99;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}

@media(max-width:767px){
    .bi-popup-wrap .inner .head-wrap .heading-600-20{margin:0;}
    .bi-popup-wrap .inner .field-group{max-width:420px;}
}
@media(max-width:576px){
    .bi-popup-wrap .inner .head-wrap{row-gap:1px;}
    .bi-popup-wrap .inner .field-group{margin: 10px 0 0;}
}