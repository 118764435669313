.border-line{
    border-top: 1px solid rgba(115, 80, 158, 0.20);
    border-bottom: 1px solid rgba(115, 80, 158, 0.20);
}
.firstsection-right.anual_img img{
    margin-left: -0.8rem;
}

.typ-pos-rtlv{
    position: relative;
}
.typ-pos-rtlv .viewbtn {
    top: 60%;
    right: 28%;
}

.transparent-btn{
    margin-top: 60px;

}

.annual_section-lyt .h1{
    width: 100%;
}
.form-flexs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 0px;
    padding-bottom:10px;
}
.form__heading{
    font-size: 24px;
    color: #211E24;
    font-weight: 600;
    margin-bottom: 12px;
}
.small_para{
    font-size: 16px;
    margin-bottom: 0;
    color: #2B2A2C;
    font-weight: 400;
    width: 350px;
}

.form-width{
    margin-right: 25px!important;
}

/* .ul_list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5px;
    margin-top: 20px;
    position: relative;

} */
.ul_list::after{
    /* content: '';
    position: absolute;
    top: -7px;
    opacity: 0.8; */
    background: #DDD;
    /* width: 100%;
    height: 1px;
    left: 0; */
}
.ul_list div{
    font-size: 14px;
    font-weight: 400;
    color: #000;
    list-style: none;
    margin-bottom: 15px;
    text-align: left;
    max-width: 220px;
    padding-left: 0;
}
.amount-lyt{
    width: 180px;
    margin-top: 30px;
}
.amount-lyt .pro_heading{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}
.amount-lyt .price{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0;
}
.cut-price{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
}
.discount_no{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.amount-lyt .limited_time{
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
}

.productblockmaindiv{
    padding-right: 0!important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .typ-anuual-sections .firstsection-left-top{
        margin-top: 40px;
    }
    .anual_heading{
        line-height: 38px;
    }
    .typ-anuual-sections .firstsection{
        flex-direction: column;
    }
    .firstsection-right.anual_img img {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        position: inherit;
        height: auto;
    }
    .firstsection-left.container{
        margin-bottom: 20px!important;
    }
    .sixthsection-maindiv{
        margin-top: 2rem;
    }
    .form-flexs{
        flex-direction: column;
    }
}