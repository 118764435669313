.secondsection{
    margin: 5rem 0 0 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.para{
    width: 42.9375rem;
    height: 1.75rem;
    opacity:80%;
    margin-bottom: 3.75rem;
    font-family: Poppins;
font-size: 1rem;
font-weight: 400;
line-height: 1.75rem;
letter-spacing: 0em;
text-align: center;

}

.list{
    margin-bottom: 7.875rem;
    padding: auto 0rem;
}
.secondsection-bottom{
    margin-top: 2rem;
    background: linear-gradient(to right,white 0%, white 50%, #F5F5F5 50%,#F5F5F5 100% );
}

@media (max-width: 767px) {
    .secondsection {
      margin-top: 3rem;
    }

    .para {
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 2.5rem;
    }
  
    .list {
      margin-bottom: 5rem;
    }
  
    .secondsection-bottom {
      margin-top: 1rem;
    }
  }