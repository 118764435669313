.sixthsection-maindiv {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.somesgnfkjnfkjfb{
    visibility: hidden;
}
.sixthsection-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.sixthsection-maindiv h1 {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.75rem;
    letter-spacing: 0em;
    text-align: left;
}

.sixthsection-heading p {
    opacity: 60%;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.sixthSectionSlider {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding: 10px;
    overflow-x: hidden;
}