.auc-Recommend-title {
    display: none;
  }
  
  .main-slider-wrap .slider-card-item .heart {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    padding: 0;
  }
  .main-slider-wrap .slider-card-item .heart svg {
    stroke: #211e24;
    fill: transparent;
  }
  .main-slider-wrap .slider-card-item .heart.favorite svg {
    fill: #73509e;
    stroke: #73509e;
  }
  .main-slider-wrap .slider-card-item:hover .heart svg {
    stroke: #fff;
  }
  .main-slider-wrap .slider-card-item:hover .heart.favorite svg {
    fill: #73509e;
    stroke: #73509e;
  }
 
  .top-wrap-main {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
  }
  .top-wrap-main .form-group {
    position: relative;
    margin: 0;
  }
  .top-wrap-main .form-group input {
    border: 1px solid #aaa;
    padding: 0 40px 0 15px;
    height: 48px;
  }
  .top-wrap-main .form-group svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  

  .name-wrap-main {
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0 0;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .name-wrap-main .people {
    display: flex;
    column-gap: 15px;
    align-items: center;
  }
  .name-wrap-main .people svg {
    cursor: pointer;
  }
  

  .img-wrap-main {
    max-width: 600px;
  }
  .product-img-wrap-main {
    margin: -24px 0 0;
  }
  .product-img-wrap-main .product {
    padding: 40px;
    position: relative;
    transition: 0.2s;
   
  }
  .product-img-wrap-main .product:hover {
    background: #9b9e51;
    cursor: pointer;
  }
  .product-img-wrap-main .product .product-img {
    display: flex;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    height: 320px;
    object-fit: cover;
  }
  .product-img-wrap-main .product .drag {
    background: #73509ec9;
    width: 100px;
    height: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    top: 60px;
    right: 60px;
    cursor: pointer;
  }
  .product-img-wrap-main .product .framre {
    background: #73509e;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    position: absolute;
    bottom: 40px;
    right: 100px;
  }
  /* .product-img-wrap-main .slick-slider .slick-arrow{display: none !important;} */
  /* .product-img-wrap-main .drag{display:none;} */
  .product-img-wrap-main .drag {
    background: #73509ec9;
    width: 100px;
    height: 100px;
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    top: 70px;
    left: 220px;
    z-index: 2;
    cursor: pointer;
  }
  .product-img-wrap-main .framre {
    display: none;
  }
  
  .product-img-wrap-main:hover .drag {
    background: #73509ec9;
    width: 100px;
    height: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    top: 70px;
    left: 220px;
    z-index: 2;
    cursor: pointer;
  }
  .product-img-wrap-main:hover .framre {
    background: #73509e;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    position: absolute;
    bottom: 40px;
    right: 100px;
    z-index: 2;
    cursor: pointer;
  }
  
  /* ProductInfo */
  .box-wrap-main {
    display: flex;
    gap: 25px;
  }
  .box-wrap-main .box-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .box-wrap-main .box-inner .box-item {
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .box-wrap-main .box-inner .box-item .heading-600-18 {
    margin: 0 0 8px;
  }
  .box-wrap-main .box-inner .box-item .heading-400-14-12 .info-text-wrap {
    position: relative;
  }
  .box-wrap-main
    .box-inner
    .box-item
    .heading-400-14-12
    .info-text-wrap
    .info-text {
    position: absolute;
    border-radius: 4px;
    background: #f2f2f2;
    color: #211e24;
    padding: 15px;
    width: 330px;
    font-size: 12px;
    line-height: 20px;
    bottom: 30px;
    right: -22px;
  }
  .box-wrap-main .box-inner .box-item .inner {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .box-wrap-main .box-inner .box-item .inner-y-purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
  }
  .box-wrap-main .box-inner .box-item .box-btn {
    background: none;
    border: none;
    line-height: 0;
  }
  .box-wrap-main .box-inner .box-item .heading-400-14-12 {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .box-wrap-main .box-inner .box-item .heading-400-14-12 svg {
    cursor: pointer;
  }
  .box-wrap-main .box-inner .box-item .btn-wrap {
    display: flex;
    align-items: center;
    column-gap: 15px;
    align-self: flex-end;
    margin-top: 25px;
  }
  .box-wrap-main .box-inner .box-item .btn-wrap .box-item-btn {
    height: 48px;
    background: #73509e;
    color: #fff;
    border-radius: 12px;
    border: none;
    width: max-content;
    margin: 0;
    padding: 0 30px;
  }
  .box-wrap-main .box-inner .box-item .btn-wrap .box-item-btn.buy-now {
    background: #fff;
    color: #73509e;
    border: 1px solid #73509e;
  }
  .box-wrap-main .box-inner .box-item .btn-wrap .box-item-btn.buy-now:hover {
    background: #73509e;
    color: #fff;
  }
  
  .product-img:hover{
    cursor: pointer;
  }
  .box-wrap-main .box-inner .box-item .box-item-btn {
    height: 48px;
    background: #73509e;
    color: #fff;
    border-radius: 12px;
    border: none;
    width: max-content;
    width: 170px;
    margin: 26px 0 0;
    align-self: flex-end;
  }
  /* .box-wrap-main .box-inner .box-item .tabs-wrap{display:flex;justify-content:space-between;border-bottom:1px solid #6f6f7090;} */
  .box-wrap-main .box-inner .box-item .tabs-wrap {
    display: flex;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 10px 10px 10px 0px;
    margin-bottom: 10px;
  }
  .box-wrap-main .box-inner .box-item .tabs-wrap::-webkit-scrollbar {
    display: none;
  }
  .box-wrap-main .box-inner .box-item .tab-btn {
    border: none;
    background: none;
    color: #6f6f70;
    position: relative;
    padding: 0 0 4px;
    margin-right: 20px;
  }
  .box-wrap-main .box-inner .box-item .active {
    color: #211e24;
    margin-right: 20px;
  }
  .box-wrap-main .box-inner .box-item .active:after {
    content: "";
    height: 3px;
    background: #9b9e51;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    border-radius: 3px;
  }
  .box-wrap-main .box-inner .box-item .items-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin: 16px 0 0;
  }
  .box-wrap-main .box-inner .box-item .items-wrap .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
  }
  
  /* Finance */
  .finance-head {
    padding: 0 20px;
  }
  .finance-wrap-main {
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff 45%,
      #f5f5f5 45%,
      #f5f5f5 100%
    );
  }
  .finance-wrap-main .max-container .body {
    display: flex;
  }
  .finance-wrap-main .max-container .body .left {
    width: 45%;
    padding: 30px 80px 0 20px;
  }
  .finance-wrap-main .max-container .body .left .content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 0;
  }
  .finance-wrap-main .max-container .body .left .range-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 20px 0 0;
  }
  /* .finance-wrap-main .max-container .body .left .range-wrap .top-wrap{} */
  .finance-wrap-main .max-container .body .left .range-wrap .track {
    width: 100%;
    height: 4px;
    background: #d9dde8;
    border-radius: 4px;
    position: relative;
  }
  .finance-wrap-main .max-container .body .left .range-wrap .track .fill {
    position: absolute;
    height: 4px;
    background: #9b9e51;
    width: 30%;
    border-radius: 5px;
  }
  .finance-wrap-main .max-container .body .left .range-wrap .track .fill .circle {
    height: 16px;
    width: 16px;
    border: 1px solid #74509e;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    right: 0;
    top: -6px;
  }
  .finance-wrap-main .max-container .body .left .tabs-wrap {
    display: flex;
    column-gap: 25px;
  }
  .finance-wrap-main .max-container .body .left .tab-btn {
    font-size: 20px;
    border: none;
    background: none;
    color: #6f6f70;
    position: relative;
    padding: 0 0 4px;
    margin: 0 0 40px;
  }
  .finance-wrap-main .max-container .body .left .active {
    color: #211e24;
  }
  .finance-wrap-main .max-container .body .left .active:after {
    content: "";
    height: 3px;
    background: #9b9e51;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    border-radius: 3px;
  }
  .finance-wrap-main .max-container .body .left .box-item-btn {
    height: 48px;
    background: #73509e;
    color: #fff;
    border-radius: 12px;
    border: none;
    width: max-content;
    width: 170px;
    margin: 60px 0 0;
  }
  .finance-wrap-main .max-container .body .right {
    width: 55%;
    padding: 60px 0px;
  }
  .finance-wrap-main .max-container .body .right .inner {
    margin-left: 60px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 60px;
  }
  .finance-wrap-main .max-container .body .right .inner .item {
    width: calc(33.33% - 27px);
  }
  .finance-wrap-main .max-container .body .right .inner .item .icon {
    width: 52px;
    height: 52px;
    background: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 0 16px;
  }
  .finance-wrap-main .max-container .body .right .inner .item .icon-txt {
    max-width: 120px;
  }
  
  /* Benefits */
  .benefits-main .head {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
  }
  .benefits-main .head .box-btn {
    background: none;
    border: none;
  }
  .benefits-main .benefits-content {
    background: #f9f9f9;
    margin: 50px 0 0;
    border-radius: 12px;
    padding: 60px 40px;
  }
  .benefits-main .benefits-content .item {
    width: calc(25%);
  }
  .benefits-main .benefits-content .item .progress-circle {
    width: 140px;
    height: 140px;
    overflow: hidden;
    margin: 0 auto 25px;
    position: relative;
  }
  .progress-circle svg {
    position: relative;
    width: 140px;
    height: 140px;
    transform: rotate(-90deg);
  }
  .progress-circle svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #ddd;
    stroke-width: 10;
    stroke-linecap: round;
  }
  .progress-circle svg .main-circle {
    stroke-dasharray: 515px;
    stroke-dashoffset: calc(515px - (515px * var(--percent)) / 100);
  }
  .benefits-main .benefits-content .item .score-wrap {
    position: absolute;
    top: 44px;
    right: 0;
    left: 0;
  }
  .benefits-main .benefits-content .item .title {
    max-width: 195px;
    margin: 0 auto;
    font-size: 16px;
  }
  .benefits-main .benefits-content .item .title span {
    display: flex;
  }
  .benefits-main .benefits-content .item .title .svg-wrap {
    position: relative;
  }
  .benefits-main .benefits-content .item .title .svg-wrap svg {
    cursor: pointer;
  }
  .benefits-main .benefits-content .item .title .svg-wrap span {
    position: absolute;
    border-radius: 4px;
    background: #ffffff;
    color: #211e24;
    padding: 15px;
    width: 330px;
    font-size: 12px;
    line-height: 20px;
    bottom: 55px;
    right: 0;
    box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
  }
  .benefits-content .benefits-content-head .slider-button-wrap {
    display: none;
  }
  /* Benefits Layout 2 */
  .benefits-main-2 .head {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
  }
  .benefits-main-2 .head .box-btn {
    background: none;
    border: none;
  }
  .benefits-main-2 .benefits-content-2 {
    background: #f9f9f9;
    margin: 50px 0 0;
    border-radius: 12px;
    padding: 60px 40px;
    display: flex;
    column-gap: 30px;
  }
  .benefits-main-2 .benefits-content-2 .item {
    flex: 1;
  }
  .benefits-main-2 .benefits-content-2 .item .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    overflow: hidden;
    background: #e7e7e7;
    border-radius: 100%;
    margin: 0 0 20px;
  }
  .benefits-main-2 .benefits-content-2 .item .content-section {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  
  /* Buyer */
  .market-buyers-main .col-cust .market-buyers-inner .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin: 0 0 60px;
  }
  .market-buyers-main .col-cust .market-buyers-inner .head .slider-button-wrap {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
  .market-buyers-main
    .col-cust
    .market-buyers-inner
    .head
    .slider-button-wrap
    .slider-button {
    width: 42px;
    height: 42px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #211e24;
    border-radius: 100%;
    background: none;
  }
  .market-buyers-main .main-slider-wrap {
    margin: 0 0 100px;
  }
  .market-buyers-main .main-slider-wrap .cust-slider .slick-list .slick-track {
    display: flex;
    column-gap: 20px;
    padding: 10px 0;
  }
  .market-buyers-main .main-slider-wrap .cust-slider .slider-card-item {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    transition: 0.2s;
    overflow: hidden;
    border-radius: 12px;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .product-img {
    display: flex;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .main-content-wrap {
    padding: 25px 25px 70px;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .main-content-wrap
    .top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin: 0 0 28px;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .main-content-wrap
    .top-wrap
    .user-wrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .main-content-wrap
    .top-wrap
    .user-wrap
    .user-img {
    display: flex;
    width: 46px;
    height: 46px;
    overflow: hidden;
    border-radius: 100%;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .main-content-wrap
    .top-wrap
    .star {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .main-content-wrap
    .product-info {
    position: absolute;
    bottom: 25px;
  }
  .market-buyers-main .main-slider-wrap .cust-slider .slider-card-item:hover {
    background: #9b9e51;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .main-content-wrap
    .top-wrap
    .star {
    color: #fff;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .main-content-wrap
    .top-wrap
    .star
    svg {
    fill: #fff;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .main-content-wrap
    .top-wrap
    .user-wrap {
    color: #fff;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .main-content-wrap
    .top-wrap
    .user-wrap
    .user-info
    .heading-400-14-12 {
    color: #fff;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .main-content-wrap
    .desc {
    color: #fff;
  }
  .market-buyers-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .main-content-wrap
    .product-info {
    color: #fff;
  }
  
  /* Similar Machines */
  .similar-machines-main .col-cust .similar-machines-inner .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin: 0 0 60px;
  }
  .similar-machines-main
    .col-cust
    .similar-machines-inner
    .head
    .slider-button-wrap {
    display: none;
  }
  .similar-machines-main .col-cust .similar-machines-inner .head .box-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .similar-machines-main .main-slider-wrap {
    margin: 0 0 100px;
  }
  .similar-machines-main
    .col-cust
    .similar-machines-inner
    .head
    .box-btn
    .arrow-icon {
    width: 42px;
    height: 42px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #211e24;
    border-radius: 100%;
    background: none;
  }
  .similar-machines-main .main-slider-wrap .cust-slider .slick-list .slick-track {
    display: flex;
    column-gap: 20px;
    padding: 10px 0;
  }
  .similar-machines-main .main-slider-wrap .cust-slider .slider-card-item {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    transition: 0.2s;
    overflow: hidden;
    border-radius: 12px;
  }
  .similar-machines-main .main-slider-wrap .cust-slider .slider-card-item .heart {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    padding: 0;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .prodcut-img-wrap {
    height: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 0;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .prodcut-img-wrap
    .product-img {
    max-width: 100%;
    width: 300px;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .contents-wrap {
    padding: 25px;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .contents-wrap
    .product-name {
    font-size: 20px;
    position: relative;
    padding: 0 0 5px;
    margin: 0 0 15px;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .contents-wrap
    .product-name:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #000;
    width: 48px;
    bottom: 0;
    left: 0;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .contents-wrap
    .bottom-wrap {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: space-between;
    margin: 15px 0 0;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item
    .contents-wrap
    .avail-btn {
    height: 48px;
    padding: 0 30px;
    background: #73509e;
    color: #fff;
    border-radius: 12px;
    border: none;
    margin: 15px 0 0;
    display: none;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .contents-wrap
    .avail-btn {
    display: block;
  }
  .similar-machines-main .main-slider-wrap .cust-slider .slider-card-item:hover {
    background: #9b9e51;
  }
  /* .similar-machines-main .main-slider-wrap .cust-slider .slider-card-item:hover .heart svg{stroke:#fff;} */
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .contents-wrap
    .product-name {
    color: #fff;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .contents-wrap
    .light-txt {
    color: #fff;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .contents-wrap
    .product-name:after {
    background: #fff;
  }
  .similar-machines-main
    .main-slider-wrap
    .cust-slider
    .slider-card-item:hover
    .contents-wrap {
    color: #fff;
  }
  
  /* Speak to expert */
  .speak-expert-wrap-main {
    display: flex;
    align-items: center;
    column-gap: 100px;
    margin: 0 0 100px;
  }
  .speak-expert-wrap-main .image-wrap {
    flex: 1;
  }
  .speak-expert-wrap-main .image-wrap img {
    width: 100%;
  }
  .speak-expert-wrap-main .speak-content-wrap {
    flex: 1;
  }
  .speak-expert-wrap-main .speak-content-wrap .box-item-btn {
    height: 48px;
    background: #73509e;
    color: #fff;
    border-radius: 12px;
    border: none;
    width: max-content;
    width: 170px;
    margin: 60px 0 0;
  }
  
  /* Call to action */
  .call-to-action-wrap-main {
    z-index: 10;
    background: #fff;
    position: sticky;
    width: 100%;
    bottom: 0;
    box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
  }
  .call-to-action-main {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
  }
  .call-to-action-main .btns-wrap {
    display: flex;
    column-gap: 12px;
  }
  .call-to-action-main .btns-wrap .buy-now {
    background: none !important;
    border: 1px solid #73509e !important;
    color: #73509e !important;
  }
  .call-to-action-main .btns-wrap .box-item-btn {
    height: 48px;
    background: #73509e;
    color: #fff;
    border-radius: 12px;
    border: none;
    padding: 0 30px;
  }
  .market-buyers-main {
    overflow: hidden;
  }
  .plr25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 1199px) {
    .market-buyers-main
      .main-slider-wrap
      .cust-slider
      .slider-card-item
      .product-img {
      height: 250px;
    }
    .market-buyers-main
      .main-slider-wrap
      .cust-slider
      .slider-card-item
      .main-content-wrap {
      padding: 20px 15px 70px;
    }
    .market-buyers-main
      .main-slider-wrap
      .cust-slider
      .slider-card-item
      .main-content-wrap
      .top-wrap
      .user-wrap {
      column-gap: 8px;
    }
  }
  
  @media (max-width: 1024px) {
    .box-wrap-main {
      gap: 15px;
    }
    .box-wrap-main .box-inner {
      gap: 15px;
    }
    .box-wrap-main .box-inner .box-item {
      padding: 22px 16px;
    }
  }
  
  @media (max-width: 991px) {
    .box-wrap-main {
      flex-direction: column;
    }
    .finance-wrap-main .max-container .body .right .inner {
      margin-left: 40px;
      column-gap: 30px;
      row-gap: 40px;
    }
    .finance-wrap-main .max-container .body .right .inner .item .icon {
      width: 42px;
      height: 42px;
      margin: 0 0 10px;
    }
    .finance-wrap-main .max-container .body .right .inner .item .icon svg {
      width: 20px;
      height: 20px;
    }
    .finance-wrap-main .max-container .body .right .inner .item .icon-txt {
      font-size: 14px;
    }
    .call-to-action-main {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    .call-to-action-main .text-contain {
      width: 50%;
    }
    .call-to-action-main .btns-wrap .box-item-btn {
      padding: 0 20px;
      font-size: 14px;
    }
    .call-to-action-main .btns-wrap {
      margin: 0 auto;
    }
  
    .benefits-main .benefits-content {
      margin-bottom: 90px;
    }
    .benefits-content .benefits-content-head {
      position: relative;
      display: flex;
      justify-content: center;
    }
    .benefits-content .benefits-content-head .slider-button-wrap {
      display: flex;
      align-items: center;
      column-gap: 30px;
      position: absolute;
      top: 320px;
    }
    .benefits-content .benefits-content-head .slider-button-wrap .slider-button {
      width: 42px;
      height: 42px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #211e24;
      border-radius: 100%;
      background: none;
    }
  }
  
  @media (max-width: 767px) {
    /* Breadcrumbs css */
    .top-wrap-main {
      flex-direction: column;
      row-gap: 20px;
    }
    .top-wrap-main .form-group {
      align-self: flex-end;
    }
    .top-wrap-main .form-group input {
      height: 40px;
      font-size: 14px;
    }
    .top-wrap-main .form-group svg {
      top: 8px;
    }
  
    /* Header css */
    .name-wrap-main {
      padding: 15px;
      display: flex;
      column-gap: 10px;
    }
    .name-wrap-main .heading-wrap .heading-600-24 {
      font-size: 12px;
      line-height: 20px;
    }
    .name-wrap-main .people {
      column-gap: 10px;
    }
    .name-wrap-main .people svg {
      height: 20px;
      width: 20px;
    }
  
    .product-img-wrap-main .product {
      padding: 30px;
    }
    .product-img-wrap-main .product .drag {
      width: 80px;
      height: 80px;
      font-size: 12px;
    }
    .product-img-wrap-main .product .framre {
      width: 38px;
      height: 38px;
    }
    .product-img-wrap-main .product .framre svg {
      width: 20px;
      height: 20px;
    }
    .box-wrap-main .box-inner .box-item .inner {
      flex-direction: column;
      row-gap: 20px;
    }
    .box-wrap-main .box-inner .box-item .box-btn {
      align-self: flex-end;
    }
    .box-wrap-main .box-inner .box-item .heading-400-14-12 {
      align-self: flex-start;
    }
    .box-wrap-main .box-inner .box-item .items-wrap .item .heading-500-16 {
      font-size: 12px;
    }
    .box-wrap-main .box-inner .box-item .box-item-btn {
      height: 38px;
      width: 140px;
      margin: 20px 0 0;
      font-size: 14px;
    }
  
    .finance-head .heading-600-32 {
      font-size: 24px;
      margin: 0 0 10px;
    }
    .finance-wrap-main {
      background: none;
    }
    .finance-wrap-main .max-container .body {
      flex-direction: column;
      row-gap: 30px;
      margin-top: -40px;
    }
    .finance-wrap-main .max-container .body .left {
      width: 100%;
      padding: 30px 20px 0;
    }
    .finance-wrap-main .max-container .body .left .box-item-btn {
      height: 38px;
      width: 160px;
      margin: 20px 0 0;
      font-size: 14px;
    }
    .finance-wrap-main .max-container .body .right {
      width: 100%;
      background: #f5f5f5;
    }
    .finance-wrap-main .max-container .body .right .inner .item .icon-txt {
      max-width: 100%;
    }
  
    .market-buyers-main .col-cust .market-buyers-inner .head {
      margin: 0 0 20px;
      position: relative;
      display: flex;
      justify-content: center;
    }
    .market-buyers-main .col-cust .market-buyers-inner .head .heading-wrap {
      width: 100%;
    }
    .market-buyers-main
      .col-cust
      .market-buyers-inner
      .head
      .heading-wrap
      .heading-600-32 {
      font-size: 24px;
    }
    .market-buyers-main .col-cust .market-buyers-inner .head .slider-button-wrap {
      position: absolute;
      top: 650px;
    }
  
    .call-to-action-main .btns-wrap .box-item-btn {
      padding: 0 16px;
      font-size: 13px;
      height: 45px;
    }
    .call-to-action-main div .heading-600-24,
    .light-txt {
      font-size: 14px;
    }
  
    .benefits-main .head {
      flex-direction: column;
      row-gap: 20px;
    }
    .similar-machines-main {
      min-height: 850px;
    }
    .similar-machines-main .col-cust .similar-machines-inner .head {
      flex-direction: column;
      row-gap: 20px;
      align-items: center;
      margin: 0 0 30px;
    }
    .similar-machines-main .col-cust .similar-machines-inner .head .heading-wrap {
      width: 100%;
    }
    .similar-machines-main
      .col-cust
      .similar-machines-inner
      .head
      .slider-button-wrap {
      margin: 0 auto;
      display: flex;
      align-items: center;
      column-gap: 30px;
      position: absolute;
      top: 740px;
    }
    .similar-machines-main
      .col-cust
      .similar-machines-inner
      .head
      .slider-button-wrap
      .slider-button {
      width: 42px;
      height: 42px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #211e24;
      border-radius: 100%;
      background: none;
    }
    .market-buyers-main .main-slider-wrap {
      /* margin: 0 auto 40px;
          width: 100%;
          max-width: 430px; */
    }
  
    .similar-machines-main .col-cust .similar-machines-inner .head .box-btn {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin: 0 auto;
    }
    .box-wrap-main .box-inner .box-item .inner-y-purchase .y-purchase-div {
      display: block;
    }
    .box-wrap-main
      .box-inner
      .box-item
      .inner-y-purchase
      .y-purchase-div
      .y-purchase-text {
      display: block;
    }
  }
  
  @media (max-width: 479px) {
    .finance-head .heading-600-32 {
      font-size: 20px;
    }
    .finance-wrap-main .max-container .body .right .inner {
      margin: 0;
      padding: 0 20px;
    }
    .finance-wrap-main .max-container .body .right .inner .item {
      width: calc(50% - 15px);
    }
    .market-buyers-main
      .col-cust
      .market-buyers-inner
      .head
      .heading-wrap
      .heading-600-32 {
      font-size: 20px;
    }
    .benefits-main .benefits-content .item .title .svg-wrap span {
      width: 222px;
    }
  }
  @media (max-width: 410px) {
    .market-buyers-main .col-cust .market-buyers-inner .head .slider-button-wrap {
      position: absolute;
      top: 680px;
    }
  }
  