/**********************************Specific orders - Desktop mode - MyOrderSummaryRevised**********************************/
.custom-marginccc {
    margin-left: 110px;
    margin-right: 110px;
  }
  
  /******************************************************Order History************************************/
  
  .custom-margin {
    margin-left: 150px;
    margin-right: 150px;
  }
  
  /*All orders - OrderHistory*/
  .individual-order-details-div {
    margin: 0 auto;
    font-size: 14px;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  .topof-individual-order-details-div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid rgb(203, 203, 203);
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 16px;
  }
  
  .leftside-of-top-viewindividual-order-details-div {
    text-align: left;
    gap: 50px;
  }
  .headingfororders {
    font-size: 14px;
    color: #686969;
  }
  
  .textfororders {
    font-weight: 500;
  }
  
  .rightside-of-top-viewindividual-order-details-div {
    text-align: right;
  }
  
  .d555dddsvbf {
    padding: 10px;
  }
  
  /* .View-Invoice {
    color: #73509e;
    font-weight: 500;
  }
  
  .View-Invoice:hover {
    color: #73509e;
  } */
  /* .bottomof-bottom-individual-order-details-div {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
  }
  .leftside-of-bottom-individual-order-details-div {
    flex-shrink: 0;
    max-width: 150px;
  }
  
  .leftside-of-bottom-individual-order-details-div {
    
  }
  
  .leftside-of-bottom-individual-order-details-divimg {
    border: 1px solid rgb(253, 253, 253);
    object-fit: cover;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
  }
  
  .rightside-of-bottom-individual-order-details-div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  .textnexttotheimage-of-bottom-individual-order-details-div {
    flex: 1;
    min-width: 200px;
    max-width: 60%;
  }
  
  .textontheimagepara {
    font-size: 16px;
  }
  .textnexttotheimage-of-bottom-individual-order-details-div p {
    margin: 0;
  } */
  
  /*buttonsdivthirdpart*/
  .rightside-second-div-of-bottom-individual-order-details-div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .button-view-invoice {
    border: 1px solid rgb(255, 255, 255);
    background-color: #73509e;
    color: white;
  }
  .button-view-invoice:hover {
    background-color: #73509e;
    color: white;
  }
  
  .button-view-order-details {
    border: 1px solid rgb(203, 203, 203);
  }
  
  .button-standard {
    display: inline-block;
    padding: 10px 20px;
    min-width: 150px;
    text-align: center;
    font-size: 14px;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .order-statusofbo {
    color: green;
    font-size: 15px;
    font-weight: 600;
  }
  
  /******************************************************************************Stepper**********************************************************************************/
  
  /*React Stepper Example*/
  .bottompartof-individual-order-details-div {
    padding: 10px;
  }
  
  .stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex-grow: 1;
  }
  
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 2px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
  }
  
  .step-label {
    font-size: 0.9rem;
    color: #333;
    margin-top: 10px;
    text-align: center;
  }
  
  .line {
    display: none;
    position: absolute;
    width: 75%;
    top: 30%;
    left: 13%;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .line-stepperLogic1 {
    position: absolute;
    width: 85%;
    top: 30%;
    left: 8%;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .line-stepperLogic2 {
    position: absolute;
    width: 82%;
    top: 30%;
    left: 10%;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .line-stepperLogic3 {
    position: absolute;
    width: 78%;
    top: 30%;
    left: 12%;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .line-stepperLogic4 {
    position: absolute;
    width: 75%;
    top: 30%;
    left: 13%;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .step-active .icon-wrapper {
    background-color: green;
    border-color: green;
  }
  
  .step-active .step-label {
    color: green;
  }
  
  .step-inactive .icon-wrapper {
    background-color: #f5f5f5;
    border-color: #ccc;
  }
  
  .step-inactive .step-label {
    color: #555;
  }
  
  .step-active .iconffff-active {
    color: white;
  }
  
  .stepper-containedddr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .step-coeentainer {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .step-text {
    top: 40px;
    left: -10px;
    font-size: 14px;
    position: absolute;
  }
  .icon-containeeeer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
  }
  
  .connectorhere {
    width: 100px;
    height: 2px;
    background-color: rgb(203, 203, 203);
  }
  
  .connectorhere.active {
    background-color: green;
  }
  
  .icon-containeeeer.active {
    color: rgb(255, 255, 255) !important;
  }
  
  .icon-containeeeer.not-active {
    background-color: rgb(205, 205, 205) !important;
  }
  
  .iconinverticalstepper {
    border-radius: 50%;
    padding: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .css-14sza3e-MuiStepLabel-root {
    padding: 0px !important;
  }
  
  .vertical-lineStepper-all {
    font-family: Poppins !important;
  }
  
  .css-1hv8oq8-MuiStepLabel-label {
    font-family: Poppins !important;
  }
  .css-ahj2mt-MuiTypography-root {
    font-family: Poppins !important;
    font-size: 0.8rem !important;
  }
  /*Normal Line Connector*/
  .css-8t49rw-MuiStepConnector-line {
    border-color: rgb(203, 203, 203) !important;
  }
  
  /*line connector when clicked on it*/
  .css-14yr603-MuiStepContent-root {
  }
  
  /*********************************************************************Responsive Media Queries***************************************************************************/
  
  @media (max-width: 568px) {
    .imagedivfirstpart {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .namedivsecondpart {
      width: 100% !important;
    }
  
    .buttonsdivthirdpart {
      width: 100% !important;
    }
  
    .order-img-1 {
      max-width: 500px !important;
      height: 180px !important;
    }
  
    .rightside-second-div-of-bottom-individual-order-details-div {
      flex-direction: row !important;
    }
    .topof-individual-order-details-div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }
  
    .leftside-of-top-viewindividual-order-details-div,
    .rightside-of-top-viewindividual-order-details-div {
      width: 100%;
    }
  
    .topof-individual-order-details-div div {
      display: flex;
      flex-direction: column;
    }
  
    .topof-individual-order-details-div .headingfororders {
      font-weight: bold;
    }
  
    .topof-individual-order-details-div .textfororders {
      margin-top: 4px;
    }
    .leftside-of-top-viewindividual-order-details-div {
      gap: 10px !important
      ;
    }
  
    .rightside-of-top-viewindividual-order-details-div {
      gap: 10px !important;
    }
  }
  @media screen and (min-width: 569px) and (max-width: 622px) {
    .imagedivfirstpart {
      width: 20% !important;
    }
  
    .namedivsecondpart {
      width: 40% !important;
    }
    .buttonsdivthirdpart {
      width: 20% !important;
    }
    .order-img-1 {
      max-width: 180px !important;
      height: 90px !important;
    }
  }
  @media screen and (min-width: 623px) and (max-width: 768px) {
    .imagedivfirstpart {
      width: 25% !important;
    }
  
    .namedivsecondpart {
      width: 40% !important;
    }
    .buttonsdivthirdpart {
      width: 20% !important;
    }
    .order-img-1 {
      max-width: 200px !important;
      height: 100px !important;
    }
  }
  
  @media (max-width: 767px) {
    .custom-margin {
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .individual-order-details-div {
      font-size: 12px;
      margin-bottom: 15px;
      /* padding: 15px; */
    }
  
    .topof-individual-order-details-div {
      flex-direction: column;
      padding: 10px;
    }
  
    .leftside-of-top-viewindividual-order-details-div {
      text-align: left;
      gap: 20px;
    }
  
    .rightside-of-top-viewindividual-order-details-div {
      text-align: left;
      margin-top: 10px;
    }
  
    .headingfororders {
      font-size: 12px;
    }
  
    .textfororders {
      font-size: 12px;
    }
  
    .bottomof-individual-order-details-div {
      padding: 10px;
    }
  
    .d555dddsvbf {
      flex-direction: column;
      padding: 0;
      gap: 10px;
    }
  
    .order-img-1 {
      max-width: 80px;
      height: 80px;
    }
  
    .col-6 {
      text-align: left;
    }
  
    .button-standard {
      min-width: 120px;
      padding: 8px 16px;
      font-size: 12px;
    }
  
    .rightside-second-div-of-bottom-individual-order-details-div {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .button-view-invoice,
    .button-view-order-details {
      min-width: 120px;
      padding: 8px 16px;
      font-size: 12px;
    }
  
    .textfororders {
      font-size: 12px;
    }
  
    .order-statusofbo {
      font-size: 13px;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .imagedivfirstpart {
      width: 30% !important;
    }
  
    .namedivsecondpart {
      width: 30% !important;
    }
    .buttonsdivthirdpart {
      width: 30% !important;
    }
    .order-img-1 {
      max-width: 250px !important;
      /* height: 100px; */
    }
  }
  
  @media (max-width: 1024px) {
    .custom-margin {
      margin-left: 20px;
      margin-right: 20px;
    }
  
    .individual-order-details-div {
      font-size: 13px;
    }
  
    .topof-individual-order-details-div {
      flex-direction: row;
      padding: 12px;
    }
  
    .leftside-of-top-viewindividual-order-details-div {
      gap: 30px;
    }
  
    .rightside-of-top-viewindividual-order-details-div {
      margin-top: 0;
    }
  
    .d555dddsvbf {
      flex-direction: row;
      gap: 15px;
    }
  
    .order-img-1 {
      max-width: 100px;
      /* height: 100px; */
    }
  
    .button-standard {
      /* min-width: 140px; */
      padding: 10px 18px;
      font-size: 13px;
    }
  
    .rightside-second-div-of-bottom-individual-order-details-div {
      flex-direction: column;
      /* align-items: center; */
      gap: 12px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1053px) {
    .imagedivfirstpart {
      width: 30% !important;
    }
  
    .namedivsecondpart {
      width: 40% !important;
    }
    .buttonsdivthirdpart {
      width: 30% !important;
    }
    .order-img-1 {
      max-width: 150px !important;
      /* height: 100px; */
    }
  }
  
  @media screen and (min-width: 1054px) and (max-width: 1222px) {
    .imagedivfirstpart {
      width: 30% !important;
    }
  
    .namedivsecondpart {
      width: 40% !important;
    }
    .buttonsdivthirdpart {
      width: 30% !important;
    }
    .order-img-1 {
      max-width: 200px !important;
      /* height: 100px; */
    }
  }
  
  @media (min-width: 1223px) {
    .imagedivfirstpart {
      width: 30% !important;
    }
  
    .namedivsecondpart {
      width: 50% !important;
    }
    .buttonsdivthirdpart {
      width: 20% !important;
    }
  }
  
  /* 
  
  @media screen and (min-width:850px) and (max-width:940px) {
    
  }
  
  @media screen and (min-width:941px) and (max-width:1000px) {
    
  }
  
  @media screen and (min-width:1160px) and (max-width:1460px) {
    
  }
  
  @media screen and (min-width:1525px) and (max-width:1799px) {
   
    .css-8t49rw-MuiStepConnector-line{
      margin-left: 1.5% !important;
      border-left-width:3px !important;
      border-color: rgb(203, 203, 203) !important;
      }
      
    
      .css-14yr603-MuiStepContent-root{
      margin-left:18px !important;
      border-left:3px solid rgb(203, 203, 203) !important;
      }
  }
  @media screen and (min-width:1800px) and (max-width:2000px) {
    
    
    .css-8t49rw-MuiStepConnector-line{
      margin-left: 1.5% !important;
      border-left-width:3px !important;
      border-color: rgb(203, 203, 203) !important;
      }
      
          .css-14yr603-MuiStepContent-root{
      margin-left:18px !important;
      border-left:3px solid rgb(203, 203, 203) !important;
      }
  }
  
  @media screen and (min-width:2001px) and (max-width:2300px) {
  
  
   .css-8t49rw-MuiStepConnector-line{
    margin-left: 1.2% !important;
    border-left-width:3px !important;
    border-color: rgb(203, 203, 203) !important;
    }
    
  
    .css-14yr603-MuiStepContent-root{
    margin-left:16.5px !important;
    border-left:3px solid rgb(203, 203, 203) !important;
    }
  
  } */
  