/* .buttonviewall{
  background-color: #73509e;
  color: white;
  padding: 10px 10px;
  border-radius: 8px;
  width: 12%;
  border: none;


margin-left: 86%;
}

.ljgrlbjrsf{
  border: 2px solid red;
}

#ResetBtn{
  padding: 2px 5px;
  font-size: 14px;
  width: 30%;
  margin-left: 75%;
border: rgb(214, 207, 207) 1px solid;
  border-radius: 8px;
   
}

.mobile-filter-wrap {
  display: none;
}
.tab-wrap {
  display: flex;
  margin-top: 20px;
}
.tab-wrap .btn:focus {
  box-shadow: none;
  outline: none;
}
.tab-wrap .btn {
  font-size: 16px;
  color: #7a7c7f;
  border-radius: 0;
  padding: 0 10px;
  height: 48px;
}
.tab-wrap .active {
  color: #211e24;
  border-bottom: 3px solid #9da14e;
}
.head-wrap {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head-wrap .heading-wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  align-self: flex-start;
}
.head-wrap .heading-wrap .sub-heading {
  color: #7a7c7f;
}
.head-wrap .heading-wrap .heading {
  color: #211e24;
}
.head-wrap .btn-wrap {
  display: flex;
  column-gap: 15px;
}
.head-wrap .btn-wrap .btn {
  height: 54px;
  background: #73509e;
  color: #fff;
  border-radius: 12px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
}
.head-wrap .btn-wrap .btn.location {
  padding: 0 42px;
  box-sizing: border-box;
}
.head-wrap .btn-wrap .short-wrap {
  position: relative;
}
.head-wrap .btn-wrap .short-wrap .short-btn {
  background: none;
  border: 2px solid #73509e;
  color: #73509e;
  position: relative;
}
.head-wrap .btn-wrap .short-wrap .shorts {
  position: absolute;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  overflow: hidden;
  width: 310px;
  top: 60px;
  right: 0;
  z-index: 1;
}
.head-wrap .btn-wrap .short-wrap .shorts .item {
  font-size: 14px;
  color: #211e24;
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}
.head-wrap .btn-wrap .short-wrap .shorts .item:hover {
  background: #9b9e51;
  color: #fff;
}
.head-wrap .btn-wrap .filter-btn {
  display: none;
}


.Mycodecss{
  border: 1px solid #73509e;
  padding: 10px 10px;
  border-radius: 8px;
}
.seperatingcodecss{

  display: flex;
  justify-content: space-between;
}
.filtericonclicked .rotate {
  transition: transform 0.3s ease;
}

.filtericonclicked .rotate.open {
  transform: rotate(180deg);
}

.filtericonclicked{
  cursor: pointer;
}
.filter-wrap {
  flex: 0 0 300px;
 
  border-radius: 8px;
  overflow: hidden;
  padding: 24px 16px;
  display: flex; 
  flex-direction: column;
  row-gap: 2px;
  height: max-content;
}



.filter-wrap::-webkit-scrollbar {
  width: 5px; 

}

.filter-wrap::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px; 
}

.filter-wrap::-webkit-scrollbar-thumb {
  background-color: rgb(210, 204, 204);
  border-radius: 5px; 
  height: 5px; 
  position: relative;
}

.filter-wrap::-webkit-scrollbar-thumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px; 
  background-color: #a98ccd;
  transform: translateY(-50%);
  border-radius: 5px;
}




.filter-body::-webkit-scrollbar {
  width: 5px; 
}

.filter-body::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px; 
}

.filter-body::-webkit-scrollbar-thumb {
  background-color: #cfbde4;
  border-radius: 5px; 
  height: 5px; 
}

.filter-body::-webkit-scrollbar-thumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px; 
  background-color: #a98ccd;
  transform: translateY(-50%);
  border-radius: 5px;
}


.scrollwrapped {
  
    height: 500px;
    overflow-y: auto; 
    padding: 10px; 
    border-radius: 5px; 
 
}

.scrollwrapped::-webkit-scrollbar {
    width: 5px; 
}

.scrollwrapped::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px; 
}

.scrollwrapped::-webkit-scrollbar-thumb {
    background-color: #cfbde4;
    border-radius: 5px; 
    height: 5px; 
    position: relative;
}

.scrollwrapped::-webkit-scrollbar-thumb::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 20px; 
    background-color: #a98ccd;
    transform: translateY(-50%);
    border-radius: 5px;
}



.filter-wrap .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-wrap .head .heading {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.filter-wrap .head .clear {
  cursor: pointer;
}
.filter-wrap .filter {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-bottom: 15px;
}
.filter-wrap .filter:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.filter-wrap .filter .head {
  cursor: pointer;
}
.filter-wrap .filter .head svg.open {
  rotate: 180deg;
}
.filter-wrap .filter .filter-body {
  padding-left: 8px;
}
.filter-wrap .filter .filter-body .checkbox-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.filter-wrap .filter .filter-body .checkbox-wrap .checkbox-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-wrap .filter .filter-body .checkbox-wrap .checkbox-item label {
  cursor: pointer;
  display: contents;
  margin: 0;
}
.filter-wrap .filter .filter-body .checkbox-wrap .checkbox-item input {
  display: flex;
  margin: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #73509e;
}

.filter-body{
  animation: fadeIn 0.3s ease-in-out;
}


.col-cust {
  position: relative;
}
.layout-wrap {
  margin: 60px 0 0;
}
.layout-wrap .product-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  margin-top: 30px;
}
.layout-wrap .product-wrap .products {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: 80px;
}
.layout-wrap .product-wrap .product {
  width: 33.33%;
  min-height: 420px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  box-sizing: border-box;
  border-right: 1px solid #73509e1a;
}
.layout-wrap .product-wrap .product .product-inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 25px;
}
.layout-wrap .product-wrap .product:nth-child(3n + 3) {
  border-right: none;
}
.layout-wrap .product-wrap .product .product-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  display: flex;
  align-self: center;
  padding: 0px 0px;
}
.layout-wrap .product-wrap .product .content {
  margin: 0;
}
.layout-wrap .product-wrap .product .content .name {
  font-size: 16px;
  color: #211e24;
  margin: 0 0 10px;
}
.layout-wrap .product-wrap .product .content .name:after {
  content: "";
  width: 32%;
  height: 2px;
  background: #211e24;
  display: flex;
  margin-top: 4px;
}
.layout-wrap .product-wrap .product .content .location {
  font-size: 14px;
  color: #7a7c7f;
  margin: 0 0 5px;
}
.layout-wrap .product-wrap .product .content .price-wrap {
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: space-between;
  color: #211e24;
  margin: 0 0 10px;
}
.layout-wrap .product-wrap .product .content .price-wrap .time {
  font-size: 16px;
}
.layout-wrap .product-wrap .product .content .price-wrap .price {
  font-size: 18px;
}
.layout-wrap .product-wrap .product .heart {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  padding: 0;
}
.layout-wrap .product-wrap .product .heart svg {
  stroke: #211e24;
  fill: transparent;
}
.layout-wrap .product-wrap .product .heart.favorite svg {
  fill: #73509e;
  stroke: #73509e;
}
.layout-wrap .product-wrap .product .purchase {
  font-size: 16px;
  background: #73509e;
  color: #fff;
  height: 54px;
  border: none;
  padding: 0 30px;
  border-radius: 12px;
  transition: 0.3s;
}
.layout-wrap .product-wrap .product .purchasef {
    font-size: 16px;
    color: #fff;
    height: 54px;
    border: none;
    padding: 0 18px;
    border-radius: 12px;
    transition: 0.3s;
    white-space: nowrap;
  }
.pbtnsb{

    background: #73509e;
}
.pbtnsb1{

    background-color: #9ba24c;
}
.buttonsinoneline{
    display: flex;
    gap: 10px;
}
.layout-wrap .product-wrap .product:hover .product-inner {
  background: #fff;
  padding: 20px 25px;
  min-height: 450px;
}
.layout-wrap .product-wrap .product:hover .content .name {
  color: #211e24;
}
.layout-wrap .product-wrap .product:hover .content .name:after {
  background: #211e24;
}
.layout-wrap .product-wrap .product:hover .content .location {
  color: #211e24;
}
.layout-wrap .product-wrap .product:hover .content .price-wrap {
  color: #211e24;
}
.layout-wrap .product-wrap .product:hover .heart svg {
  stroke: #211e24;
}
.layout-wrap .product-wrap .product:hover .heart.favorite svg {
  fill: #73509e;
  stroke: #73509e;
}
.layout-wrap .product-wrap .product:hover .purchase {
  display: block;
}
.layout-wrap .product-wrap .product:hover .purchasef {
    display: block;
  }
.bot-icon-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
}
.bot-icon-wrap .bot-icon {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #9b9e51;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .layout-wrap .product-wrap .product {
    padding: 20px;
    width: calc(50% - 7px);
  }
}
@media (max-width: 991px) {
  .filter-wrap {
    flex: 0 0 250px;
  }
}
@media (max-width: 767px) {
  .mobile-filter-wrap {
    display: block;
  }
  .filter-wrap {
    display: none;
  }
  .layout-wrap {
    margin: 30px 0 0;
  }
  .layout-wrap .product-wrap .product {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
  }
  .tab-wrap {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
  }
  .head-wrap {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
  }
  .head-wrap .btn-wrap {
    column-gap: 10px;
  }
  .head-wrap .btn-wrap .btn {
    height: 45px;
    padding: 0 16px;
    font-size: 14px;
  }
  .head-wrap .btn-wrap .btn.location {
    font-size: 12px;
    padding: 0 34px;
  }
  .head-wrap .btn-wrap .short-wrap .short-btn span {
    display: none;
  }
  .head-wrap .btn-wrap .filter-btn {
    display: block;
    background: #fff;
    border: 2px solid #73509e;
  }

 
}

.text-responsive {
  font-size: 1.15rem; 
  font-weight: 600;
}

@media (max-width: 576px) {
  .text-responsive {
    font-size: 0.8rem; 
  }
}
 */




 

/*----------------------------------------------------Main Layout of--------------------------------------------*/
.row.layout-wrap {
  display: flex;
  flex-wrap: wrap;
}
.filtersectioncse {
  position: sticky !important;
  top: 205px;
  height: 100vh;
}
.productcardsectioncse {
  flex-grow: 1;
}
/*-----------------------------------------This is for used in CombinedHits.jsx----------------------------------*/
.buttonviewall {
  background-color: #73509e;
  color: white;
  padding: 10px 10px;
  border-radius: 8px;
  width: 12%;
  border: none;
  margin-left: 86%;
}
/*-----------------------------------------BreadCrumbs , Grid , Sort---------------------------------------------*/
.Breadcrumbsanddeliverlocation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-location-wrapper {
  display: none;
}

.breadcrumbsanddeliverylocationsort {
  display: none;
}

.gridlines {
  border: 1px solid #73509e;
  color: #7a7c7f;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
}

.fliters-delivery-grid {
  gap: 10px;
}
.filters-delivery-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.individual-div-filters-delivery-grid {
  margin-right: 2px;
}

.sortbutton {
  margin-left: 2px;
}

.delveryloactionn {
  align-items: center;
  background: #73509e;
  border-radius: 12px;
  color: #fff;
  column-gap: 10px;
  border: none;
  display: flex;
  font-weight: 600;
  height: 54px;
  padding: 0 30px;
}

.sortforstorejsx {
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  color: #73509e;
  column-gap: 10px;
  border: 2px solid #73509e;
  display: flex;
  font-weight: 600;
  height: 54px;
  padding: 0 30px;
}

.shorts {
  position: absolute;
  right: 1px;
  top: 60px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: 310px;
  z-index: 10;
}

.shorts .item {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  color: #333;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.shorts .item:last-child {
  border-bottom: none;
}

.shorts .item:hover {
  background-color: rgb(153, 158, 81);
  color: #ffffff;
}

/*---------------------------------------------Alice Carousel banner----------------------------------------------*/
.custom-image {
  height: 180px;
  width: 95%;
  /* object-fit: cover; */
  border-radius: 8px;
}
@media (min-width:569){
  .custom-image {
    height: 220px;
    width: 95%;
    /* object-fit: cover; */
    border-radius: 8px;
  }
}

/*---------------------------------------------Filter Section----------------------------------------------------*/

#ResetBtn {
  padding: 2px 5px;
  font-size: 14px;

  border: rgb(214, 207, 207) 1px solid;
  border-radius: 8px;
}



.mobile-filter-wrap {
  display: none;
}
.tab-wrap {
  display: flex;
  margin-top: 20px;
}
.tab-wrap .btn:focus {
  box-shadow: none;
  outline: none;
}
.tab-wrap .btn {
  font-size: 16px;
  color: #7a7c7f;
  border-radius: 0;
  padding: 0 10px;
  height: 48px;
}
.tab-wrap .active {
  color: #211e24;
  border-bottom: 3px solid #9da14e;
}
.head-wrap {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head-wrap .heading-wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  align-self: flex-start;
}
.head-wrap .heading-wrap .sub-heading {
  color: #7a7c7f;
}
.head-wrap .heading-wrap .heading {
  color: #211e24;
}
.head-wrap .btn-wrap {
  display: flex;
  column-gap: 15px;
}
.head-wrap .btn-wrap .btn {
  height: 54px;
  background: #73509e;
  color: #fff;
  border-radius: 12px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
}
.head-wrap .btn-wrap .btn.location {
  padding: 0 42px;
  box-sizing: border-box;
}
.head-wrap .btn-wrap .short-wrap {
  position: relative;
}
.head-wrap .btn-wrap .short-wrap .short-btn {
  background: none;
  border: 2px solid #73509e;
  color: #73509e;
  position: relative;
}
.head-wrap .btn-wrap .short-wrap .shorts {
  position: absolute;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  overflow: hidden;
  width: 310px;
  top: 60px;
  right: 0;
  z-index: 1;
}
.head-wrap .btn-wrap .short-wrap .shorts .item {
  font-size: 14px;
  color: #211e24;
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}
.head-wrap .btn-wrap .short-wrap .shorts .item:hover {
  background: #9b9e51;
  color: #fff;
}
.head-wrap .btn-wrap .filter-btn {
  display: none;
}

.Mycodecss {
  border: 1px solid #73509e;
  padding: 10px 10px;
  border-radius: 8px;
}
.seperatingcodecss {
  display: flex;
  justify-content: space-between;
}
.filtericonclicked .rotate {
  transition: transform 0.3s ease;
}

.filtericonclicked .rotate.open {
  transform: rotate(180deg);
}

.filtericonclicked {
  cursor: pointer;
}
.filter-wrap {
  flex: 0 0 300px;
  /* box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12); */
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  height: max-content;
}

.scrollable {
  max-height: 600px;
  overflow-y: auto;
}

.filter-wrap {
  /* border: 1px solid rgb(223, 223, 223); */
  border-radius: 10px;
  box-shadow: #fff;
  padding: 5px;
}

.filter-wrap::-webkit-scrollbar {
  width: 5px;
}


@media(max-width:1320px){
  
.filter-wrap .filter .filter-body{
  padding-left:0px !important;
  }
  
  .filtersectioncse{
  padding:0  !important;
  }
}
.filter-wrap::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

.filter-wrap::-webkit-scrollbar-thumb {
  background-color: rgb(210, 204, 204);
  border-radius: 5px;
  height: 5px;
  position: relative;
}

.filter-wrap::-webkit-scrollbar-thumb::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #a98ccd;
  transform: translateY(-50%);
  border-radius: 5px;
}

.filter-body::-webkit-scrollbar {
  width: 5px;
}

.filter-body::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

.filter-body::-webkit-scrollbar-thumb {
  background-color: #cfbde4;
  border-radius: 5px;
  height: 5px;
}

.filter-body::-webkit-scrollbar-thumb::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #a98ccd;
  transform: translateY(-50%);
  border-radius: 5px;
}

.scrollwrapped {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
}

.scrollwrapped::-webkit-scrollbar {
  width: 5px;
}

.scrollwrapped::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

.scrollwrapped::-webkit-scrollbar-thumb {
  background-color: #cfbde4;
  border-radius: 5px;
  height: 5px;
  position: relative;
}

.scrollwrapped::-webkit-scrollbar-thumb::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #a98ccd;
  transform: translateY(-50%);
  border-radius: 5px;
}

.filter-wrap .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-wrap .head .heading {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.filter-wrap .head .clear {
  cursor: pointer;
}
.filter-wrap .filter {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* border-bottom: 1px solid #ddd; */

}
.filter-wrap .filter:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.filter-wrap .filter .head {
  cursor: pointer;
  padding-bottom: 2px;
}
.filter-wrap .filter .head svg.open {
  rotate: 180deg;
}
.filter-wrap .filter .filter-body {
  padding-left: 8px;
}
.filter-wrap .filter .filter-body .checkbox-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.filter-wrap .filter .filter-body .checkbox-wrap .checkbox-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-wrap .filter .filter-body .checkbox-wrap .checkbox-item label {
  cursor: pointer;
  display: contents;
  margin: 0;
}
.filter-wrap .filter .filter-body .checkbox-wrap .checkbox-item input {
  display: flex;
  margin: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #73509e;
}

.filter-body {
  animation: fadeIn 0.3s ease-in-out;
}

.col-cust {
  position: relative;
}

.pbtnsb {
  background: #73509e;
}
.pbtnsb1 {
  background-color: #9ba24c !important;
}
.buttonsinoneline {
  display: flex;
  gap: 10px;
}

.buttonforquotation{
  background-color: #73509e;
}
.layout-wrap {
  margin: 0px 0 0;
}
.layout-wrap .product-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  margin-top: 30px;
}

.layout-wrap .product-wrap .products {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: 80px;
}

.layout-wrap .product-wrap .product {
  width: 33.33%;
  min-height: 410px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  box-sizing: border-box;
  border-right: 1px solid #73509e1a;
}

.layout-wrap.grid-view .product-wrap .product {
  width: 23.33%; 
  display: flex;
  column-gap: 12px;
}


.layout-wrap .product-wrap .product .product-inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 25px;
}
.layout-wrap .product-wrap .product:nth-child(3n + 3) {
  border-right: none;
}
.layout-wrap .product-wrap .product .product-img {
  width: 100%;
  height: 250px;
  object-fit: fill;
  display: flex;
  align-self: center;
  padding: 0px 0px;
}
.layout-wrap .product-wrap .product .content {
  margin: 0;
}
.layout-wrap .product-wrap .product .content .name {
  font-size: 16px;
  color: #211e24;
  margin: 0 0 10px;
}
.layout-wrap .product-wrap .product .content .name:after {
  content: "";
  width: 32%;
  height: 2px;
  background: #211e24;
  display: flex;
  margin-top: 4px;
}
.layout-wrap .product-wrap .product .content .location {
  font-size: 14px;
  color: #7a7c7f;
  margin: 0 0 5px;
}
.layout-wrap .product-wrap .product .content .price-wrap {
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: space-between;
  color: #211e24;
  margin: 0 0 10px;
}
.layout-wrap .product-wrap .product .content .price-wrap .time {
  font-size: 16px;
}
.layout-wrap .product-wrap .product .content .price-wrap .price {
  font-size: 16px;
}
.layout-wrap .product-wrap .product .heart {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  padding: 0;
}

.heart{
  position: absolute;
  top: 0.5px;
  right: 0.5px;
  background: none;
  border: none;
  padding: 0;
}

.heart svg{
  stroke: #211e24;
  fill: transparent;
}

 .heart.favorite svg {
  fill: #73509e;
  stroke: #73509e;
}

.heartcolor{
  background-color: transparent !important;
}

.layout-wrap .product-wrap .product .heart svg {
  stroke: #211e24;
  fill: transparent;
}
.layout-wrap .product-wrap .product .heart.favorite svg {
  fill: #73509e;
  stroke: #73509e;
}
.layout-wrap .product-wrap .product .purchase {
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 16px;
  padding:10px;
  transition: 0.3s;
  white-space: nowrap;
  background-color: #73509e;
}

.purchase{
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 16px;
  padding:10px;
  transition: 0.3s;
  white-space: nowrap;
  background-color: #73509e;
}
.layout-wrap .product-wrap .product .purchasef {
  font-size: 16px;
  color: #fff;
  /* height: 54px; */
  border: none;
  padding:10px;
  border-radius: 12px;
  transition: 0.3s;
  /* display: none; */
  background-color: #73509e;
  white-space: nowrap;
}

.layout-wrap .product-wrap .product:hover .product-inner {
  background: #fff;
  padding: 20px 25px;
  min-height: 450px;
}
.layout-wrap .product-wrap .product:hover .content .name {
  color: #211e24;
}
.layout-wrap .product-wrap .product:hover .content .name:after {
  background: #211e24;
}
.layout-wrap .product-wrap .product:hover .content .location {
  color: #211e24;
}
.layout-wrap .product-wrap .product:hover .content .price-wrap {
  color: #211e24;
}
.layout-wrap .product-wrap .product:hover .heart svg {
  stroke: #211e24;
}
.layout-wrap .product-wrap .product:hover .heart.favorite svg {
  fill: #73509e;
  stroke: #73509e;
}
.layout-wrap .product-wrap .product:hover .purchase {
  display: block;
}
.layout-wrap .product-wrap .product:hover .purchasef {
  display: block;
}
.bot-icon-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
}
.bot-icon-wrap .bot-icon {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #9b9e51;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.text-responsive {
  font-size: 1.15rem;
  font-weight: 600;
}

.loadmorebuttondiv {
  display: flex;
  justify-content: flex-end;
  margin-top: -4%;
}

.load-more-button {
  font-size: 13px;
  font-weight: 700;
  text-align: right !important;
  /* padding: 2px 5px; */
  color: #73509e;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  padding-bottom: 5px;
}

/*--------------------------------------------------Media queries------------------------------------------------*/
@media (max-width: 576px) {
  .text-responsive {
    font-size: 0.8rem;
  }
  .delveryloactionn {
    font-size: 12px;
    padding: 0 24px;
    height: 43px;
  }
  .individual-div-fliters-delivery-grid {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .delivery-location-wrapper {
    display: block;
  }
  .breadcrumbsanddeliverylocationsort {
    display: block;
  }
  .jisngnvnbnb {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-filter-wrap {
    display: block;
  }
  .filtersectioncse {
    display: none;
  }
  .filter-wrap {
    display: none;
  }
  .layout-wrap {
    margin: 30px 0 0;
  }
  .layout-wrap .product-wrap .product {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
  }
  .tab-wrap {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
  }
  .head-wrap {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
  }
  .head-wrap .btn-wrap {
    column-gap: 10px;
  }
  .head-wrap .btn-wrap .btn {
    height: 45px;
    padding: 0 16px;
    font-size: 12px;
  }
  .head-wrap .btn-wrap .btn.location {
    font-size: 12px;
    padding: 0 34px;
  }
  .head-wrap .btn-wrap .short-wrap .short-btn span {
    display: none;
  }
  .head-wrap .btn-wrap .filter-btn {
    display: block;
    background: #fff;
    border: 2px solid #73509e;
  }

  .layout-wrap .product-wrap .product .product-img {
    padding: 0px 0px;
  }
  .individual-div-fliters-delivery-grid {
    display: none !important;
  }
  .layout-wrap .product-wrap .product .product-img {
    width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: cover;
    display: flex;
    align-self: center;
    padding: 0;
  }

  .delveryloactionn {
    font-size: 12px;
    padding: 0 24px;
    height: 43px;
  }
  .filters-delivery-grid {
    display: none;
  }
  .individual-div-filters-delivery-grid {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .filter-wrap {
    top: 100px !important;
  }

  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (max-width: 991px) {
  .filter-wrap {
    flex: 0 0 250px;
  }
}

@media (min-width: 769px) and (max-width: 814px) {
  .layout-wrap .product-wrap .product {
    margin: 0 auto;
    width: 52%;
  }
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (min-width: 815px) and (max-width: 854px) {
  .layout-wrap .product-wrap .product {
    margin: 0 auto;
    width: 45%;
  }
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (min-width: 854px) and (max-width: 999px) {
  .layout-wrap .product-wrap .product {
    margin: 0 auto;
    width: 50%;
  }
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .layout-wrap .product-wrap .product{
    width: 50%;
  }
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }

  .layout-wrap.grid-view .product-wrap .product {
    width: 30%;
  }
}

@media (min-width: 1201px) and (max-width: 1249px) {
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (min-width: 1250px) and (max-width: 1625px) {
  .layout-wrap .product-wrap .products {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    row-gap: 80px;
  }

  .layout-wrap .product-wrap .product {
    width: 33.33%;
    min-height: 410px;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    box-sizing: border-box;
    border-right: 1px solid #73509e1a;
  }
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (min-width: 1625px) and (max-width: 2000px) {
  .layout-wrap .product-wrap .products {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    row-gap: 100px;
    column-gap: 25px;
  }

  .layout-wrap .product-wrap .product {
    width: 23.33%;
    min-height: 410px;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    box-sizing: border-box;
    border-right: 1px solid #73509e1a;
  }
  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}

@media (min-width: 2001px) and (max-width: 5000px) {
  .layout-wrap .product-wrap .products {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    row-gap: 100px;
  }

  .layout-wrap .product-wrap .product {
    flex: 0 1 calc(20% - 30px);
    min-height: 410px;
    box-sizing: border-box;
  }

  .filterAndLocationWrapper {
    display: none !important;
  }
  .filterAndLocationNotForDesktop{
    display: none !important;
  }
}
