.listofservices{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
   
}

.listofservices:hover{
    transition: all .5s ease;
}

.listofservices img{
    width: 5.625rem;
    height: 5.625rem;
}

.listofservices img:hover{
    width: 6.25rem;
    height: 6.25rem;
}
.listofservice-title{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: center;
}
.listofservice-title.hovered{
font-size: 1.125rem;
font-weight: 600;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: center;

}
.listofservice-message{
/* display: none; */
visibility: hidden;
}
.listofservice-message.hovered{
    /* display: block; */
    visibility: visible;
font-size:0.875rem;
font-weight: 400;
line-height: 1.375rem;
letter-spacing: 0em;
text-align: center;
}

@media (max-width:992px)
{
    .listofservice-title,.listofservice-title.hovered{
font-size: 12px;
font-weight: 600;
line-height: 24px;
letter-spacing: -0.01em;
text-align: center;

}
.listofservice-message,.listofservice-message.hovered{
    visibility: visible;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
}
    
}