.privacypolicy-maindiv {
    margin-top: 2rem;
}

.accordion-header {
    width: 100% !important;
}

.accordion-header button {
    width: 100% !important;
    height: 4rem;
    border: none;
    background-color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #211E24;
}

.privacypolicy-para {
    opacity: 60%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #211E24;
    display: flex;
    margin: 0;
}

.privacypolicy-heading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #211E24;
}

.listp {
    padding: 0;
    margin: 0;
}

.accordionborder {
    border-bottom: 1px solid rgb(210, 199, 199);
}

@media only screen and (max-width: 600px) {
    .accordion-header button {
        font-size: 14px !important;
        font-weight: 600;
        line-height: 24px;
    }
}