
.cart-lyt-box {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 25px;
    margin-bottom: 25px;
}
.cart-lyt-box .top-cart-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.cart-lyt-box .first__row__box {
    display: flex;
}
.cart-lyt-box .plan-name {
    font-size: 14px;
    font-weight: 500;
    color: #211e24;
    margin-bottom: 0px;
    opacity: 0.7;
}
.cart-lyt-box .price {
    color: #211e24;
    font-size: 16px;
    font-weight: 600;
    line-height: 170%;
    margin-bottom: 8px;
}
.cart-lyt-box .cancel-price {
    font-size: 14px;
    color: #211e24;
    font-weight: 400;
    opacity: 0.6;
}
.cart-lyt-box .rating {
    color: #211e24;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.cart-lyt-box .discount-available {
    color: #211e24;
    font-size: 16px;
    font-weight: 600;
    line-height: 170%;
    margin-bottom: 0;
}
.cart-lyt-box .limites_time {
    color: #211e24;
    font-size: 16px;
    font-weight: 600;
    line-height: 170%;
    margin-bottom: 0;
}
.typ-addBtn .typ-light-button {
    background-color: #73509e99;
    width: 155px;
    height: 50px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid rgba(115, 80, 158, 0.6);
    border-radius: 12px;
}
.typ-addBtn .typ-light-button:hover {
    background-color: #fff;
    color: #73509e99;
    border: 2px solid #73509e99;
}
.typ-addBtn .typ-transparent-button {
    background-color: white;
    width: 155px;
    height: 50px;
    color: #73509e;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid #73509e;
    border-radius: 12px;
    margin-right: 25px;
}
.typ-addBtn .typ-transparent-button:hover {
    background-color: #73509e;
    color: #fff;
    border: 2px solid #73509e;
}
.typ-addBtn .typ-light-button {
}
.first__row__box .typ-custom-width {
    min-width: 200px;
}

.cart-lyt-box .second-cart-box {
    margin-top: 35px;
    display: flex;
}
.cart-lyt-box .typ-features {
    color: #211e24;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    opacity: 0.7;
}
.cart-lyt-box .feature_list {
    color: #211e24;
    font-size: 16px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 0;
}
.cart-lyt-box .icon__img {
    margin-right: 8px;
}
.cart-lyt-box .typ-second-row-width {
    min-width: 450px;
}
.cart-lyt-box .scopes_list {
    color: #211e24;
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 0;
}
.heading-box {
    padding: 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
}
.heading-box .cart-heading {
    font-size: 24px;
    font-weight: 600;
    color: #211e24;
    margin-bottom: 0;
}
.heading-box .company-name {
    font-size: 16px;
    font-weight: 400;
    color: #211e24;
    opacity: 0.5;
    margin-bottom: 0;
}
.second-cart-box .key-flex {
    display: flex;
}
.second-cart-box .feature_para {
    color: #211e24;
    font-size: 16px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 0;
    margin-left: 5px;
}

/* Swiper */
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.productSwiper .img-box{
    padding: 28px;
}
.productSwiper .img-box:hover{
    border-right: 2px solid rgba(115, 80, 158, 0.10);
    background:  #9B9E51;
    box-shadow: 0px 32px 64px 0px rgba(101, 105, 4, 0.24);
}
.cart-lyt-box .nav-tabs .nav-link {
    color: #211E24!important;
    opacity: 0.6;
    border: none;
    font-size: 14px;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
}
.cart-lyt-box .nav-tabs .nav-item{
    margin-right: 40px;
}
.cart-lyt-box .nav-link.active{
    color: #211E24!important;
    opacity: 1;
    border-bottom: 3px solid #9DA14E!important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
}

/* Karthi */

.buynowbtn1{
    position: relative !important;
    top: 20px !important; 
    margin: auto !important;
    display: block !important;

}

.errormsg {
    padding: 15px;
    color: red;
    font-size: 16px;
    text-align: right;
    /* text-align: center; */
}

/* Tabs */
.tab-first-row{
    display: flex;
}
.tab-first-row .typ-first-lyt{
    min-width: 350px;
}
.tab-first-row .tab--heading{
    font-size: 14px;
    font-weight: 400;
    opacity: 0.7;
    color: #211E24;
    margin-bottom: 12px;
}
.tab-first-row .tab--para{
    color: #211E24;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    width: 281px;
}
.tab-first-row .typ-tab-flex{
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}
.tab-first-row .typ-tab-flex .typ--para{
    color:  #211E24;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}


/*@media screen and (min-width: 320px) and (max-width: 480px) {*/
@media screen and (min-width: 320px) and (max-width: 932px) {
    .heading-box {
        padding: 8px;
    }
    .heading-box .cart-heading {
        font-size: 12px;
        max-width: 230px;
    }
    .cart-lyt-box {
        padding: 16px;
    }
    .cart-lyt-box .top-cart-box {
        flex-direction: column;
    }
    .first__row__box .typ-custom-width {
        min-width: 165px;
    }
    .cart-lyt-box .plan-name {
        font-size: 12px;
    }
    .cart-lyt-box .price {
        font-size: 14px;
    }
    .cart-lyt-box .cancel-price {
        font-size: 12px;
    }
    .cart-lyt-box .discount-available {
        font-size: 14px;
    }
    .cart-lyt-box .limites_time {
        font-size: 14px;
    }
    .second-cart-box .key-flex {
        display: flex;
        flex-direction: column;
    }
    .second-cart-box .feature_para {
        color: #211e24;
        font-size: 14px;
        font-weight: 600;
        margin-top: 2px;
        margin-bottom: 0;
        margin-left: 0px;
    }
    .second-cart-box .feature_para.typ-mt-15 {
        margin-top: 15px;
    }
    .cart-lyt-box .feature_list {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.7;
    }
    .cart-lyt-box .second-cart-box {
        flex-direction: column;
    }
    .cart-lyt-box .typ-second-row-width {
        min-width: 200px;
    }

    /* Tab  */
    .tab-first-row{
        flex-direction: column;
    }
    .tab-first-row .tab--heading{
        font-size: 12px;
    }
    .tab-first-row .tab--para{
        font-size: 14px;
        font-weight: 400;
    }
    .tab-first-row .typ-first-lyt{
        min-width: 100%;
        margin-bottom: 25px;
    }
    .tab-first-row .typ-tab-flex .typ--para {
        margin-bottom: 0;
    }
    .cart-lyt-box .nav{
        flex-wrap: nowrap!important;
    }
    .cart-lyt-box .nav-link{
        text-align: left;
    }
    .cart-lyt-box .nav-tabs .nav-item{
        margin-right: 20px;
    }


    .annual_tabs{
        overflow-x:auto!important   ;
    }
}
