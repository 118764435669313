
.totalpopupdiv {
  text-align: left;
}

.quotationDetailsheading {
  font-size: 1.7rem;
  font-weight: 700;
}

.renderPriceListinRaiseForQuotationPopup{
display: flex;
flex-wrap: wrap;
/* justify-content: space-around; */
}
.buttonclickednodvjbktorbfdg{

padding: 20px;
background-color: #fafafa;
border: none;
font-size: 16px;
border-radius:8px ;
}


.buttonclickednodvjbktorbfdg:hover{
color: white;
/* transform: scale(0.95);  */
/* background-color: #937db5; */
background-color: lightslategrey;
}


.priceItemLabel{
white-space: nowrap;
}
@media (max-width: 575px) {
.buttonclickednodvjbktorbfdg {
 background-color: #73509e;/* Disable pointer events to prevent hover effect */
 
 /* transform: scale(0.95); */
}
.priceItemLabel{
  white-space: normal;
}

.renderPriceListinRaiseForQuotationPopup{
display: flex !important;
justify-content: flex-start !important;
}

.buttonclickednodvjbktorforqutotation{
  padding: 12px;
  width: 130px !important; /* Set a fixed width */
  text-align: center; 
  background-color: #fafafa;
  border: 1px solid #e9e9e9;
  font-size: 16px;
  border-radius:8px ;


}


.buttonclickednodvjbktorbfdg {
  
}
}

@media (min-width: 576px) and (max-width: 767px) {
.buttonclickednodvjbktorbfdg:hover {
  background-color: #73509e;/* Disable pointer events to prevent hover effect */
  color: white; /* Change text color */
  /* transform: scale(0.95); */
 }
 .priceItemLabel{
  white-space: normal;
}
}
.btn-clicked {
background-color: #73509e; /* Example: change to a different background color */
color: white; /* Change text color */
/* transform: scale(0.95);  */
}

.button-submit-popup {
  padding: 11px 16px;
  font-size: 14px;
  color: white;
  background-color:  #73509e;
  border: none;
  font-weight: 500;
  border-radius: 10px;
}

.Contentisnidepopup {
  font-size: 1rem;
}

.popupContentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.imageWrapper {
  margin-left: 20px; /* Adjust spacing as needed */
}

.imageinpopup {
max-width: 200px; /* Increase the width */
  max-height: 400px; /* Increase the height */
  object-fit: cover; /* Maintain aspect ratio */
}


.buttonsqautntiyf{
  display: flex;
  justify-content:space-between;

  align-items: center;
  gap: 10px;

}


.butttonplusminus{
  padding: 5px 8px;
  border-radius: 5px;
  background-color:  #73509e;
  color: white;
  border: none;
}

.renderPricListsf{
margin-top: -6%; 
}


.price-list-container {
display: flex;
flex-wrap: wrap; /* Allow buttons to wrap to the next line */
justify-content: flex-start;
}

.buttonclickednodvjbktorbfdg {
flex: 1 1 45%; /* This will ensure each button takes up about 30% of the container width */
max-width: 50%; /* Ensure that the button does not exceed this width */
min-width: 50%; /* Make sure each button occupies at least 30% */
text-align: center;
padding: 10px;

box-sizing: border-box;
}

.btn-clicked {
background-color: #f5a623; /* Example of a clicked button style */
color: #fff;
}

.sgjrdkbg {
margin-left: auto; /* Push the "Add to Cart" button to the right */
}


.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  color: #fff; 
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.toast.show {
  opacity: 1;
}

.success-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideIn 0.5s ease-out;
}

.success-popup-content {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  padding: 15px;
  max-width: 300px;
}

.success-icon {
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 15px;
}

.success-message {
  flex-grow: 1;
}

.success-message h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.success-message p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.close-button:hover {
  color: #333;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.success-popup.fade-out {
  animation: fadeOut 0.5s ease-in forwards;
}