.totalcartpage{
  background-color:  #EAEDED;
padding: 26px 26px;
display: flex;
justify-content: space-between;
}

.horinvdnv{
  width: 70%;
}

.BarrelOrBucket{
  border:1px solid #73509e;
  padding: 8px;
  font-size: 13px;
  border-radius: 12px;
  background-color: white;
}
.amccartpage{
background-color:  #EAEDED; 
}
.totalcartpagebuttop{
background-color:  #EAEDED;
padding-left: 6px;
padding-right: 6px;
padding-top: 20px; 
padding-bottom: 10px; 
}

.totalcartpagebuttopformanageaddresspart{
  background-color:  white;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 20px; 
  padding-bottom: 10px; 
  }


.icon-containere {
position: relative;
display: inline-block;
}

.icon-number {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: white; /* Change color as needed */
font-weight: bold;
}

.centertotaldivcart{
display: flex;
justify-content: center;
align-items: center;
}


.leftsideBordertotaldiv{
  width: 69%;
  border: 1px solid rgb(255, 255, 255);

  background-color: rgb(255, 255, 255);
  padding: 20px 20px;
  
}
.leftheadingcarttext{
  font-size: 27px; 
  font-weight: 600; 
  margin-left:12%;
  padding-top: px;


  /* topbottom and rightleft  */
}


.rightheadingcarttext{
  font-size: 14px;
  margin-left:73%;
  margin-top: 5%;
}


.basespace{

}
.lineofcartunder{
  width: 92%;
  border: 0;
  height: 1px;
  top: -14px;
  position: relative;
  margin: 20px auto;
  left: -1%;
  
}.threesectionsofcart {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center; /* Align items vertically centered */
  gap: 50px; /* Adjust the gap between columns */
}

.firstsectionofcart {
  display: flex;
  align-items: center; 
  padding-left:25px; /* Adjust the padding from the left as needed */
}

.secondsectionofcart {
  display: flex;
  flex-direction: column;
   /* Center content vertically */
  margin-left: -8px; /* Adjust the margin between the first and second columns */
}


.headingpartofcarttt{
  font-size: 18px;
  margin-top: -2%;

}

.contentofcartpara{
font-size: 14px;
}
.thirdsectionofcart {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align content to the right */
  justify-content: center; /* Center content vertically */
padding-right: 40px;
}

.deletecartbtn {
  width: 20%;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px 4px;
  background-color: white;
}
 
.deletecartbtn:hover{
  background-color: rgb(234, 234, 234);
}

.proceedcartbtn {
  width: 50%;
  white-space: nowrap;
  background-color:#735197;
  color: white;
  padding:8px 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;

  border: none;
}


.proceedcartbtnnew {
  width: 18%;
  margin-left: 80%;
  margin-top: 2%;
  white-space: nowrap;
  background-color:#735197;
  color: white;
  padding:8px 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;

  border: none;
}

.proceedcartbtn:hover{
  cursor: pointer;
  background-color: #6c5b7e;
}

.badgerightside{
  margin-top: -40%; 
}
.salespricecart{
  font-weight: 600;
  font-size: 18px;

}

.imageonleftofcart {
  width: 160px; /* Set a fixed width */
  height: 17vh; /* 20% of the viewport height */
  border-radius: 8px; /* Optional: adds rounded corners */
  object-fit: cover;
  /* width: 120px; 
  height: 15vh; */
}

.emptyCartMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  
  text-align: center;
  font-size: 1.2rem; /* Adjust font size as needed */
  color: #555; /* Adjust text color */
}

.stockbold{
  font-weight: 500;
}
.SummaryPart{

align-items: center; 
padding-left: 152px;
}

.inStocktext{
  font-size: 15px;

}

.inStocktextcontent{
  color: #9b9e51;
}

.lineofcartbottom{
  width: 92%;
  border: 0;
  height: 1px;
  top: -8px;
  position: relative;
  margin: 20px auto;
  left: -1%;
  color: gray;
}



.buttonsgapbetweentwocart {
  display: flex;
padding-top: 2px;
align-items: center;
  gap: 10px; /* Adjust the gap between buttons */
}

.limitedtime{
  color: #735197;
  font-size: 16px;
  font-weight: 700;
}

.badgeoffer{
  background-color:#9b9e51;
  color: white;
  padding: 4px 6px;
  text-align: center;
  margin-right: 9px;
  border-radius: 5px;

}






.quantity-selector-wrapper {
  border: 1px solid #ccc; /* Border style */
  border-radius: 4px; /* Optional: Rounded corners */
/* Optional: Adjust padding */

}
.quantity-selector-wrapper.clicked {
  border-color: blue; /* Border color when clicked */
}


.quantity-selector {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.quantity-button {
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 1px 10px;
  font-size: 14px;
}


.quantity-value {
  padding: 8px 12px;
  font-size: 14px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  min-width: 40px; /* Ensure space for two-digit numbers */
  text-align: center;

}
.rightsideBordertotaldiv{
  width: 29%;
  border: 1px solid rgb(255, 255, 255);

  background-color: rgb(255, 255, 255);
  padding: 20px 20px;
}

.rifhtsidebortertotaldivcontent{
margin-top: 3.5%;
}

 
.completebuttonradius{
background-color: #009674;
border-top-left-radius: 8px;
border-bottom-left-radius: 8px;
box-shadow: inset 0 0 0 1px #067d62;

}

.meter-container {
width: 100%;
max-width: 400px; /* Adjust as needed */
margin: 20px auto; /* Center the meter horizontally */
}

.meter {
background-color: #e0e0e0; /* Background color of the meter */
border-radius: 8px; /* Rounded corners */
overflow: hidden; /* Clip children elements that overflow the container */
height: 15px; /* Height of the meter */
width: 80%; /* Full width of the container */
}

.meter-fill {
background-color: #73509e; /* Color of the filled portion */
height: 100%; /* Fill the full height of the meter */
transition: width 0.3s; /* Smooth transition for width changes */
width: 0; /* Initial width of the fill */
}
/* 
.proceddcartbuttondiv{
display: flex;
justify-content: center;
} */

.summaryContent {
margin: 10px 0;
}

.summaryItem {
display: flex;
justify-content: space-between;
margin: 5px 0;
}

.label {
flex: 1;
font-weight: 500;
}
.labledddd{
font-weight: 700;
}

.value {
flex: 0 0 auto;
}

.lineedbr{
margin-right: 10px;
}
.valuesdbf {
text-decoration: line-through;
}

.no-decoration {
text-decoration: none;
}
.add-to-cart-text {
margin-left: 10px;
margin-right: 8px; /* Adjust spacing as needed */
font-weight: 600;
}
/* 
@media (max-width: 576px) {
.add-to-cart-text{
  font-size: 12px;
  margin-left: 1px;
  white-space: nowrap;
}
.line {
 display: none;
}
.lineedbr{
  margin-right: 1px;
  
  }
  .icon-container{
    margin-left: 1px;
  }
  .leftheadingcarttext{
    font-size: 16px;
    white-space: nowrap;
  }
  .rightheadingcarttext{
    margin-left: 5%;
    display: none;
  }
  .threesectionsofcart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
  }
  .firstsectionofcart{
   margin-left: -50%;
   margin-top: -8%;
  }
  .secondsectionofcart {
    text-align: center; 
    margin-left: 0;
  }

  .imageonleftofcart {
    width: 100%; 
    max-width: 120px; 
    height: auto;
    margin-bottom: 10px;
  }
  .headingpartofcarttt{
    font-size:14px;
    text-align: left;
    
    white-space: nowrap;
  }
  .contentofcartpara{
   
    font-size:12px;
text-align: left;
  }
  .inStocktext{
    font-size: 12px;
  }
  .buttonsgapbetweentwocart{
    display: block;
    margin-top: -12%;
    text-align: left;
  }

  .quantitytextright{
    font-size: 12px;
    text-align: left;
  }
  
  .thirdsectionofcart{
    
  }
  

  .summaryContent{
    font-size: 12px;
  }




  .rifhtsidebortertotaldivcontent{
text-align: left;
  }

  .orderdetailscart{
    font-size: 12px;
  }
  .ioiconscc{
    font-size: 20px;
  }

}
*/


.linebetweenicons{
  display: block;
}


.horizontal-line-above-the-stepper{
  width: 90%;
}

/*  */

.line-below-eachaddress-div{
  display: none;
}
.line-below-eachaddress{
  display: none;

}

@media (max-width: 576px) {
.totalcartpagebuttop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Ensure that items wrap to the next line if they overflow */

}
.line-below-eachaddress-div{
  display: block;
}
.line-below-eachaddress{
  display: block;
}

.centertotaldivcart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap; /* Ensure that items wrap to the next line if they overflow */
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 10px; /* Adjust the margin as needed */
  margin-right: 8%;  
}



.icon-number {
  font-size: 12px; /* Adjust the font size as needed */
   /* Adjust the spacing as needed */
}

.add-to-cart-text {
  font-size: 10px !important; 
  margin: 0 5px; 
  white-space: nowrap;
  margin-left: 1%;
  margin-right: 8px;
}

.line {
  display: none; 
}

 .linebetweenicons{
  display: none;
} 




  .leftheadingcarttext{
    font-size: 16px;
    white-space: nowrap;
  }
  .rightheadingcarttext{
    margin-left: 5%;
    display: none;
  }

.totalcartpage {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.leftsideBordertotaldiv,
.rightsideBordertotaldiv {
  width: 100%;
  margin-bottom: 20px;
}

.toppartofcartpage {
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.leftheadingcart,
.rightheadingcart {
  width: 100%;
  text-align: center;
}

.leftheadingcarttext,
.rightheadingcarttext {
  font-size: 18px; /* Adjust font size as needed */
}

.horizontallineofcartpage {
  width: 100%;
}

.lineofcartunder,
.lineofcartbottom {
  width: 100%;
  margin: 10px 0;
}
.threesectionsofcart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
  align-items: center;
}

.firstsectionofcart, .secondsectionofcart, .thirdsectionofcart {
  width: 100%;
  text-align: center;
}

.imageonleftofcart {
  width: 100%;
  height: auto;
}

.secondsectionofcart {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.headingpartofcarttt {
  font-size: 1.2rem;

}

.contentofcartpara {
  margin-bottom: 10px;
}

.buttonsgapbetweentwocart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.quantity-selector-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-button {
  font-size: 1rem;
}

.quantity-value {
  font-size: 1rem;
  margin: 0 10px;
}

.deletecartbtn {
  background-color:#735197;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 70%;
  cursor: pointer;

  margin-top:-4%;
}

.thirdsectionofcart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badgerightside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}
.salespricecart{
display: none;
}
.badgeoffer, .limitedtime{
display: none;
}


.contentofcartpara{
  margin-top: -3%;
  }

  .buttonsgapbetweentwocart{
    margin-top: -7%;
  }
.rifhtsidebortertotaldivcontent {
  text-align: center;
}

.meter-container {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3%;
}

.summaryContent {
  padding: 10px 0;
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  flex-wrap: wrap;
}

.label {
  flex: 1 0 45%; /* Adjust the width as per your design */
  margin-right: 10px; /* Spacing between label and value */
  text-align: left; /* Align labels to the left */
}

.value {
  flex: 1 0 1%; /* Adjust the width as per your design */
  text-align: left; /* Align values to the left */
}

.labledddd {
  font-size: 1.2rem; /* Adjust font size for the label */
}

.proceddcartbuttondiv {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.proceedcartbtn {
  width: 100%;
  padding: 10px; /* Adjust padding for better spacing */
}

.ioiconscc {
  display: inline-block;
  margin-right: 8px;
  font-size: 15px;
}

.meter{
  vertical-align: middle;  
}
.orderdetailscart {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
}
}


@media (min-width: 577px) and (max-width: 768px) {
.totalcartpagebuttop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.centertotaldivcart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  margin-right: 8%;
}

.icon-number {
  font-size: 12px;
}

.add-to-cart-text {
  font-size: 12px;
  margin: 0 5px;
  white-space: nowrap;
  margin-right: 8px;
}

.line {
  display: flex;
}

.leftheadingcarttext {
  font-size: 16px;
  white-space: nowrap;
}

.rightheadingcarttext {
  margin-left: 5%;
  display: none;
}

.totalcartpage {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.leftsideBordertotaldiv,
.rightsideBordertotaldiv {
  width: 100%;
  margin-bottom: 20px;
}

.toppartofcartpage {
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.leftheadingcart,
.rightheadingcart {
  width: 100%;
  text-align: center;
}

.leftheadingcarttext,
.rightheadingcarttext {
  font-size: 18px;
}

.horizontallineofcartpage {
  width: 100%;
}

.lineofcartunder,
.lineofcartbottom {
  width: 100%;
  margin: 10px 0;
}

.threesectionsofcart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
  align-items: center;
}

.firstsectionofcart,
.secondsectionofcart,
.thirdsectionofcart {
  width: 100%;
  text-align: center;
}

.imageonleftofcart {
  margin-left: 25%;
  width: 50%;
  height: auto;
}

.secondsectionofcart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headingpartofcarttt {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contentofcartpara {
  margin-bottom: 10px;
}

.buttonsgapbetweentwocart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

}
.quantitytextright{
  margin-top: 15%;
}

.quantity-selector-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-button {
  font-size: 1rem;
}

.quantity-value {
  font-size: 1rem;
  margin: 0 10px;
}

.deletecartbtn {
  background-color:#735197;
  color: #fff;
  padding: 5px 10px;
  border: none;
  width: 70%;
  cursor: pointer;
  margin-top: -4%;
}

.thirdsectionofcart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badgerightside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}

.salespricecart {
  display: none;
}

.badgeoffer,
.limitedtime {
  display: none;
}

.contentofcartpara {
  margin-top: -3%;
}

.buttonsgapbetweentwocart {
  margin-top: -7%;
}

.rifhtsidebortertotaldivcontent {
  text-align: center;
}

.meter-container {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  margin-left: 6%;
}

.summaryContent {
  padding: 10px 0;
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  flex-wrap: wrap;
}

.label {
  flex: 1 0 45%;
  margin-right: 10px;
  text-align: left;
}

.value {
  flex: 1 0 1%;
  text-align: left;
}

.labledddd {
  font-size: 1.2rem;
}

.proceddcartbuttondiv {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.proceedcartbtn {
  width: 100%;
  padding: 10px;
}

.ioiconscc {
  display: inline-block;
  margin-right: 8px;
}

.orderdetailscart {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
}
}

@media (min-width: 769px) and (max-width: 888px) {

}
@media (min-width: 769px) and (max-width: 992px) {
.threesectionsofcart {
  display: grid;
  grid-template-columns: 1fr auto; /* Adjust the columns to ensure proper placement */
  grid-gap: 10px; /* Adjust the gap as needed */
  margin-bottom: 15px;
}

.firstsectionofcart {
  grid-column: 1 / 2; /* Ensure it takes the first column */
}

.secondsectionofcart {
  grid-column: 1 / 2; /* Ensure it takes the first column */
  margin-left: 5%;
}

.thirdsectionofcart {
  margin-top: -25%;
  grid-column: 2 / 3; /* Ensure it stays in the second column */
  align-self: start; /* Align to the top of the column */
  text-align: left;
}

.badgerightside {
  font-size: 1em; /* Adjust as needed */
}

.badgeoffer, .limitedtime, .salespricecart {
  display: block;
  margin-bottom: 5px;
}
.proceedcartbtn{
  font-weight:600 ;
  font-size: 12px;
  width: 100%;
}
}



@media (min-width: 993px) and (max-width: 1200px) {
.deletecartbtn{
  width: 80%;
 }
 .proceedcartbtn{
  font-weight:600 ;
  font-size: 12px;
  width: 100%;
}
}


@media (min-width: 1201px) and (max-width: 1440px) {
.proceedcartbtn{
  font-weight:600 ;
  font-size: 12px;
  width: 100%;
}

}

.card{
width: 100%;
}