*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {  
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Poppins";
}

