.summary-wrap{display:flex;overflow: hidden;}
.summary-wrap .btn-wrap{flex:0 0 100px;}
.summary-wrap .content-wrap{flex:1;background:#F9F9F9;padding:0px 50px 50px;display: flex;flex-direction: column;text-align: center;}
.summary-wrap .content-wrap .address-top-wrap{display: flex;align-items:center;column-gap:5px;justify-content: center;}
.summary-wrap .content-wrap .dilevery-address,
.summary-wrap .content-wrap .order-sumary{color:#211E24;}
.summary-wrap .content-wrap .dilevery-address-1{position: relative;margin: 0 8px 0 0;}
.summary-wrap .content-wrap .order-sumary-2{position:relative;margin: 0 8px 0 0;}
.summary-wrap .content-wrap .order-sumary-2::before{content:"2";position: absolute;top:3%;left:36%;color: #fff;}
.summary-wrap .content-wrap .bottom-wrap{margin: 40px 0 0;}
.summary-wrap .content-wrap .bottom-wrap .top-heading{display: flex;justify-content: space-between;}
.summary-wrap .content-wrap .bottom-wrap .top-heading .select-heading{color:#211E24}
.summary-wrap .content-wrap .bottom-wrap .top-heading .add-address{color:#211E24;display:flex;align-items: center;gap:5px}
.summary-wrap .content-wrap .bottom-wrap .top-heading .add-address .addicon{display:flex;padding:4px;border-radius:50%;border: 1px solid var(--Black, #211E24);background: #FFF;box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail{display: flex;flex-direction:column;}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail .detail-lists{display: flex;gap: 10px;align-items: baseline;padding:24px 0 50px;border-bottom:1px solid #AAA;}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail .detail-lists .check .radio{accent-color:#73509E;}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail .detail-lists .check-details{flex:1;}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail .detail-lists .check-details .select{text-align:left;color: #333;margin:0 0 8px;}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail .detail-lists .check-details .text{text-align:left;max-width:496px;margin:0 0 6px;color: #211E24;opacity: 0.6;}
.summary-wrap .content-wrap .bottom-wrap .bottom-wrap-detail .detail-lists .check-details .contact-details{display: flex;justify-content:space-between;max-width:496px;color: #211E24;opacity: 0.6;}
.summary-wrap .content-wrap .product-wrap{display:flex;align-items:center;}
.summary-wrap .content-wrap .product-wrap .left-wrap{flex:1;}
.summary-wrap .content-wrap .product-wrap .left-wrap .product-img{display:flex;width:100%;max-width:180px;margin:40px 0 24px;}
.summary-wrap .content-wrap .product-wrap .left-wrap .title{position:relative;margin:0 0 10px;padding:0 0 5px;text-align:left;}
.summary-wrap .content-wrap .product-wrap .left-wrap .title:after{content:"";position:absolute;height:2px;background:#211E24;width:80px;bottom:0;left:0;}
.summary-wrap .content-wrap .product-wrap .left-wrap .price-wrap{width:100%;max-width:300px;display:flex;flex-direction:column;row-gap:5px;}
.summary-wrap .content-wrap .product-wrap .left-wrap .price-wrap .price-item{display:flex;align-items:center;column-gap:12px;justify-content:space-between;}
.summary-wrap .content-wrap .product-wrap .right-wrap{flex:1;background:#fff;padding:25px;display:flex;flex-direction:column;row-gap:8px;}
.summary-wrap .content-wrap .product-wrap .right-wrap .amount-heading{margin:0 0 8px;color:#211E24;text-align:left;}
.summary-wrap .content-wrap .product-wrap .right-wrap .price-item{display:flex;align-items:center;column-gap:12px;justify-content:space-between;color:#211E24;}
.summary-wrap .content-wrap .product-wrap .right-wrap .price-item{display:flex;align-items:center;column-gap:12px;justify-content:space-between;}
.summary-wrap .content-wrap .product-wrap .right-wrap .total-wrap{display:flex;align-items:center;column-gap:12px;justify-content:space-between;border-top:1px solid #DDD;margin:10px 0 0;padding:10px 0 0;color:#211E24;}
.summary-wrap .content-wrap .deliver-btns{height: 48px;width:164px;background: #73509E;color: #fff;border-radius: 12px;border: none;margin:10px 0 0;}
.summary-wrap .content-wrap .delivery-btn-wrap{display: flex;justify-content: flex-end;}
@media(max-width:576px){
    .summary-wrap .content-wrap{flex:1;background:#F9F9F9;padding:0px 20px 20px;display: flex;flex-direction: column;text-align: center;}
    .summary-wrap .content-wrap .dilevery-address-1{position: relative;margin: 0 8px 0 0;display: block;}
    .summary-wrap .content-wrap .order-sumary-2{position:relative;margin: 0 8px 0 0;display: block;}
    .summary-wrap .content-wrap .order-sumary-2::before {content: "2"; position: absolute; top: 13%;left: 46%;color: #fff; }
}
@media(max-width:520px){
    .summary-wrap .content-wrap .bottom-wrap .top-heading{display: block; text-align: left;}
    .summary-wrap .content-wrap .bottom-wrap .top-heading .add-address{color:#211E24;display:flex;justify-content: flex-end; align-items: center;gap:5px; padding-top: 10px;}
    .summary-wrap .content-wrap .product-wrap{display:block;align-items: center;}
    .summary-wrap .content-wrap .product-wrap .left-wrap{display: flex; flex-direction: column; align-items: center;}
    .summary-wrap .content-wrap .delivery-btn-wrap{display: flex;justify-content: flex-end; padding-top: 20px;}
    .summary-wrap .content-wrap .delivery-btn-wrap .button{
        width: 180px;
    }
}