.promotion-section-container {
  text-align: start;
  padding-top: 28px;
 padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin: 0 auto;
  max-width: 1500px; 
  padding-bottom: 20px;
}

.promotion-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.promotion-slider-wrapper {
  position: relative;
  padding: 0 10px;
}

.promotion-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 10;
}

.promotion-nav-button.prev {
  left: 2%;
}

.promotion-nav-button.next {
  right: -0.8%;
}

.view-all-link {
  display: none;
  text-align: center;
  font-size: 16px; 
  color: blue;  
  text-decoration: none; 
  margin-top: 10px;
}

.theheadingandsvg{
  padding-left: 53px;
 }

.promotion-slide-card {
  background-color: white;

  aspect-ratio: 16 / 9; /* Maintain aspect ratio */
  border-radius: none;
  overflow: hidden;
  margin: 0 15px;
  transition: transform 0.5s ease-in-out;

}

.promotion-slide-image {
  width: 100%;
  height: 100%;

  
  border-radius: none;
/* object-fit: cover; */
  /* object-fit: fill; */
 /*  border-radius: 10px; */
 object-fit: contain;
  margin-left: 3%;
}

@media  (max-width: 989px) {
  .hot-deals-container {
    padding: 15px;
    background-color: white;
    max-width: 600px;
    margin: 0 auto;
  }

  .promotions-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .promotion-card {
    background-color: white;
    border-radius: none;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    aspect-ratio: 3/2;
  }

  .promotion-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: none;
  }

  .promotion-section-container {
    width: 98%;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    border-radius: none;
  }
  .theheadingandsvg{
    padding-left: 35px;
  }
}


@media (max-width: 620px) {
  .view-all-link {
    display: block;
  }
}
@media (max-width: 576px) {
  .promotions-wrapper {
    grid-template-columns: repeat(2);
    gap: 10px;

  }

  .promotion-card-upper{

  }
  .promotion-card {
    max-width: 100%;
  aspect-ratio: 3 / 4;

  
   
  }

  .promotion-image {
    width: 100%;
    /* max-height: auto;  */
    /* object-fit: cover;  */
    /* object-fit: cover; */
  }

  .promotion-section-container {
    padding: 15px 5px;
  }
}