:root {
    --nav-text-max-width: 150px; /* Default max width for text */
    --nav-text-margin-left: 8px; /* Default margin between icon and text */
    --nav-icon-text-gap: 2px; /* Default gap between each icon and text */
}

.navcontainer {
    display: flex;
    padding-top: .8rem;
    justify-content: space-between;
    padding-right: 0 !important;
    padding-left: 0 !important;

}

.navcontainer .user {
    min-width: 400px;
    padding-right: 15px;
}

.logo {
    padding-left: 2px !important;
}

.cart-badge {
    position: absolute;
    top: -10px; /* Adjust this value to position the badge correctly */
    right: 23px; /* Adjust this value to position the badge correctly */
    background-color: #999e51; /* Customize badge background color */
    color: #ffffff; /* Customize badge text color */
    border-radius: 50%; /* To make it circular */
    padding: 2px 6px; /* Adjust padding as needed */
    font-size: 12px; /* Adjust font size as needed */
    font-weight: bold; /* Ensure text is bold for visibility */
}

.lang-a > a {
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}

.lang-a a:hover {
    color: #999e51 !important;
}

.navbar {
    padding: 0.5rem 0 !important;
}

.logo img {
    height: auto;
    width: 5rem;
    margin-left: 0;
}

.user {
    display: flex;
    justify-content: flex-end;
}

.nav-col {
    padding: 0 !important;
    display: flex;
    align-items: center; /* Align items vertically centered */
    margin-right: var(--nav-icon-text-gap); /* Use CSS variable for gap between each icon and text */
    position: relative; /* Ensure the cart icon is the reference for the badge */
}

.nav-col span {
    margin-left: var(--nav-text-margin-left); /* Use CSS variable for margin */
    max-width: var(--nav-text-max-width); /* Use CSS variable for max width */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.top-banner {
    text-align: center;
    width: 100%;
    padding: 8px 0;
    background-color: #735197;
    color: #ffffff;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
}

.phone-link {
    color: #ffffff; /* Inherits the white color for the text */
    text-decoration: none; /* Removes underline from the link */
    padding: 5px 10px; /* Padding for better clickability */
    border-radius: 5px; /* Rounded corners for a modern look */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.phone-link:hover,
.phone-link:focus {
    background-color: rgba(255, 255, 255, 0.3); /* Lighter background on hover/focus for feedback */
    outline: none; /* Removes outline on focus, can be customized if needed for accessibility */
}

/* @media screen and (min-width: 992px){
    .mid-logo{
        display: block;
        width: 64px;
        height: auto;
    }
} */

/* @media screen and (min-width: 320px) and (max-width: 480px) {
    .navcontainer {
        position: relative;
    }
    .navcontainer img {
        position: absolute;
        left: -30%;
    }
    .navbar-light .navbar-toggler{
        border: none;
    }
    .user{
        position: relative; 
    }
    .user .typ-cart-btn{
        position: absolute;
        left: 0;
        top: 10px;
    }
    .menubar-container{
        padding: 10px 0;
    }
    
} */