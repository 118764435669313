.rating-stars-container {
  display: flex;
}

.star-wrapper {
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.star-icon {
  font-size: 22px; 
  
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
